import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS, SERVER_INPUT_TYPE } from '../constants';

const initialState = {
  postStatus: STATUS.READY,
  getStatus: STATUS.READY,
  postIsLoading: false,
  formIsLoading: false,
  error: '',
  formData: {
    questions: [
      {
        order: 1,
        name: 'First Name',
        section: 'APPLICATION',
        required: true,
        type: SERVER_INPUT_TYPE.INPUT,
        uuid: 'firstName',
      },
      {
        order: 2,
        name: 'Last Name',
        section: 'APPLICATION',
        required: true,
        type: SERVER_INPUT_TYPE.INPUT,
        uuid: 'lastName',
      },
      {
        order: 3,
        name: 'Email',
        section: 'APPLICATION',
        required: true,
        type: SERVER_INPUT_TYPE.INPUT,
        disabled: true,
        uuid: 'email',
      },
      {
        order: 4,
        name: 'Phone Number',
        section: 'APPLICATION',
        required: true,
        type: 'PhoneNumber',
        uuid: 'phoneNumber',
      },
      {
        order: 5,
        name: 'Date of Birth',
        section: 'APPLICATION',
        required: true,
        type: 'Date',
        uuid: 'dob',
      },
    ],
  },
};

const form = (state = initialState, action) => {
  switch (action.type) {
    case types.FORM_POST_FAILURE:
      return {
        ...state,
        postStatus: STATUS.FAILURE,
        postIsLoading: false,
        error: action.payload,
      };

    case types.FORM_POST_REQUEST:
      return {
        ...state,
        postStatus: STATUS.REQUEST,
        postIsLoading: true,
      };

    case types.FORM_POST_SUCCESS:
      return {
        ...state,
        postStatus: STATUS.SUCCESS,
        postIsLoading: false,
      };
    case types.FORM_GET_FAILURE:
      return {
        ...state,
        getStatus: STATUS.FAILURE,
        formIsLoading: false,
        error: action.payload,
      };

    case types.FORM_GET_REQUEST:
      return {
        ...state,
        getStatus: STATUS.REQUEST,
        formIsLoading: true,
      };

    case types.FORM_GET_SUCCESS:
      return {
        ...state,
        getStatus: STATUS.SUCCESS,
        formIsLoading: false,
        formData: { questions: action.payload },
      };

    default:
      return state;
  }
};
export default form;

import * as Sentry from '@sentry/browser';

import { NODE_ENV, SENTRY_FRONTEND_DSN } from '@constants/environment';
import packageJson from '../../../package.json';

/**
 * Initializes sentry with constants
 */
export const init = (): void => {
  Sentry.init({
    dsn: SENTRY_FRONTEND_DSN,
    environment: NODE_ENV,
    release: packageJson.version,
  });
};

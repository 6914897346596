import React from 'react';

import { Checkmark, Container } from './style';

class Checkbox extends React.PureComponent {
  handleChange = event => {
    const { name, checked } = event.target;
    const { receiveValues } = this.props;
    receiveValues(name, checked);
  };

  render() {
    const { name, label } = this.props;

    return (
      <Container htmlFor={name}>
        {label}
        <input name={name} type="checkbox" {...this.props} onChange={this.handleChange} />
        <Checkmark />
      </Container>
    );
  }
}

export default Checkbox;

import { APP_ENV } from '@constants/environment';
import { removeCookie, clearDevfolioAuth } from '@devfolioco/helpers';
import Cookies from 'js-cookie';

import { getState } from './index';
import { STATE } from '../constants';
import { sign, verify } from '../../shared/helpers';

const setDevfolioCookie = (name, value, path) => {
  Cookies.set(
    name,
    value,
    Object.assign(
      {
        path,
        httpOnly: false,
        expires: 365,
      },
      (APP_ENV === 'production' || APP_ENV === 'preview') && {
        domain: APP_ENV === 'production' ? 'devfolio.co' : 'devrel.in',
        sameSite: 'lax',
        secure: true,
      }
    )
  );
};

/**
 * Creates a cookie that keeps track of the current quiz
 * between client and server
 * @param {string} quizId The current quiz ID
 */
const createDevfolioCookie = async quizId => {
  const { uuid } = getState(STATE.AUTHENTICATION);

  const token = await sign({
    userId: uuid,
    quizId,
    start: true,
  });

  setDevfolioCookie('devfolio', token, '/quiz');
  setDevfolioCookie('devfolio', token, '/badges');
};

/**
 * Returns the parsed devfolio cookie
 */
const getDevfolioCookie = () => {
  const devfolioCookie = Cookies.get('devfolio');
  return verify(devfolioCookie);
};

/**
 * This method is used when clearing quiz related cookies when ending
 * a quiz and when logging out
 * @param {boolean} clearAuth Should the auth be cleared as well
 */
const removeDevfolioCookie = (clearAuth = false) => {
  removeCookie('devfolio', '/quiz');
  removeCookie('devfolio', '/badges');
  removeCookie('ethicBounds', '/quiz');
  // This cookie was used by previous auth mechanism
  // remove if it still persists
  removeCookie('devfolioAuth', '/');
  // Although this cookie would be cleared from the API when logging out
  // but just to be double sure this condition is added here in case
  // the API fails to clear the cookie to prevent the application from
  // behaving weirdly
  if (clearAuth) {
    clearDevfolioAuth();
  }
};

/**
 * Checks whether the cookie stored belongs to the current
 * user and the quiz or not
 * @param {string} quizId The current quiz id
 * @param {string} userId UUID of the current user
 */
const hasRelevantDevfolioCookie = (quizId, userId) => {
  let isRelevant = false;

  try {
    const devfolioCookie = getDevfolioCookie();

    const { quizId: cookieQuizId, userId: cookieUserId } = devfolioCookie;

    isRelevant = quizId === cookieQuizId && userId === cookieUserId;

    return isRelevant;
  } catch (error) {
    return isRelevant;
  }
};

/**
 * The stores the ethic bound cookie which confirms that
 * the user has checked the ethic bounds checkbox
 * @param {string} quizId
 */
const createEthicBoundsCookie = quizId => {
  const { uuid } = getState(STATE.AUTHENTICATION);
  setDevfolioCookie('ethicBounds', JSON.stringify({ userId: uuid, quizId }), '/quiz');
};

/**
 * Removes the ethic bounds cookie
 */
const removeEthicBoundsCookie = () => {
  removeCookie('ethicBounds', '/quiz');
};

export {
  createDevfolioCookie,
  getDevfolioCookie,
  removeDevfolioCookie,
  hasRelevantDevfolioCookie,
  createEthicBoundsCookie,
  removeEthicBoundsCookie,
};

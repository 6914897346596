import styled from 'styled-components';

export const Text = styled.span`
  color: #273339;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
`;

export const Logo = styled.h1`
  color: #273339;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

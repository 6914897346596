import moment from 'moment';
import store from '../../shared/store';

/* Moment allow us to change the time source
 * that will be used by all the moment objects
 * @reference https://momentjs.com/docs/#/customization/now/
 */
moment.now = () => {
  const serverTimerOffset = store.getState().authentication.serverTimeOffset;
  // Add the server time offset to the user's current time
  // to get the accurate time
  return serverTimerOffset + Date.now();
};

export default moment;

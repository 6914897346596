/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

const initialState = {
  bio: '',
  experiences: [],
  expertise: [],
  firstName: '',
  hackathons: [],
  lastName: '',
  otherProfiles: [],
  profileImageURL: '',
  publicProfileVisible: true,
  projects: [],
  skills: [],
  username: '',
  status: {
    fetchUserInfo: STATUS.READY,
  },
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_INFO: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ...payload.data,
          status: {
            ...state.status,
            fetchUserInfo: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchUserInfo: payload.status,
        },
      };
    }

    default:
      return state;
  }
};

export default profile;

/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { SPACES_BASE_URL } from '@constants/environment';
import { getApplicationStatus, mapToProject } from '../helpers';
import * as types from '../constants/actions';
import { ACTION_STATUS, CREATE_TEAM, APPLY_STATUS, ROLE, PROJECT_DESCRIPTION_QUESTIONS } from '../constants';

const initialState = {
  allHackathons: {
    data: [],
    pages: 0,
    currentPage: 0,
    count: 0,
  },
  featuredHackathons: [],
  applicationRequirementsCheckStatus: ACTION_STATUS.READY,
  applicationStatus: null,
  campaignNftTokenId: null,
  currentHackathon: {
    uuid: null,
    feedback: [],
    announcements: {
      status: '',
      currentPage: 1,
      totalPages: 1,
      items: [],
    },
  },
  destroyTeamStatus: ACTION_STATUS.READY,
  fetchReimbursementStatus: ACTION_STATUS.READY,
  hackathonDetails: {},
  hackathonError: [],
  hackathonTeamError: [],
  individualStatus: null,
  joinTeamRequests: [],
  joinTeamStatus: ACTION_STATUS.READY,
  otherFields: [],
  otherFieldsState: {},
  requiredFields: {},
  notFilledFields: {},
  joiningDiscordRequired: false,
  stakingCompleted: false,
  userStakingDetail: {},
  requiredLinks: [],
  reimbursement: null,
  reimbursementLocation: { city: '', state: '', country: '' },
  RSVPModuleCardKey: 'RSVP_INFO',
  submitTeamApplicationStatus: ACTION_STATUS.READY,
  team: null,
  teamMembers: [],
  ticket: null,
  tracks: [],
  updateHackerStatusAction: ACTION_STATUS.READY,
  updatePaymentDetailsStatus: ACTION_STATUS.READY,
  uploadTicketStatus: ACTION_STATUS.READY,
  userHackathons: [],
  projectError: '',
  stakings: {},
  // TODO: Remove this after ETHIndia 2023
  hasJoinedEthIndiaDiscordServer: false,
  tokenproofVerifiedStatusError: '',
  // Multi-phase hackathon phase
  phase: null,
  status: {
    fetchAnnouncements: ACTION_STATUS.READY,
    fetchHackathonData: ACTION_STATUS.READY,
    fetchHackathonsData: ACTION_STATUS.READY,
    fetchFeaturedHackathon: ACTION_STATUS.READY,
    fetchUserHackathonDetails: ACTION_STATUS.READY,
    fetchUserHackathons: ACTION_STATUS.READY,
    sendFeedback: ACTION_STATUS.READY,
    submitOtherFields: ACTION_STATUS.READY,
    submitProject: ACTION_STATUS.READY,
    publishProject: ACTION_STATUS.READY,
    deleteProject: ACTION_STATUS.READY,
    saveOtherFields: ACTION_STATUS.READY,
    updateEmailAnnouncementPreference: ACTION_STATUS.READY,
    applyToHackathon: ACTION_STATUS.READY,
    uploadProjectImages: ACTION_STATUS.READY,
    updateUserHackathonTrack: ACTION_STATUS.READY,
    checkEthIndiaDiscordServer: ACTION_STATUS.READY,
    setTokenproofNonce: ACTION_STATUS.READY,
    setTokenproofVerifiedStatus: ACTION_STATUS.READY,
    fetchUserStakingInfo: ACTION_STATUS.READY,
  },

  project: {
    uuid: null,
    name: null,
    slug: null,
    tagline: null,
    description: PROJECT_DESCRIPTION_QUESTIONS,
    hashtags: [],
    pictures: [],
    videoUrl: null,
    links: [],
    tracks: [],
    trackDescriptions: {},
    platforms: [],
    favicon: null,
    coverImage: null,
    status: 'draft',
    // ETHIndia specific fields
    judgingSubmissionType: null,
    emoji: null,
    sourceCodeURL: null,
    demoURL: null,
    category: null,
    techWebFrameworks: [],
    techLanguages: [],
    techDevtools: [],
    techDesignTools: [],
    techDatabases: [],
    techBlockchains: [],
    // ETHDenver2024 specific fields
    communityExpoOptIn: false,
    // Google GenAIExhchange 2024 specific fields
    architectureDiagram: null,
    // MumbaiHacks 2024 specific field
    byStudentTeam: false,
  },

  oldProjectState: null,
};

const hackathon = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CURRENT_HACKATHON_SUCCESS: {
      return {
        ...state,
        currentHackathon: { ...initialState.currentHackathon, ...action.payload },
      };
    }

    case types.FETCH_ALL_HACKATHON_USER_DETAILS_SUCCESS: {
      const { hackathon_fare: fare, status, project, campaign_nft_token_id: campaignNftTokenId } = action.payload;
      return {
        ...state,
        campaignNftTokenId,
        applicationStatus: getApplicationStatus(action.payload),
        hackathonDetails: action.payload,
        individualStatus: status,
        reimbursement:
          fare !== null
            ? {
                reimbursement_amount: (fare.amount && Number.parseInt(fare.amount, 10)) || 0,
                currency: fare.currency || 'INR',
              }
            : initialState.reimbursement,
        reimbursementLocation:
          fare !== null
            ? {
                city: fare.city || '',
                state: fare.state || '',
                country: fare.country || '',
              }
            : initialState.reimbursementLocation,
        team: action.payload.team,
        teamMembers: action.payload.teamMembers,
        ticket: action.payload.ticket,
        project: project !== null ? mapToProject(project) : initialState.project,
        phase: action.payload.phase,
        status: {
          ...state.status,
          fetchUserHackathonDetails: ACTION_STATUS.SUCCESS,
        },
      };
    }

    case types.FETCH_HACKATHON_USER_DETAILS_FAILURE:
    case types.FETCH_ALL_HACKATHON_USER_DETAILS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchUserHackathonDetails: ACTION_STATUS.FAILURE,
        },
      };
    }

    case types.FETCH_HACKATHON_USER_DETAILS_REQUEST:
    case types.FETCH_ALL_HACKATHON_USER_DETAILS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchUserHackathonDetails: ACTION_STATUS.REQUEST,
        },
      };
    }

    case types.FETCH_HACKATHONS_DATA_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathonsData: ACTION_STATUS.REQUEST,
        },
      };
    }

    case types.FETCH_HACKATHONS_DATA_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        allHackathons: {
          data: payload.page === 0 ? [...payload.result] : [...state.allHackathons.data, ...payload.result],
          currentPage: payload.page,
          pages: payload.pages,
          count: payload.count,
        },
        status: {
          ...state.status,
          fetchHackathonsData: ACTION_STATUS.SUCCESS,
        },
      };
    }

    case types.FETCH_HACKATHONS_DATA_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathonsData: ACTION_STATUS.FAILURE,
        },
      };
    }

    case types.FETCH_HACKATHON_DATA_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathonData: ACTION_STATUS.REQUEST,
        },
      };

    case types.FETCH_FEATURED_HACKATHON: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          featuredHackathons: payload.data,
          status: {
            ...state.status,
            fetchFeaturedHackathon: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchFeaturedHackathon: payload.status,
        },
      };
    }

    case types.FETCH_HACKATHON_DATA_SUCCESS:
      return {
        ...state,
        currentHackathon: { ...initialState.currentHackathon, ...action.payload },
        status: {
          ...state.status,
          fetchHackathonData: ACTION_STATUS.SUCCESS,
        },
      };

    case types.FETCH_HACKATHON_DATA_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          fetchHackathonData: ACTION_STATUS.FAILURE,
        },
      };

    case types.SET_OTHER_FIELDS:
      return {
        ...state,
        otherFields: [...action.payload],
      };

    case types.SET_HACKATHON_REQUIRED_FIELDS:
      return {
        ...state,
        requiredFields: { ...action.payload.requiredFields },
        notFilledFields: { ...action.payload.notFilledFields },
        requiredLinks: [...action.payload.requiredLinks],
        joiningDiscordRequired: action.payload.joiningDiscordRequired ?? false,
      };

    case types.HACKATHON_ERROR:
      return {
        ...state,
        hackathonError: [...state.hackathonError, action.payload],
      };
    case types.CLEAR_HACKATHON_ERROR:
      return {
        ...state,
        hackathonError: state.hackathonError.filter(error => error !== action.payload),
      };

    case types.DELETE_TEAM_FAILURE:
    case types.LEAVE_TEAM_FAILURE:
      return {
        ...state,
        destroyTeamStatus: ACTION_STATUS.FAILURE,
      };

    case types.SUBMIT_TEAM_APPLICATION_FAILURE:
      return {
        ...state,
        hackathonTeamError: [...state.hackathonTeamError, action.payload],
        submitTeamApplicationStatus: ACTION_STATUS.FAILURE,
      };

    case types.SUBMIT_TEAM_APPLICATION_REQUEST:
      return {
        ...state,
        submitTeamApplicationStatus: ACTION_STATUS.REQUEST,
      };

    case types.SUBMIT_TEAM_APPLICATION_SUCCESS:
      return {
        ...state,
        userHackathons: state.userHackathons.map(userHackathon => {
          if (userHackathon.hackathon.uuid === state.currentHackathon.uuid) {
            return {
              ...userHackathon,
              status: APPLY_STATUS.SUBMIT,
              team: action.payload,
            };
          }
          return userHackathon;
        }),
        applicationStatus: action.payload.status,
        submitTeamApplicationStatus: ACTION_STATUS.SUCCESS,
      };

    case types.DELETE_TEAM_REQUEST:
    case types.LEAVE_TEAM_REQUEST:
      return {
        ...state,
        destroyTeamStatus: ACTION_STATUS.REQUEST,
      };

    case types.CREATE_TEAM_FAILURE:
      return {
        ...state,
        createTeamStatus: action.payload,
      };
    case types.CREATE_TEAM_REQUEST:
      return {
        ...state,
        createTeamStatus: CREATE_TEAM.REQUEST,
      };
    case types.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        createTeamStatus: CREATE_TEAM.SUCCESS,
        team: action.payload.team,
        teamMembers: [action.payload.user],
      };

    case types.JOIN_TEAM_WITH_CODE_REQUEST: {
      return {
        ...state,
        joinTeamStatus: ACTION_STATUS.REQUEST,
      };
    }
    case types.JOIN_TEAM_WITH_CODE_FAILURE: {
      return {
        ...state,
        joinTeamStatus: ACTION_STATUS.FAILURE,
      };
    }
    case types.JOIN_TEAM_WITH_CODE_SUCCESS: {
      return {
        ...state,
        joinTeamStatus: ACTION_STATUS.SUCCESS,
        team: action.payload.team,
        teamMembers: action.payload.members,
        project: action.payload.project ? mapToProject(action.payload.project) : initialState.project,
      };
    }

    case types.CLEAR_HACKATHON_TEAM_ERROR: {
      return {
        ...state,
        hackathonTeamError: state.hackathonTeamError.filter(error => error !== action.payload),
      };
    }

    case types.HACKATHON_TEAM_ERROR: {
      return {
        ...state,
        hackathonTeamError: [...state.hackathonTeamError, action.payload],
      };
    }

    case types.DELETE_TEAM_SUCCESS: {
      return {
        ...state,
        destroyTeamStatus: ACTION_STATUS.SUCCESS,
        applicationStatus: action.payload,
        team: null,
        teamMembers: [],
        project: initialState.project,
      };
    }
    case types.LEAVE_TEAM_SUCCESS: {
      return {
        ...state,
        destroyTeamStatus: ACTION_STATUS.SUCCESS,
        applicationStatus: action.payload,
        team: null,
        teamMembers: [],
        project: initialState.project,
      };
    }

    case types.SUBMIT_INDIVIDUAL_REQUEST:
      return {
        ...state,
        submitIndividualStatus: ACTION_STATUS.REQUEST,
      };

    case types.SUBMIT_INDIVIDUAL_SUCCESS:
      return {
        ...state,
        applicationStatus: APPLY_STATUS.SUBMIT,
        userHackathons: state.userHackathons.map(userHackathon => {
          if (userHackathon.hackathon.uuid === state.currentHackathon.uuid) {
            return {
              ...userHackathon,
              status: APPLY_STATUS.SUBMIT,
            };
          }
          return userHackathon;
        }),
        submitIndividualStatus: ACTION_STATUS.SUCCESS,
      };

    case types.SUBMIT_INDIVIDUAL_FAILURE:
      return {
        ...state,
        submitIndividualStatus: ACTION_STATUS.FAILURE,
      };

    case types.FETCH_USER_HACKATHONS: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          userHackathons: action.payload.data,
          status: {
            ...state.status,
            fetchUserHackathons: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchUserHackathons: payload.status,
        },
      };
    }

    case types.SET_OTHER_FIELDS_STATE:
      return {
        ...state,
        otherFieldsState: { ...action.payload },
      };

    case types.SAVE_OTHER_FIELDS: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.REQUEST) {
        return {
          ...state,
          otherFieldsState: { ...state.otherFieldsState, ...payload.values },
          status: {
            ...state.status,
            saveOtherFields: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          saveOtherFields: payload.status,
        },
      };
    }

    case types.HACKATHON_FILE_UPLOAD_FAILURE:
    case types.HACKATHON_FILE_UPLOAD_REQUEST:
    case types.HACKATHON_FILE_UPLOAD_SUCCESS: {
      const {
        payload: { scopeID, status, value },
      } = action;
      return {
        ...state,
        otherFieldsState: {
          ...state.otherFieldsState,
          [scopeID]: {
            value: value || state.otherFieldsState[scopeID],
            status,
          },
        },
      };
    }
    case types.CHECK_APPLICATION_REQUIREMENTS_READY:
      return {
        ...state,
        applicationRequirementsCheckStatus: ACTION_STATUS.READY,
      };
    case types.CHECK_APPLICATION_REQUIREMENTS_FAILURE:
      return {
        ...state,
        applicationRequirementsCheckStatus: ACTION_STATUS.FAILURE,
      };
    case types.CHECK_APPLICATION_REQUIREMENTS_REQUEST:
      return {
        ...state,
        applicationRequirementsCheckStatus: ACTION_STATUS.REQUEST,
      };
    case types.CHECK_APPLICATION_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        applicationRequirementsCheckStatus: ACTION_STATUS.SUCCESS,
      };

    case types.UPDATE_HACKER_STATUS_FAILURE:
      return {
        ...state,
        updateHackerStatusAction: ACTION_STATUS.FAILURE,
      };
    case types.UPDATE_HACKER_STATUS_REQUEST:
      return {
        ...state,
        updateHackerStatusAction: ACTION_STATUS.REQUEST,
      };
    case types.UPDATE_HACKER_STATUS_SUCCESS:
      return {
        ...state,
        applicationStatus: getApplicationStatus({ status: action.payload, team: state.team }),
        userHackathons: state.userHackathons.map(userHackathon => {
          if (userHackathon.hackathon.uuid === state.currentHackathon.uuid) {
            return {
              ...userHackathon,
              status: getApplicationStatus({ status: action.payload, team: state.team }),
            };
          }
          return userHackathon;
        }),
        individualStatus: action.payload,
        updateHackerStatusAction: ACTION_STATUS.SUCCESS,
      };

    case types.FETCH_REIMBURSEMENT_AMOUNT_FAILURE: {
      return {
        ...state,
        fetchReimbursementStatus: ACTION_STATUS.FAILURE,
      };
    }
    case types.FETCH_REIMBURSEMENT_AMOUNT_REQUEST: {
      return {
        ...state,
        fetchReimbursementStatus: ACTION_STATUS.REQUEST,
      };
    }
    case types.FETCH_REIMBURSEMENT_AMOUNT_SUCCESS: {
      const { reimbursement, reimbursementLocation } = action.payload;
      return {
        ...state,
        fetchReimbursementStatus: ACTION_STATUS.SUCCESS,
        reimbursement:
          reimbursement !== null && reimbursement.hasOwnProperty('amount') && reimbursement.amount
            ? {
                reimbursement_amount: Number.parseInt(reimbursement.amount, 10),
                currency: reimbursement.currency,
              }
            : initialState.reimbursement,
        reimbursementLocation,
        RSVPModuleCardKey: 'UPLOAD_TICKET',
      };
    }

    case types.UPDATE_RSVP_CARD_KEY:
      return {
        ...state,
        RSVPModuleCardKey: action.payload,
      };

    case types.UPLOAD_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.payload,
        uploadTicketStatus: ACTION_STATUS.SUCCESS,
      };
    case types.UPLOAD_TICKET_FAILURE:
      return {
        ...state,
        uploadTicketStatus: ACTION_STATUS.FAILURE,
      };
    case types.UPLOAD_TICKET_REQUEST:
      return {
        ...state,
        uploadTicketStatus: ACTION_STATUS.REQUEST,
      };
    case types.SET_HACKATHON_ID: {
      return {
        ...state,
        currentHackathon: { ...initialState.currentHackathon, uuid: action.payload },
      };
    }

    case types.SUBMIT_OTHER_FIELDS_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          submitOtherFields: ACTION_STATUS.REQUEST,
        },
      };
    }
    case types.SUBMIT_OTHER_FIELDS_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          submitOtherFields: ACTION_STATUS.FAILURE,
        },
      };
    }
    case types.SUBMIT_OTHER_FIELDS_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          submitOtherFields: ACTION_STATUS.SUCCESS,
        },
      };
    }
    case types.SUBMIT_HACKATHON_PROJECT_FAILURE:
      return {
        ...state,
        projectError: action.payload,
        status: {
          ...state.status,
          submitProject: ACTION_STATUS.FAILURE,
        },
      };
    case types.SUBMIT_HACKATHON_PROJECT_REQUEST:
      return {
        ...state,
        projectError: '',
        status: {
          ...state.status,
          submitProject: ACTION_STATUS.REQUEST,
        },
      };
    case types.SUBMIT_HACKATHON_PROJECT_SUCCESS: {
      const { payload } = action;
      let projectData = state.project;
      if (payload.uuid) {
        delete projectData.uuid;
        projectData = Object.assign({}, projectData, { uuid: payload.uuid });
      }
      if (payload.name) {
        delete projectData.name;
        projectData = Object.assign({}, projectData, { name: payload.name });
      }
      if (payload.slug) {
        delete projectData.slug;
        projectData = Object.assign({}, projectData, { slug: payload.slug });
      }
      if (payload.tagline) {
        delete projectData.tagline;
        projectData = Object.assign({}, projectData, { tagline: payload.tagline });
      }
      if (payload.description) {
        delete projectData.description;
        projectData = Object.assign({}, projectData, { description: payload.description });
      }
      if (payload.hashtags) {
        delete projectData.hashtags;
        const hashtags = payload.hashtags.map(hashtag => ({ id: hashtag.name, text: hashtag.name }));
        projectData = Object.assign({}, projectData, { hashtags });
      }
      if (payload.pictures) {
        delete projectData.pictures;
        const pictures = payload.pictures.split(',').map(picture => ({ preview: `${SPACES_BASE_URL}${picture}` }));
        projectData = Object.assign({}, projectData, { pictures });
      }
      if (payload.video_url) {
        delete projectData.videoUrl;
        projectData = Object.assign({}, projectData, { videoUrl: payload.video_url });
      }
      if (payload.links) {
        delete projectData.links;
        const links = payload.links.split(',').map(link => ({ id: link, text: link }));
        projectData = Object.assign({}, projectData, { links });
      }
      return {
        ...state,
        projectError: '',
        status: {
          ...state.status,
          submitProject: ACTION_STATUS.SUCCESS,
        },
        project: projectData,
      };
    }
    case types.SET_HACKATHON_PROJECT_STATE: {
      const { name, value } = action.payload;
      return {
        ...state,
        project: {
          ...state.project,
          [name]: value,
        },
      };
    }

    case types.UPDATE_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        updatePaymentDetailsStatus: ACTION_STATUS.FAILURE,
      };
    case types.UPDATE_PAYMENT_DETAILS_REQUEST:
      return {
        ...state,
        updatePaymentDetailsStatus: ACTION_STATUS.REQUEST,
      };
    case types.UPDATE_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        updatePaymentDetailsStatus: ACTION_STATUS.SUCCESS,
        hackathonDetails: {
          ...state.hackathonDetails,
          payment_mode: action.payload.mode,
          payment_mode_value: action.payload.value,
        },
      };

    case types.HYDRATE_PROJECT: {
      const { payload } = action;
      if (typeof payload === 'object' && !Array.isArray(payload) && payload !== null) {
        // Remove these fields when hydrating project from local storage to avoid
        // loading incorrect data in the project content
        const { uuid, status, slug, coverImage, favicon, ...rest } = payload;
        return {
          ...state,
          project: {
            ...state.project,
            ...rest,
          },
        };
      }

      return state;
    }

    case types.VERIFY_INVITE_ADD_SQUAD: {
      const { payload } = action;

      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          status: {
            ...state.status,
            verifyInviteAddSquad: payload.status,
          },
          userHackathons: state.userHackathons.map(item => {
            if (item.hackathon.slug === payload.hackathonSlug || item.hackathon.uuid === payload.hackathonSlug) {
              item.role = ROLE.ORGANIZER;
            }

            return item;
          }),
        };
      }

      // Request
      return {
        ...state,
        status: {
          ...state.status,
          verifyInviteAddSquad: payload.status,
        },
      };
    }

    case types.FETCH_ANNOUNCEMENTS: {
      const { payload } = action;
      const { currentPage, items, totalPages, userStatus } = payload;

      if (payload.status === ACTION_STATUS.REQUEST && userStatus !== state.currentHackathon.announcements.status) {
        return {
          ...state,
          currentHackathon: {
            ...state.currentHackathon,
            announcements: {
              ...initialState.currentHackathon.announcements,
            },
          },
          status: {
            ...state.status,
            fetchAnnouncements: payload.status,
          },
        };
      }

      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          currentHackathon: {
            ...state.currentHackathon,
            announcements: {
              status: userStatus,
              currentPage,
              // Reset announcement array when loading the first page of announcements else append
              // the announcements to the existing array for subsequent pages
              items: currentPage === 1 ? items : [...state.currentHackathon.announcements.items, ...items],
              totalPages,
            },
          },
          status: {
            ...state.status,
            fetchAnnouncements: payload.status,
          },
        };
      }

      // Request
      return {
        ...state,
        status: {
          ...state.status,
          fetchAnnouncements: payload.status,
        },
      };
    }

    case types.UPDATE_EMAIL_ANNOUNCEMENT_PREFERENCE: {
      const { payload } = action;

      if (payload.status === ACTION_STATUS.REQUEST) {
        return {
          ...state,
          hackathonDetails: {
            ...state.hackathonDetails,
            unsubscribe_announcement: payload.unsubscribe,
          },
          status: {
            ...state.status,
            updateEmailAnnouncementPreference: payload.status,
          },
        };
      }

      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          status: {
            ...state.status,
            updateEmailAnnouncementPreference: payload.status,
          },
        };
      }

      return {
        ...state,
        hackathonDetails: {
          ...state.hackathonDetails,
          unsubscribe_announcement: !state.hackathonDetails.unsubscribe_announcement,
        },
        status: {
          ...state.status,
          updateEmailAnnouncementPreference: payload.status,
        },
      };
    }

    case types.SEND_FEEDBACK: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          sendFeedback: payload.status,
        },
      };
    }

    case types.FETCH_FEEDBACK: {
      const { payload } = action;

      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          currentHackathon: {
            ...state.currentHackathon,
            feedback: [...initialState.currentHackathon.feedback, ...payload.data],
          },
          status: {
            ...state.status,
            fetchFeedback: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchFeedback: payload.status,
        },
      };
    }

    case types.PUBLISH_PROJECT: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          project: {
            ...state.project,
            status: 'publish',
          },
          status: {
            ...state.status,
            publishProject: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          publishProject: payload.status,
        },
      };
    }

    case types.DELETE_PROJECT: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          project: initialState.project,
          status: {
            ...state.status,
            deleteProject: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteProject: payload.status,
        },
      };
    }

    case types.FETCH_SIDE_PROJECT: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          project: mapToProject(payload.data),
          teamMembers: payload.data.members || [],
        };
      }
      return state;
    }

    case types.CLEAR_CURRENT_HACKATHON: {
      return {
        ...state,
        applicationRequirementsCheckStatus: ACTION_STATUS.READY,
        currentHackathon: { ...initialState.currentHackathon },
        status: {
          ...state.status,
          fetchUserHackathonDetails: ACTION_STATUS.READY,
        },
      };
    }

    case types.UPDATE_REQUIRED_LINK: {
      const { payload } = action;
      return {
        ...state,
        requiredLinks: state.requiredLinks.map(link => {
          if (link.name === payload.name) {
            return { ...link, value: payload.value || link.value, uuid: payload.uuid || link.uuid };
          }
          return link;
        }),
      };
    }

    case types.SET_OLD_PROJECT_STATE:
      return {
        ...state,
        oldProjectState: action.payload,
      };

    case types.APPLY_TO_HACKATHON:
      return {
        ...state,
        status: {
          ...state.status,
          applyToHackathon: action.payload.status,
        },
      };

    case types.UPLOAD_PROJECT_IMAGE: {
      const { payload } = action;
      return {
        ...state,
        status: {
          ...state.status,
          uploadProjectImages: payload.status,
        },
      };
    }

    case types.UPDATE_HACKATHON_STAKING_INFO: {
      const { payload } = action;
      return {
        ...state,
        stakings: payload,
      };
    }

    case types.SET_HACKATHON_TRACKS: {
      const { payload } = action;
      return {
        ...state,
        tracks: payload,
      };
    }

    case types.UPDATE_USER_HACKATHON_TRACK: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.REQUEST) {
        return {
          ...state,
          hackathonDetails: {
            ...state.hackathonDetails,
            hackathon_track: { uuid: payload.trackUUID },
          },
          status: {
            ...state.status,
            updateUserHackathonTrack: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          updateUserHackathonTrack: payload.status,
        },
      };
    }
    // Remove this After ETHIndia 2023
    case types.CHECK_ETHINDIA_DISCORD_SERVER: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          hasJoinedEthIndiaDiscordServer: payload.data,
          status: {
            ...state.status,
            checkEthIndiaDiscordServer: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          checkEthIndiaDiscordServer: payload.status,
        },
      };
    }

    case types.UPDATE_TOKENPROOF_NONCE: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          status: {
            ...state.status,
            setTokenproofNonce: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          setTokenproofNonce: payload.status,
        },
      };
    }

    case types.UPDATE_TOKENPROOF_VERIFIED_STATUS: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          hackathonDetails: {
            ...state.hackathonDetails,
            tokenproof_verified: payload.data,
          },
          tokenproofVerifiedStatusError: '',
          status: {
            ...state.status,
            setTokenproofVerifiedStatus: payload.status,
          },
        };
      }

      if (payload.status === ACTION_STATUS.FAILURE) {
        return {
          ...state,
          tokenproofVerifiedStatusError: payload.data,
          status: {
            ...state.status,
            setTokenproofVerifiedStatus: payload.status,
          },
        };
      }
      return {
        ...state,
        tokenproofVerifiedStatusError: '',
        status: {
          ...state.status,
          setTokenproofVerifiedStatus: payload.status,
        },
      };
    }

    case types.USER_HACKATHON_STAKING_INFO_REQUEST: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchUserStakingInfo: ACTION_STATUS.REQUEST,
        },
      };
    }
    case types.USER_HACKATHON_STAKING_INFO_SUCCESS: {
      const userHackathonStakings = action.payload;

      if (userHackathonStakings?.length === 0) {
        return {
          ...state,
          stakingCompleted: false,
          userStakingDetail: {},
          status: {
            ...state.status,
            fetchUserStakingInfo: ACTION_STATUS.SUCCESS,
          },
        };
      }

      return {
        ...state,
        stakingCompleted: true,
        userStakingDetail: userHackathonStakings?.[0] ?? {},
        status: {
          ...state.status,
          fetchUserStakingInfo: ACTION_STATUS.SUCCESS,
        },
      };
    }
    case types.USER_HACKATHON_STAKING_INFO_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          fetchUserStakingInfo: ACTION_STATUS.FAILURE,
        },
      };
    }

    default:
      return state;
  }
};

export default hackathon;

import React from 'react';
import { Column, Row } from 'hedron';

const Loading = ({ isLoading, timedOut, error }) => {
  if (isLoading) {
    if (timedOut) {
      return (
        <Row divisions={2} style={{ maxWidth: 1200, margin: '0 auto', marginTop: 24 }}>
          <Column xs={2} sm={2} md={1} lg={1} style={{ justifyContent: 'flex-end' }}>
            <div>Try again!</div>
          </Column>
        </Row>
      );
    }

    return null;
  }
  if (error) {
    return (
      <Row divisions={2} style={{ maxWidth: 1200, margin: '0 auto', marginTop: 24 }}>
        <Column xs={2} sm={2} md={1} lg={1} style={{ justifyContent: 'flex-end' }}>
          <div>Error! Couldn&apos;t load</div>
        </Column>
      </Row>
    );
  }
  return null;
};

export default Loading;

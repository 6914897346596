import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useAction = action => {
  const dispatch = useDispatch();
  const updatedAction = useCallback(
    (...args) => {
      action(...args)(dispatch);
    },
    [action, dispatch]
  );
  return updatedAction;
};

export default useAction;

/* eslint-disable prefer-destructuring */
/**
 * @note Using template literals to make sure we always have a string.
 */
type NODE_ENV_TYPE = 'production' | 'development' | 'test';
type APP_ENV_TYPE = 'production' | 'development' | 'preview';
export const API_ROOT = `${process.env.API_ROOT}`;
export const BASE_URL = `${process.env.BASE_URL}`;
export const NODE_ENV = `${process.env.NODE_ENV}` as NODE_ENV_TYPE;
export const APP_ENV = `${process.env.APP_ENV}` as APP_ENV_TYPE;
export const SENTRY_FRONTEND_DSN = `${process.env.SENTRY_FRONTEND_DSN}`;
export const RECAPTCHA_KEY = `${process.env.RECAPTCHA_KEY}`;
export const SPACES_BASE_URL = `${process.env.SPACES_BASE_URL}`;
export const GOOGLE_PLACES_KEY = `${process.env.GOOGLE_PLACES_KEY}`;
export const RAZORPAY_KEY_ID = `${process.env.RAZORPAY_KEY_ID}`;
export const ORG_BASE_URL = `${process.env.ORG_BASE_URL}`;
export const GIPHY_API_KEY = `${process.env.GIPHY_API_KEY}`;
export const QUIZ_SECRET = `${process.env.QUIZ_SECRET}`;
export const OG_BASE = `${process.env.OG_BASE}`;
export const APPLY_NOW_SCRIPT = `${process.env.APPLY_NOW_SCRIPT}`;
export const GOOGLE_CLIENT_ID = `${process.env.GOOGLE_CLIENT_ID}`;
export const QUADRATIC_FUNDING_SLUG = `${process.env.QUADRATIC_FUNDING_SLUG}`.split(',');
export const QUADRATIC_FUNDING_END_DATE = `${process.env.QUADRATIC_FUNDING_END_DATE}`;
export const LOOM_API_KEY = `${process.env.LOOM_API_KEY}`;
export const GRAPHQL_WEBSOCKET_ROOT = `${process.env.GRAPHQL_WEBSOCKET_ROOT}`;
export const GRAPHQL_ROOT = `${process.env.GRAPHQL_ROOT}`;
export const INFURA_ID = `${process.env.INFURA_ID}`;
export const ALCHEMY_ID = `${process.env.ALCHEMY_ID}`;
export const WALLET_CONNECT_ID = `${process.env.WALLET_CONNECT_ID}`;
export const OG_GENERATOR_PREFIX = `${process.env.OG_GENERATOR_PREFIX}`;
export const TOKENPROOF_APP_ID = `${process.env.TOKENPROOF_APP_ID}`;

interface EnvironmentInterface {
  API_ROOT: string;
  BASE_URL: string;
  NODE_ENV: NODE_ENV_TYPE;
  APP_ENV: APP_ENV_TYPE;
  SENTRY_FRONTEND_DSN: string;
  RECAPTCHA_KEY: string;
  SPACES_BASE_URL: string;
  GOOGLE_PLACES_KEY: string;
  RAZORPAY_KEY_ID: string;
  ORG_BASE_URL: string;
  GIPHY_API_KEY: string;
  QUIZ_SECRET: string;
  OG_BASE: string;
  APPLY_NOW_SCRIPT: string;
  GOOGLE_CLIENT_ID: string;
  QUADRATIC_FUNDING_SLUG: Array<string>;
  QUADRATIC_FUNDING_END_DATE: string;
  LOOM_API_KEY: string;
  GRAPHQL_WEBSOCKET_ROOT: string;
  GRAPHQL_ROOT: string;
  INFURA_ID: string;
  ALCHEMY_ID: string;
  WALLET_CONNECT_ID: string;
  OG_GENERATOR_PREFIX: string;
  TOKENPROOF_APP_ID: string;
}

const ENVIRONMENT: EnvironmentInterface = {
  API_ROOT,
  BASE_URL,
  NODE_ENV,
  APP_ENV,
  SENTRY_FRONTEND_DSN,
  RECAPTCHA_KEY,
  SPACES_BASE_URL,
  GOOGLE_PLACES_KEY,
  RAZORPAY_KEY_ID,
  ORG_BASE_URL,
  GIPHY_API_KEY,
  QUIZ_SECRET,
  OG_BASE,
  APPLY_NOW_SCRIPT,
  GOOGLE_CLIENT_ID,
  QUADRATIC_FUNDING_SLUG,
  QUADRATIC_FUNDING_END_DATE,
  LOOM_API_KEY,
  GRAPHQL_WEBSOCKET_ROOT,
  GRAPHQL_ROOT,
  INFURA_ID,
  ALCHEMY_ID,
  WALLET_CONNECT_ID,
  OG_GENERATOR_PREFIX,
  TOKENPROOF_APP_ID,
};

export default ENVIRONMENT;

import { connect } from 'react-redux';
import React from 'react';

import { Body } from 'components/Globals';
import ButtonLink from 'components/ButtonLink';
import Logo from 'components/Logo';

import { logger } from 'helpers/logger';

import ServerError from '../../views/ServerError';

import { Container } from './style';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });

    // Report the UI error
    logger.error('ui', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, serverError } = this.props;

    if (hasError) {
      return (
        <Container>
          <Logo />
          <Body>
            Something went wrong. Please try{' '}
            <ButtonLink onClick={() => window.location.reload(true)}>refreshing</ButtonLink>
            {` `}the page.
          </Body>
        </Container>
      );
    }

    if (serverError) {
      return <ServerError />;
    }

    return children;
  }
}

const mapStateToProps = ({ user }) => ({
  serverError: user.serverError,
});

export default connect(mapStateToProps)(ErrorBoundary);

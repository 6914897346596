/* eslint-disable no-nested-ternary */
import React from 'react';
import { Markdown } from '@devfolioco/genesis';
import { saveImage } from '@devfolioco/helpers';
import { RichTextEditor, TextareaAuto } from './style';
import TextArea from '../Textarea';

class InputRichTextEditor extends React.PureComponent {
  state = {
    hasFocus: false,
  };

  onBlur = () => {
    const { onBlur, name } = this.props;
    this.setState({ hasFocus: false });
    if (onBlur) {
      onBlur({ target: { name } });
    }
  };

  focus = () => {
    this.setState({ hasFocus: true });
  };

  render() {
    const { hasFocus } = this.state;
    const { height, transparent, name, value } = this.props;

    return (
      <React.Fragment>
        {!hasFocus ? (
          <RichTextEditor
            saveImage={saveImage}
            style={
              !transparent
                ? {
                    backgroundColor: '#cffbf3',
                    padding: '0px 15px',
                  }
                : { marginTop: '7px' }
            }
            transparent={transparent}
            height={height}
            onClick={this.focus}
            tabIndex={0}
            onFocus={this.focus}
          >
            {typeof value === 'string' ? <Markdown>{value}</Markdown> : ''}
          </RichTextEditor>
        ) : !transparent ? (
          <TextArea id={name} autoFocus onBlur={this.onBlur} {...this.props} />
        ) : (
          <TextareaAuto id={name} autoFocus onBlur={this.onBlur} {...this.props} />
        )}
      </React.Fragment>
    );
  }
}

export default InputRichTextEditor;

/* eslint-disable max-len */

export const ERRORS = {
  /**
   * These are the error messages we display on the client side
   */
  addressEmpty: "Address can't be left blank",
  cityEmpty: "City can't be left blank.",
  cityInvalid: 'Please enter a valid city name.',
  dateInvalid: "Please make sure you're entering a valid date.",
  deleteTeamFailed: 'Delete team failed.',
  emailDirty: "This doesn't appear to be a valid email address. Please try a different email address.",
  emailDisposable: "We don't allow signup via disposable email service. Please try a different email address.",
  emailDuplicate: 'This email address is already linked to a different account.',
  emailEmpty: "The email address can't be left empty.",
  emailInvalid: 'Please enter a valid email address. For eg. example@email.com',
  emailNotFound: "Sorry, we couldn't find an account with that email address.",
  emailMismatch: 'Please enter the correct email address.',
  emailUnavailable: 'Someone (maybe you?) has already created an account using this email.',
  emailUnverified: "You haven't verified your email yet!",
  emailVerificationFailed: "Sorry, we couldn't verify your email address. Please try again.",
  emergencyPhoneNumberInvalid:
    'The emergency contact number is invalid. Please enter a valid phone number. For eg. 9876543210.',
  fieldRequired: 'Please fill all the fields.',
  fieldUnsaved: 'Field could not be saved. Please try again.',
  firstNameEmpty: "First name can't be left blank.",
  fullNameEmpty: "Full name can't be left blank.",
  googleAuthenticationFailure: "Sorry, we couldn't connect with Google. Please try again.",
  individualApplicationFailed: "We could'nt submit your application. Try again.",
  inviteUserFailed: 'Invite user failed.',
  joinTeamFailed: 'Join team failed.',
  invalidTeamCode: "Can't join team. The entered code is invalid.",
  teamJoinFailed: 'Could not join team.',
  lastNameEmpty: "Last name can't be left blank.",
  leaveTeamFailed: 'Leave team failed.',
  loginFailed:
    "Sorry, we're unable to login you right now. If the problem persists, please drop us an email at help@devfolio.co.",
  notSubmissionDuration: 'Sorry, a project can only be uploaded during the hackathon',
  OTPIncorrect: 'Incorrect OTP. Please try again.',
  OTPSendFailure: "Sorry, we couldn't send OTP. We're looking into this, right away! Please try again later.",
  passwordIncorrect: "Sorry, that password isn't right.",
  passwordResetFailed:
    "Sorry, we couldn't reset password. We're looking into this, right away! Please try again later.",
  passwordShort: 'Password must be minimum 8 characters long.',
  passwordRateLimitExceeded: 'The maximum number of login attempts has been reached. Please try again in an hour.',
  phoneNumberBlank: 'Phone number cannot be left blank.',
  phoneNumberDuplicate: 'This phone number is already linked to a different account.',
  phoneNumberInvalid: 'Please enter a valid phone number.',
  requiredFieldsMissing: 'Something went wrong, please refresh the page and try again.',
  teamFull: 'The team you are trying to join is already full.',
  teamSubmissionFailed: "Sorry, we couldn't submit your team for review.",
  usernameInvalid: 'Username must be minimum 2 characters long, and should not contain spaces or special characters.',
  usernameNotFound: "Sorry, we couldn't find an account with that username.",
  usernameShort: 'Username must be minimum 2 characters long.',
  usernameNotAllowed: 'Username should not contain spaces or special characters.',
  usernameUnavailable: 'Another user with this username already exists. Please try a different username.',
  userPartOfTeam: 'The user you invited has already joined another team. Please Invite someone else.',
  onlyFemaleHackathon: 'This is a hackathon only for women.',
  revertApplicationFailed: "Sorry, we're unable to withdraw your application at this moment.",
  pincodeEmpty: "PIN code can't be left blank.",
  pincodeInvalid: 'Please enter a valid PIN code.',
  pincodeNotServiceable: 'Sorry, we do not deliver to this location.',
  projectSubmitFailed: 'Sorry, we could not upload your project. Please, try again.',
  projectNameRequired: 'Please enter a project name to save the project.',
  projectAlreadyCreated: 'The project has already been created. Please check your dashboard',
  projectAlreadySubmitted:
    'The team has already submitted the project. Please join a different team or submit the project individually.',
  projectImageInvalid: 'Please make sure that the image is in .jpg or .png format with file size less than 1 MB',
  interview: {
    slotUnavailable:
      'Oops! The interview slot you are trying to book seems to be unavailable. Please reload the page to fetch fresh slots',
    default: 'Something went wrong, please reload the page and try booking the slot again.',
  },
  stateEmpty: "State can't be left blank.",
  stateInvalid: 'Please enter a valid state name.',
  firstNameUpdatesBlockedForETHIndia: 'Updating the First Name field has been disabled for ETHIndia 2023 hackers.',
  lastNameUpdatesBlockedForETHIndia: 'Updating the Last Name field has been disabled for ETHIndia 2023 hackers.',
  emailUpdatesBlockedForETHIndia: 'Updating the Email field has been disabled for ETHIndia 2023 hackers.',
  teamJoiningErrors: {
    /* These are the errors which would be shown to the user when the team and hacker are in different states.
    The first status denotes the status of the hacker and the second status is the status of the team */

    'null submit, null accept, null waitlist, null reject, null waitlist_sent': `This team is already being reviewed. Please submit your individual application for review and join this team later if both accepted, or join another team now that is yet to apply.`,

    'null accept_sent, null rsvp, null check_in, null reimburse': `This team's already been accepted. Please submit your individual application for review and join this team later if accepted, or join another team now that is yet to apply.`,

    'null reject_sent, reimburse reject_sent': `Oh darn! This team didn't make the cut. Please submit an individual application or join another team now that is yet to apply.`,

    'rsvp null, check_in null, reimburse null': `This team has not submitted their application for review. You can join this team only after they've been accepted.`,

    'rsvp submit, rsvp accept, rsvp waitlist, rsvp reject, check_in submit, check_in accept, check_in waitlist, check_in reject, reimburse submit, reimburse accept, reimburse waitlist, reimburse reject': `This team's application is under review. You can join this team only after they've been accepted.`,

    'rsvp accept_sent': `Joining this team requires RSVP yes from all current members. Please ask them to do so.`,

    'rsvp reject_sent, check_in reject_sent': `Oh darn! This team didn't make the cut. You can join other accepted teams`,

    'rsvp check_in, rsvp reimburse': `You haven't checked in yet. Please check in to join this team. To check-in, please reach out to the organizers of the hackathon`,

    'check_in accept_sent, reimburse accept_sent, reimburse rsvp, check_in accept_sent':
      'Joining this team requires RSVP yes and check-in from all current members. Please ask them to do so.',

    'check_in rsvp': 'Joining this team requires check-in from all current members. Please ask them to do so.',
  },
} as const;

export const SERVER_ERRORS = {
  /**
   * These are the error messages the server could throw. We check them, and
   * display the client ERROR accordingly.
   */
  accessTokenRequired: 'access_token is required',
  accessTokenInvalid: 'invalid access_token',
  accountNotFound: 'Account with this email or username does not exists',
  emailDuplicate: 'email already in use',
  emailInvalid: 'must be an email',
  hackathonApplicationClosed: "Can't apply, hackathon registration are close.",
  IDTokenInvalid: 'Invalid id_token',
  invalidTeamCode: "Can't joint team, invalid code.",
  notFound: "The requested resource can't be found.",
  OTPInvalid: 'Invalid OTP',
  passwordInvalid: 'Invalid Password',
  passwordShort: 'password must be atleast 8 characters long',
  phoneNumberDuplicate: 'Phone number is linked with another account',
  phoneNumberInvalid: 'Invalid phone number',
  reapplyingToHackathon: "Can't reapply.",
  requiredFieldsMissing: "Can't apply, fill in all the required information.",
  teamCreationDenied: "Can't create team.",
  teamDuplicate: 'team name not available',
  teamFull: 'Cannot join. Team is full.',
  teamJoinFailed: 'Cannot join team.',
  teamJoinFailedDuplicate: "Can't join team.",
  teamNotFormed: "Can't apply without team.",
  teamSubmissionFailed: "Can't submit team for review",
  tokenExpired: 'Token expired',
  tokenInvalid: 'invalid token',
  unprocessableRequest: 'The server received a request that could not be processed.',
  usernameInvalid: 'Invalid username',
  usernameInvalidDuplicate: 'invalid username',
  usernameUnavailable: 'username not available',
  userNotFound: 'user not found',
  userNotMemberOfTeam: 'User is not a member of the team.',
  userNotMemberOfTeamRemove: "Can't remove member from the team, user is not the member of team.",
  userPartOfTeam: 'User is already part of a team.',
  photoSubmissionFailed: "Can't upload project pictures. Hackathon has not started or has ended.",
  fileUploadFailed: "Can't upload project file. Hackathon has not started or has ended.",
  notSubmissionDuration: "Can't create project. Hackathon has not started or has ended.",
  refreshTokenInvalid: 'invalid refresh_token',
  interviewSlotUnavailable: 'Interview slot not available',
  companyAlreadyRegistered: 'Company is already registered',
  projectAlreadyCreated: "Can't create project. Project already exists.",
  projectAlreadySubmitted: "Can't join team, as the project is submitted",
  passwordRateLimitExceeded: 'Login attempts over',
  accountAlreadyConnected: 'ACCOUNT_ALREADY_CONNECTED',
  ethindiaUpdatesBlocked: 'ETHINDIA_PARTICIPANTS_CANNOT_UPDATE_DETAILS_AFTER_RSVP',

  // Constraint errors
  femaleOnly: {
    noApply: "Can't apply, only females are allowed.",
    noCreate: "Can't create team, only females are allowed.",
    noJoin: "Can't join team, only females are allowed.",
    noSubmit: "Can't submit team for review, only females are allowed.",
  },
} as const;

export const TOKENPROOF_ERRORS = {
  TICKET_NOT_FOUND: 'Tokenproof Ticket not found',
  TICKET_ALREADY_USED: 'Tokenproof Ticket is already used',
} as const;

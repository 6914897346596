import React from 'react';
import { useSelector } from 'react-redux';
import { usePrevious, Analytics } from '@devfolioco/helpers';

import useAction from 'helpers/useAction';
import { fetchUserBasicInfo } from 'actions/user';
import { isAuthenticated } from '@helpers';
import { ACTION_STATUS as STATUS } from '@constants';

/**
 * This custom hook is used to fetch the basic user info and identify
 * the user with the analytics service if the user is currently logged
 * in.
 * @returns {boolean} Whether the user info is loading right now
 */
export const useIdentifyUser = () => {
  const dispatchFetchUserData = useAction(fetchUserBasicInfo);
  const fetchUserBasicInfoStatus = useSelector(({ user }) => user.status.fetchUserBasicInfo);
  const previousFetchUserBasicInfoStatus = usePrevious(fetchUserBasicInfoStatus);

  // Fetch the user info when the component is mounted
  React.useEffect(() => {
    // Fetch user info when the component is mounted and the user is authenticated, just avoid fetching
    // any user info on /quiz page where the quiz app runs, because it would end up fetching the data
    // unnecessarily whenever an answer is submitted because the page is reloaded.
    if (isAuthenticated() && typeof window !== 'undefined' && window.location.pathname !== '/quiz') {
      dispatchFetchUserData();
    }
  }, [dispatchFetchUserData]);

  // Once the user details are fetched successfully
  // Identify the user from the details received from the API
  React.useEffect(() => {
    if (previousFetchUserBasicInfoStatus !== fetchUserBasicInfoStatus && fetchUserBasicInfoStatus === STATUS.SUCCESS) {
      Analytics.identifyUser();
    }
  }, [fetchUserBasicInfoStatus, previousFetchUserBasicInfoStatus]);

  return fetchUserBasicInfoStatus === STATUS.REQUEST;
};

/* eslint-disable no-console */
import { toast } from 'react-toastify';
import { isValueSet, isValidURL, getDevfolioUserCookie, isValidPhone } from '@devfolioco/helpers';

import debounce from 'lodash/debounce';
import mapKeys from 'lodash/mapKeys';
import isEmpty from 'lodash/isEmpty';
import moment from '@helpers/moment';
import pD from 'parse-domain';

import { BASE_URL, SPACES_BASE_URL, GOOGLE_CLIENT_ID, API_ROOT } from '@constants/environment';
import { ERRORS } from '@constants/errors';
import { ETHINDIA_2023_SLUG, GOOGLE_GENAI_EXCHANGE_2024_SLUG } from '../../shared/hackathon';
import Email from '../assets/email.png';
import {
  APPLY_MODE,
  APPLY_STATUS,
  CHECK_CONTACT_FIELDS,
  CHECK_EXTRA_FIELDS,
  CHECK_FIELDS,
  CHECK_PROFILE_FIELDS,
  CLIENT_PARAM,
  EMAIL_DOMAINS,
  PROFILES_ARRAY,
  SERVER_PARAM,
  STATE,
  HACKATHON_FILTERS,
  PROJECT_DESCRIPTION_QUESTIONS,
  ACTION_STATUS as STATUS,
  AMZ_ACL,
  AMZ_HEADER,
  USER_STATUS_DELETED,
  GLOBAL_URLS,
  YOUTUBE_HOST_NAMES,
  VIDEO_HOST_NAMES,
  GOOGLE_DRIVE_HOST_NAMES,
} from '../constants';
import { ALLOWED_PROFILE_LINKS_REGEX } from '../../constants';
import { storeItem, getItemFromStorage, removeItemFromStorage } from './localStorage';
import { EXTERNAL_API, API, SEARCH_API } from '../api';

import store from '../../shared/store';
import { logger } from './logger';

/**
 * Checks if Date is in a valid DD/MM/YYYY format
 *
 * @param {string} date The Date String
 * @returns {boolean} Either true or false
 */
export const isDateValid = date => {
  const DateRegExp = RegExp(
    /^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\d|2[0-8])[- /.]02)[- /.]\d{4}|29[- /.]02[- /.](\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$/
  );

  return DateRegExp.test(date);
};

export const mapToClient = object => mapKeys(object, (value, key) => CLIENT_PARAM[key]);

export const mapToServer = object => mapKeys(object, (value, key) => SERVER_PARAM[key]);

/**
 * Returns the error object when found in the API response,
 * else returns the raw response data.
 * @param {*} error The response object received from the API.
 */
export const getError = error => {
  const responseData = error?.response?.data;
  return responseData?.error || responseData;
};

export const getState = state => store.getState()[state];

export const getHostName = input => {
  /**
   * Returns the hostname from the input
   * Returns the input if value is not a URL
   */
  if (isValidURL(input)) {
    return hostNamePattern.exec(input).toString().replace(/^www./i, '');
  }

  return input;
};

const hostNamePattern = /(?:\w+\.)+\w+/i;

/**
 *
 * @param {*} project the status of the project in case of online hackathon
 * @returns project status
 */
export const getProjectStatus = project => {
  return project ? project.status : 'project_not_created';
};

export const getStatus = userHackathon => {
  return userHackathon.hackathon.is_online && userHackathon.status
    ? getProjectStatus(userHackathon.project)
    : getApplicationStatus(userHackathon);
};

/**
 * Get the hacker's application status
 *
 * @param {*} hackathon the user hackathon details object containing status and team
 * @returns
 */
export const getApplicationStatus = hackathon => {
  // When the hacker status is withdraw, do not check for team status
  // and consider application status as withdrawn regardless
  if (hackathon.status === APPLY_STATUS.WITHDRAW) {
    return hackathon.status;
  }

  // If the apply mode is team, return the team's status
  if (hackathon.apply_mode === APPLY_MODE.TEAM) {
    if (hackathon.team === null) {
      return null;
    }

    return hackathon.team.status;
  }

  // When team is present return the team status or else return individual status
  return hackathon.team === null ? hackathon.status : hackathon.team.status;
};

export const toCheck = field => {
  /**
   * Some missing fields have to be skipped. Those of type boolean or custom fields
   * @param {string} field - a valid CLIENT_PARAM
   */

  if (field === CLIENT_PARAM.is_education) {
    return false;
  }

  return true;
};

export const isFieldsMissing = async (missingFields, values) => {
  if ('hasNoFormalEducation' in values) {
    if (values.hasNoFormalEducation) {
      return false;
    }
  }

  if (missingFields.includes('graduation_year') && !missingFields.includes('graduation_month')) {
    missingFields.push('graduation_month');
  }

  if (missingFields.includes('current_college')) {
    missingFields.splice(missingFields.indexOf('current_college'), 1);
  }

  const missingFieldsFilled = missingFields.map(field =>
    toCheck(CLIENT_PARAM[field]) ? isValueSet(CLIENT_PARAM[field], values).then(res => res) : true
  );

  const missingFieldsFilledResult = await Promise.all(missingFieldsFilled);

  if (missingFieldsFilledResult.includes(false)) {
    return true;
  }

  return false;
};

export const getExpectedYearsArray = () => {
  const years = [];
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year < currentYear + 10; year += 1) {
    years.push(year);
  }

  return years;
};

export const getYearsArray = (fromYear = 1950, toYear = new Date().getFullYear()) => {
  const years = [];

  for (let year = toYear; year >= fromYear; year -= 1) {
    years.push(year);
  }

  return years;
};

export const getMonthsArray = (fromMonth = 0, toMonth = 11) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (typeof fromMonth === 'string') fromMonth = parseInt(fromMonth, 10);
  if (typeof toMonth === 'string') toMonth = parseInt(toMonth, 10);

  return months.slice(fromMonth, toMonth + 1);
};

/**
 * Get specific address details from a geosuggest address component
 * @param {*} value undefined if input is empty
 * @returns {object} pinCode, city, state, and country
 */
export const extractAddress = value => {
  let pinCode = '';
  let city = '';
  let state = '';
  let country = '';

  if (value !== undefined) {
    try {
      const address = value.gmaps.address_components;

      const postalCodeFilter = address.filter(x => x.types[0] === 'postal_code');
      const cityFilter = address.filter(x => x.types[0] === 'locality');
      const stateFilter = address.filter(x => x.types[0] === 'administrative_area_level_1');
      const countryFilter = address.filter(x => x.types[0] === 'country');

      if (countryFilter.length) country = countryFilter[0].long_name;

      if (stateFilter.length) {
        state = stateFilter[0].long_name;
      }

      if (cityFilter.length) {
        city = cityFilter[0].long_name;
      }

      if (postalCodeFilter.length) pinCode = postalCodeFilter[0].long_name;
    } catch (error) {
      return { city, state, country, pinCode };
    }
  }

  return { city, state, country, pinCode };
};

export const hasHackathonEnded = hackathonEndTime => moment(hackathonEndTime).diff(moment(), 'seconds') < 0;

export const hasHackathonStarted = hackathonStartTime => moment(hackathonStartTime).isBefore(moment());

export const getLocalTimeFromUTC = momentTimeObject => moment.utc(momentTimeObject).local();

export const hasTimePassed = momentTimeObject => moment().diff(momentTimeObject, 'seconds') > 0;

/**
 * From a user object which has first_name, last_name and username
 * return a display name. The first name and last name might be null,
 * in that case return the username else return the item that is not
 * null. If both are present, return a concatenated string. If nothing,
 * return am empty string
 *
 * @param {string} user.first_name
 * @param {string} user.last_name
 * @param {string} user.username
 * @param {string} user.status Indicates whether the user has deleted account or not
 * @returns {string} the display name
 */
export const getDisplayName = user => {
  const hasUsername = typeof user.username !== 'string';
  const hasName = typeof user.first_name === 'string' || typeof user.last_name === 'string';

  if (isUserDeleted(user)) {
    return 'Deleted User';
  }

  if (!hasUsername && !hasName) {
    return ` `;
  }

  return hasName ? `${user.first_name} ${user.last_name}`.trim() : `${user.username}`;
};

/**
 * Checks if the user has deleted account using the user
 * object
 *
 * @param {string} user.status Indicates whether the user has deleted account or not
 * @returns {boolean} Indicating whether the user has deleted account
 */
export const isUserDeleted = user => user?.status === USER_STATUS_DELETED;

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

/**
 * Returns the inverted color.
 * Refer https://www.w3.org/TR/AERT/#color-contrast for the brightness
 * calculation
 * @param {string} color - in Hex
 * @returns hex (#ffffff or #000000)
 */
export const getInvertedColor = color => {
  const { r, g, b } = hexToRgb(color);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  if (brightness < 123) {
    return '#ffffff';
  }

  return '#000000';
};

/**
 * Save the project to local storage
 *
 * @param {*} project
 */
export const saveProject = ({ file, pictures, ...restProjectItems }) => {
  storeItem(`devfolio-project`, restProjectItems);
};

/**
 * Get the project associated with the hackathonID
 *
 * @returns {object} project
 */
export const getProject = () => {
  const project = getItemFromStorage(`devfolio-project`);

  if (project !== null) {
    return project;
  }

  return null;
};

export const isGithubLink = link => {
  if (!link.startsWith('http')) {
    link = `https://${link}`;
  }

  const parsed = pD(link);

  return parsed && parsed.domain === 'github';
};

/**
 * Are all the fields required to update or add education section empty?
 * @returns {boolean} - true if all fields are empty
 */
export const areAllRequiredFieldsEmpty = fields => {
  const { educationInstitution, fieldOfStudy, monthOfGraduation, yearOfGraduation, degreeType } = fields;

  if (
    degreeType === '' &&
    (educationInstitution === undefined || educationInstitution.uuid === undefined) &&
    fieldOfStudy === undefined &&
    monthOfGraduation === '' &&
    yearOfGraduation === ''
  ) {
    return true;
  }

  return false;
};

/**
 * Are all the fields required to update or add education section filled?
 * @returns {boolean} - true if all fields are filled
 */
export const areAllRequiredFieldsFilled = fields => {
  const { educationInstitution, fieldOfStudy, monthOfGraduation, yearOfGraduation, degreeType } = fields;

  if (
    degreeType !== '' &&
    educationInstitution !== undefined &&
    educationInstitution.uuid !== undefined &&
    fieldOfStudy !== undefined &&
    monthOfGraduation !== '' &&
    yearOfGraduation !== ''
  ) {
    return true;
  }

  return false;
};

/**
 * Returns whether or not to add a new Education user entity
 * @returns {boolean} - if true send an add education request else update
 */
export const shouldAddEducation = fields => {
  const { educationID } = fields;

  if (educationID.length === 0) {
    if (areAllRequiredFieldsFilled(fields)) {
      return true;
    }
  }

  return false;
};

export const getHackathonDatesRange = (startDate, endDate) => {
  // If the hackathon start year and hackathon end year date are different show
  // the year and months like so: December 30, 2018 - January 1, 2019
  if (startDate.year() !== endDate.year()) {
    return `${startDate.format('MMMM D, YYYY')} - ${endDate.format('MMMM D, YYYY')}`;
  }

  // If the hackathon start month and hackathon end month date are different show
  // the year and months like so: March 30 - April 1, 2019
  if (startDate.month() !== endDate.month()) {
    return `${startDate.format('MMMM D')} - ${endDate.format('MMMM D, YYYY')}`;
  }

  return `${startDate.format('MMMM D')} - ${endDate.format('D, YYYY')}`;
};

/**
 * Returns whether or not to update an experience user entity
 * @returns {boolean} - if true send an add education request else update
 */
export const shouldUpdateExperience = experienceItem => {
  const { company, title } = experienceItem;
  if (
    company &&
    company.hasOwnProperty('name') &&
    typeof company.name === 'string' &&
    company.name.length &&
    typeof title === 'string' &&
    title.length
  )
    return true;

  return false;
};

/**
 * Returns whether or not to delete an experience item
 * @returns {boolean} - if true the experience item is empty and can be deleted
 */
export const shouldDeleteExperience = experienceItem => {
  const { start, end, title, description: desc, company } = experienceItem;
  return (
    start === null &&
    end === null &&
    title === '' &&
    desc === '' &&
    (company.name === '' || undefined) &&
    !shouldUpdateExperience(experienceItem)
  );
};

export const getInt = value => Number.parseInt(value, 10);

/**
 * Removes the leading whitespace.
 * Used as an alternative to trimStart for cross-browser support.
 * @param {string} [string='']
 */
export const trimStart = (string = '') => string.replace(/^\s+/g, '');

/**
 * Route to the organizer dashboard for the given hackathon slug
 * @param {string} slug the hackathon slug
 */
export const routeToOrganizerDashboard = slug => {
  if (slug) {
    window.location.replace(`${GLOBAL_URLS.ORG_DASH}${slug}`);
  } else {
    window.location.replace(`${GLOBAL_URLS.ORG_DASH}`);
  }
};

/**
 * Check equality between two supplied hostname strings
 * @param {String} hostnameA - first hostname argument
 * @param {String} hostnameB - second hostname argument
 */
export const isHostnameEqual = (hostnameA, hostnameB) => {
  if (!hostnameA || !hostnameB) return false;
  const parsedA = pD(hostnameA);
  const parsedB = pD(hostnameB);
  return (
    parsedA?.subdomain === parsedB?.subdomain && parsedA?.domain === parsedB?.domain && parsedA?.tld === parsedB?.tld
  );
};

export const getDetailedProfile = async profileObject => {
  /**
   * Obtains the profile name and logo from the given value
   * @param {Object} profileObject - with null logo and name
   * @returns {Object} - profileObject with updated name and logo
   */
  const updatedProfile = profileObject;
  const parsedProfile = pD(updatedProfile.value);
  let profileDetails =
    parsedProfile != null
      ? PROFILES_ARRAY.find(obj => parsedProfile.domain === pD(obj.url).domain && parsedProfile.tld === pD(obj.url).tld)
      : undefined;
  if (profileDetails === undefined) {
    profileDetails = await EXTERNAL_API.searchCompanies(updatedProfile.value)
      .then(res => (Array.isArray(res.data) && res.data.length > 0 ? res.data[0] : undefined))
      .then(res => (isHostnameEqual(updatedProfile.value, res?.domain) ? res : undefined))
      .catch(() => undefined);
  }
  if (profileDetails !== undefined) {
    const { logo, name } = profileDetails;
    updatedProfile.logo = logo;
    updatedProfile.name = name;
  } else {
    updatedProfile.logo = null;
    updatedProfile.name = 'Link';
  }

  return new Promise(resolve => resolve(updatedProfile));
};

export const getEmailProviderLogo = async domain => {
  /**
   * Obtains the logo for the given email provider
   * @param {String} domain - domain name of the email provider
   * @returns {Object} - with the name, domain and logo of email provider
   */
  let provider;
  provider = EMAIL_DOMAINS.find(data => data.domain === domain);
  if (provider === undefined) {
    const MXRecord = await EXTERNAL_API.checkMXRecord(domain)
      .then(res =>
        res.data && Array.isArray(res.data.Answer) && res.data.Answer.length > 0
          ? res.data.Answer.find(data => data.name === domain.concat('.'))
          : undefined
      )
      .catch(error => {
        if (apiErrorStatus.internalServerError(error)) {
          logger.error('api', error, { action: 'checkMXRecord' });
        }
        return undefined;
      });
    if (MXRecord) {
      let record = MXRecord.data && MXRecord.data.match(/\w*\.\w*\.$/);
      record = Array.isArray(record) ? record[0] : null;
      provider = EMAIL_DOMAINS.find(data => data.mx_domain === record);
      if (provider)
        return new Promise(resolve => resolve({ domain: provider.domain, logo: provider.logo, name: provider.name }));
    }
    return new Promise(resolve => resolve({ domain, name: domain, logo: Email }));
  }
  return new Promise(resolve => resolve({ domain: provider.domain, logo: provider.logo, name: provider.name }));
};

export const isValidHostURL = (hostName, value) => {
  const url = hostName;
  const parsedValue = pD(value);
  const parsedURL = pD(url);

  if (parsedValue === null) return false;

  if (isValidURL(value) && url !== undefined) {
    return parsedValue.domain === parsedURL.domain && parsedValue.tld === parsedURL.tld;
  }

  return isValidURL(value);
};

export const validateLink = (name, value, required, onChange) => {
  const hostName = name === 'gitHubProfile' ? 'github.com' : 'linkedin.com';
  if (isValidHostURL(hostName, value)) {
    onChange(name, value);
  } else if (required || (!required && value === '')) {
    onChange(name, '');
  } else onChange(name, value);
};

export const getUniqueElements = array => array.filter((item, position, arr) => arr.indexOf(item) === position);

export const generateInitialValues = (questions, context) => {
  const Fields = CHECK_FIELDS.concat(CHECK_EXTRA_FIELDS).concat(CHECK_CONTACT_FIELDS).concat(CHECK_PROFILE_FIELDS);
  const initialValues = questions.reduce((obj, { uuid, type }) => {
    if (Fields.indexOf(uuid) > -1) {
      if (uuid === 'gitHubProfile') return { ...obj, [uuid]: context.links.gitHub.value };
      if (uuid === 'linkedInProfile') return { ...obj, [uuid]: context.links.linkedIn.value };
      return { ...obj, [uuid]: context[uuid] };
    }
    if (type === 'company') return { ...obj, [uuid]: { company_name: '', company_domain: '' } };
    if (type === 'PhoneNumber') return { ...obj, [uuid]: '', [`dummy${uuid}`]: '' };
    return { ...obj, [uuid]: '' };
  }, {});
  return initialValues;
};

export const getSectionsTillTab = (questions, tab) => {
  const sections = getUniqueElements(questions.map(question => question.section));
  let finalQuestions = [];
  for (let i = 0; i <= tab; i += 1) {
    finalQuestions = [...finalQuestions, ...questions.filter(question => question.section === sections[i])];
  }
  return finalQuestions;
};

export const onSuggestSelect = (suggest, name, onChange) => {
  let value;

  if (suggest) {
    value = suggest.label;
  } else {
    value = '';
  }

  onChange(name, value);
};

export const getRadioBool = value => {
  if (value === '') return value;
  return value ? 'Yes' : 'No';
};

export const getUpdatedFields = (checkFields, values, user) => {
  const changedFields = checkFields.filter(el => values[el] !== user[el]);
  const updatedFields =
    changedFields.length > 0
      ? changedFields.reduce(
          (o, key) => ({
            ...o,
            [SERVER_PARAM[key]]: values[key],
          }),
          {}
        )
      : {};
  Object.keys(updatedFields).forEach(key => updatedFields[key] === undefined && delete updatedFields[key]);
  return updatedFields;
};

export const getUsername = () =>
  new Promise((resolve, reject) => {
    try {
      const authenticationState = getState(STATE.AUTHENTICATION);

      const { username } = authenticationState;

      if (typeof username === 'string' && username.length > 0) {
        resolve(username);
      } else {
        reject(new Error('Username not found'));
      }
    } catch (error) {
      reject(new Error('Error occured while fetching the username', error));
    }
  });

export const isAuthenticated = () => {
  const authState = getDevfolioUserCookie();
  const isAuthStateValid = !!authState?.uuid;
  return isAuthStateValid;
};

export const getErrorMessage = errorObj => {
  if (errorObj && errorObj.message) {
    const { message } = errorObj;
    return message;
  }
  if (errorObj && typeof errorObj.source === 'object') {
    const sourceMessage = errorObj.source[Object.keys(errorObj.source)[0]].msg;
    return sourceMessage;
  }
  const errorMessage = typeof errorObj === 'string' ? errorObj.source : '';
  return errorMessage;
};

export const getHackathonPublicPage = subdomain => {
  const base = BASE_URL.replace(/https?:\/\//i, '');
  return `https://${subdomain}.${base}`;
};

export const openSubmissions = subdomain => {
  const submissionsWindow = window.open(`${getHackathonPublicPage(subdomain)}submissions`, '_blank');
  submissionsWindow.focus();
};

export const getDevfolioQuizObject = () => {
  const devfolioQuiz = getItemFromStorage('devfolio-quiz');
  return devfolioQuiz;
};

export const removeDevfolioQuizObject = () => {
  removeItemFromStorage('devfolio-quiz');
};

export const convertArrayToObject = arr => arr.reduce((acc, stage) => ({ ...acc, [stage]: stage }), {});

export const getObjectDifference = (oldObject, newObject) => {
  const updatedKeys = Object.keys(newObject).filter(key => newObject[key] !== oldObject[key]);
  return updatedKeys;
};

export const getArrayDifference = (oldArray, newArray, key) => {
  const removedItems = oldArray.filter(item =>
    key ? !newArray.find(newArrayObj => newArrayObj[key] === item[key]) : !newArray.includes(item)
  );
  const newItems = newArray.filter(item =>
    key ? !oldArray.find(oldArrayObj => oldArrayObj[key] === item[key]) : !oldArray.includes(item)
  );
  return [newItems, removedItems];
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const getImageWithRandomNumber = image => `${image}?v=${Math.floor(Math.random() * 10) + 1}`;

export const mapToProject = project => ({
  uuid: project.uuid,
  description: project.description || PROJECT_DESCRIPTION_QUESTIONS,
  file: project.file,
  name: project.name,
  tagline: project.tagline,
  status: project.status,
  slug: project.slug,
  hashtags: (project.hashtags && project.hashtags.map(hashtag => ({ id: hashtag.uuid, text: hashtag.name }))) || [],
  pictures:
    (project.pictures && project.pictures.split(',').map(picture => ({ preview: `${SPACES_BASE_URL}${picture}` }))) ||
    [],
  videoUrl: project.video_url,
  links: (project.links && project.links.split(',').map(link => ({ id: link, text: link }))) || [],
  tracks: project?.prize_tracks?.map(track => track.uuid) || [],
  trackDescriptions:
    project?.prize_tracks?.reduce((acc, track) => {
      acc[track.uuid] =
        // The description can be inside project_tracks or project_prizes when fetching the project details in hackathon or it can be in
        // description when tracks are fetched separately. Ensure to read the description from project_tracks or project_prizes first
        // because in case of the former it also contains the description field which is added by the organizer
        track?.project_tracks?.description ??
        track?.project_prizes?.description ??
        track?.description ??
        track?.project_track_description ??
        '';
      return acc;
    }, {}) ?? {},
  platforms: project?.platforms || [],
  favicon: project.favicon && { preview: project.favicon },
  coverImage: project.cover_img && { preview: project.cover_img },
  // ETHIndia Specific fields
  judgingSubmissionType: project?.judging_submission_type ?? null,
  emoji: project?.emoji ?? null,
  demoURL: project?.demo_url ?? null,
  sourceCodeURL: project?.source_code_url ?? null,
  category: project?.category ?? null,
  techWebFrameworks: project?.tech_details?.tech_web_frameworks ?? null,
  techLanguages: project?.tech_details?.tech_languages ?? null,
  techDevtools: project?.tech_details?.tech_devtools ?? null,
  techDesignTools: project?.tech_details?.tech_design ?? null,
  techDatabases: project?.tech_details?.tech_databases ?? null,
  techBlockchains: project?.tech_details?.tech_blockchains ?? null,
  // ETHDenver 2024 specific field
  communityExpoOptIn: project?.community_expo_opt_in ?? false,
  architectureDiagram: project?.architecture_diagram && { preview: project?.architecture_diagram },
  // MumbaiHacks 2024 specific field
  byStudentTeam: project?.by_student_team ?? false,
});

export const generateID = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

/**
 * Populates the skills dropdown
 */
export const getSkillsOptions = (input, callback) => {
  if (!input) {
    callback([]);
    return;
  }

  SEARCH_API.searchSkills(input)
    .then(({ hits }) => callback((Array.isArray(hits) ? hits : []).map(hit => ({ ...hit, value: hit.uuid }))))
    .catch(() => callback([]));
};

// get project URL
export const getProjectUrl = slug => {
  return `${BASE_URL}projects/${slug}`;
};

export const parseHackathonSearchFilter = (filter, query) => {
  const hackathonFilters = {};

  if (filter?.timeFrame !== HACKATHON_FILTERS.ALL) {
    hackathonFilters.type = filter.timeFrame;
  }

  if (query) {
    hackathonFilters.q = query;
  }

  if (filter?.cities?.length) {
    hackathonFilters.location = filter.cities;
  }

  if (filter?.tags?.length) {
    hackathonFilters.hashtags = filter.tags;
  }

  if (filter.hackathonType.length && filter.hackathonType.length < 2) {
    hackathonFilters.is_online = !!filter.hackathonType.includes('online');
  }

  return hackathonFilters;
};

export const trimWithEllipsis = (text, length = 50) => (text.length > length ? `${text.slice(0, length)}...` : text);

// make a list in the Oxford comma style (eg "a, b, c, and d")
// Examples with conjunction "and":
// ["a"] -> "a"
// ["a", "b"] -> "a and b"
// ["a", "b", "c"] -> "a, b, and c"

export const oxfordJoin = (arr, conjunction = 'and', ifEmpty = '') => {
  const l = arr.length;
  if (!l) return ifEmpty;
  if (l < 2) return arr[0];
  if (l < 3) return arr.join(` ${conjunction} `);
  arr = arr.slice();
  arr[l - 1] = `${conjunction} ${arr[l - 1]}`;
  return arr.join(', ');
};

export const isValidNameURL = (name, value) => {
  if (value && isValidURL(value)) {
    if (name in ALLOWED_PROFILE_LINKS_REGEX) {
      // use regex for the name
      return ALLOWED_PROFILE_LINKS_REGEX[name].test(value);
    }

    // if there is not regex for the name use this fallback logic
    const parsedValue = pD(value);
    return parsedValue && parsedValue.domain ? name.replace(/\s/g, '').toLowerCase() === parsedValue.domain : false;
  }
  return false;
};

export const isFieldEmpty = (userStore, field, userError) => {
  const fieldValue = userStore[field];

  if (field === CLIENT_PARAM.phone_number) {
    return !isValidPhone(fieldValue ?? '') || userError === ERRORS.phoneNumberDuplicate;
  }

  if (field === 'experience' && userStore.hasNoWorkExperience) {
    return false;
  }
  if (Array.isArray(fieldValue) && !isEmpty(fieldValue)) {
    return !fieldValue.some(item => item.uuid);
  }
  if (typeof fieldValue === 'number' || typeof fieldValue === 'boolean') {
    return !fieldValue;
  }
  return isEmpty(fieldValue);
};

export const hasFilledRequiredLinks = profileLinks => {
  if (!(Array.isArray(profileLinks) && profileLinks.length)) {
    return true;
  }

  const links = profileLinks;
  const { otherProfiles } = getState(STATE.USER);
  if (
    profileLinks.includes('otherProfiles') &&
    Array.isArray(otherProfiles) &&
    otherProfiles.length &&
    otherProfiles.some(profile => profile.uuid)
  ) {
    return true;
  }

  return links.every(link => {
    const hasProfile = otherProfiles.find(profile => profile.name === link);
    return hasProfile && hasProfile.uuid;
  });
};

export const getFileType = file => {
  const mimeType = file?.type;
  if (mimeType === undefined) {
    // To make this comply with other function calls without breaking anything
    return { type: undefined, mimeType: undefined };
  }
  let type = mimeType.split('/');
  type = type[type.length - 1];
  return { type, mimeType };
};

export const getHackathonCityAndCountry = hackathon => {
  if (hackathon.city && hackathon.country) {
    return `${hackathon.city}, ${hackathon.country}`;
  }
  return hackathon.location;
};

export const printHiringLog = () => {
  console.clear();
  console.log(
    `
  %ctS888888888888888888888@X 88%.
  :8888888S@88888888S888@888@888X@:8@.
  t888X8888@888@888@X8X8S8S8X88888888 .X.
  88888%888S888888888888X88X8888@88888888X
  88X88888X88@@8X888888@88888X8888@88S888 8%
  8888888@X888888@8888X8888X88888888@888@8@S8:
  888888XX888888888888888%888@8888888888888@S8
  8888X88S888888X88888@8888888@S@888888888X8888
  @8X8888@88@8@8@88@8888@S@88888888888888@8888@;
  @8888888@@88888@8@8888888888888X8888888@8@@8@8
  8S888888888@888888@@8888@88@888888888S@%8@88X@@:
  88888@8888@88888X@888XX@88888888X8888888X8S8888
  88@8888888888888@88@@@@88888@88888X8888888888888
  88X88888888@8888@88@@@8888@8XX888888@88XX8X88888;
  88@S8%88@88S@8@8@8@@@888@888S88@8X888@8888@88888t
  88X88888888888888888888888%8888X8@8X8888@888X@88S
  888888@S8@888888@88@888888@888@X8888888@88@8@888:
  88X888@88X888888888@888@8@@88S888888888@88888888.S
  88@8@8888888@X%@88888888888S88888888888888X8888;88
  88@@8888@888888@888@8888888@8888888X88888888@8@%88
  88@888@8888888@8@@X88@888888888X88888@88888888:88S
  888X8@8888888888888888888888@8888888888@888@8::88
  88X88X888888S88888888888@888888X88888@8888@88:88;
  88@8888%888@888@X@888X888@S@888888X8888888@8%@X8@
  88@@S888888888@X888888@888888@888888@S@888X:8X8@
  88X8888888X88%88888888@88888@888888888@8@%S8X88
  8S8888888888X888888@8888S88888@88888888 .8@@8%
  @888S8888888888@8@8888@8888888@888X8Xt.X88888
  :88@88@8S88888888888X8888@88X8888888;88@88@@.
    .tXS8@88888888888@888@888%X% SX8@88@888S
    ;t.:%tS.tS%%%:%%t.;;;;;;:;%SX8X@8@8888.
    %8888888888@@8888888888888X8@8888888.
    :;.88888@88888888@88888888X8@; % ::
  `,
    'color:#386ef6;'
  );
  console.log(
    "%cWe're hiring! https://devfolio.co/careers",
    "font-family: 'Nunito Sans', sans-serif; font-size: 18px; font-weight: bold;"
  );
};

export const showCopyToast = debounce(() => {
  toast('Copied to clipboard!', {
    autoClose: 1000,
    type: toast.TYPE.INFO,
  });
}, 300);

/**
 * Checks if a given point with co-ordinates (a, b) is inside a
 * circle with center (x, y) and radius r using the Pythagorean theorem.
 * @param {Number} a x position of the point
 * @param {Number} b y position of the point
 * @param {Number} x x position of the center of circle
 * @param {Number} y y position of the center of circle
 * @param {Number} r radius of the circle
 */
export const isPointInsideCircle = (a, b, x, y, r) => {
  const distance = (a - x) * (a - x) + (b - y) * (b - y);
  return distance < r * r;
};

export const getProjectData = async (username, hackathonID, project) => {
  let projectData = {};
  let removeHashtagPromises = [];
  let removePrizePromises = [];
  const { oldProjectState } = getState(STATE.HACKATHON);
  const [newHashtags, removedHashtags] = getArrayDifference(
    oldProjectState?.hashtags || [],
    project?.hashtags || [],
    'id'
  );
  const [newTracks, removedTracks] = getArrayDifference(oldProjectState?.tracks || [], project?.tracks || []);

  // Remove and add again the tracks with updated description
  // ======================================================
  const tracksWithUpdatedDescription = project?.tracks?.filter(
    track => project?.trackDescriptions?.[track] !== oldProjectState?.trackDescriptions?.[track]
  );

  if (Array.isArray(tracksWithUpdatedDescription)) {
    tracksWithUpdatedDescription.forEach(track => {
      // Only add to removed tracks if the track is not already present in
      // new track
      if (!newTracks.includes(track) && !removedTracks.includes(track)) {
        removedTracks.push(track);
      }
      if (!newTracks.includes(track)) {
        newTracks.push(track);
      }
    });
  }
  // ====================================================

  let newFaviconToBeUploaded = null;
  let newCoverImageToBeUploaded = null;
  let newArchitectureDiagramImageToBeUploaded = null;
  const filesToBeUploaded = [];
  if (project.hasOwnProperty('pictures') && Array.isArray(project.pictures) && project.pictures.length > 0) {
    const { pictures: allPictures } = project;
    const alreadyUploadedPictures = allPictures.filter(pic => !(pic instanceof File) && pic.preview);
    const newPictures = allPictures.filter(pic => pic instanceof File);
    const promises = newPictures.map(picture => {
      const { type } = getFileType(picture);
      const picName = `pic${generateID()}`;
      if (hackathonID) {
        return API.hackathon.getHackathonProjectImageUploadURL(username, hackathonID, type, picName);
      }
      return API.myProjects.getProjectImageUploadURL(username, project.uuid, type, picName);
    });
    const urls = await Promise.all(promises);
    const projectImageUrls = [];

    const newUploads = urls.map((url, index) => {
      const {
        data: { signedUrl, url: uri },
      } = url;
      const file = newPictures[index];
      projectImageUrls.push(uri);
      const { mimeType } = getFileType(file);
      return {
        signedUrl,
        mimeType,
        file,
      };
    });
    filesToBeUploaded.push(...newUploads);

    projectData = Object.assign({}, projectData, {
      pictures: `${alreadyUploadedPictures.map(pic => pic.preview.replace(SPACES_BASE_URL, ''))}${
        alreadyUploadedPictures.length && projectImageUrls.length ? ',' : ''
      }${projectImageUrls.join(',')}`,
    });
  }

  if (project.hasOwnProperty('favicon') && project.favicon) {
    const { favicon } = project;
    const { type, mimeType } = getFileType(favicon);
    // Refer to line 948 and 949 in this file for the reason why we are doing this

    if (!(favicon instanceof File) && favicon.preview) {
      // Favicon is already uploaded
      newFaviconToBeUploaded = favicon.preview.replace(SPACES_BASE_URL, '');
    } else if (type !== undefined) {
      // Favicon is a new file to be uploaded
      const picName = `pic${generateID()}`;
      let url;

      if (hackathonID) {
        url = await API.hackathon.getHackathonProjectImageUploadURL(username, hackathonID, type, picName);
      } else {
        url = await API.myProjects.getProjectImageUploadURL(username, project.uuid, type, picName);
      }

      const {
        data: { signedUrl, url: uri },
      } = url;

      const faviconData = {
        signedUrl,
        mimeType,
        file: favicon,
      };

      filesToBeUploaded.push(faviconData);
      newFaviconToBeUploaded = uri;
    } else {
      // Favicon is not a compatible file
      logger.error('api', new Error('Favicon did not upload successfully'), {
        favicon,
        isFaviconAFile: favicon instanceof File,
        fileType: getFileType(favicon),
      });
    }
  }

  if (project.hasOwnProperty('coverImage') && project.coverImage) {
    const { coverImage } = project;
    const { type, mimeType } = getFileType(coverImage);

    if (!(coverImage instanceof File) && coverImage.preview) {
      // Cover Image is already uploaded
      newCoverImageToBeUploaded = coverImage.preview.replace(SPACES_BASE_URL, '');
    } else if (type !== undefined) {
      // Cover Image is a new file to be uploaded
      const picName = `pic${generateID()}`;
      let url;

      if (hackathonID) {
        url = await API.hackathon.getHackathonProjectImageUploadURL(username, hackathonID, type, picName);
      } else {
        url = await API.myProjects.getProjectImageUploadURL(username, project.uuid, type, picName);
      }

      const {
        data: { signedUrl, url: uri },
      } = url;

      const coverImageData = {
        signedUrl,
        mimeType,
        file: coverImage,
      };

      filesToBeUploaded.push(coverImageData);
      newCoverImageToBeUploaded = uri;
    } else {
      // Cover Image is not a compatible file
      logger.error('api', new Error('Cover Image did not upload successfully'), {
        coverImage,
        isCoverImageAFile: coverImage instanceof File,
        fileType: getFileType(coverImage),
      });
    }
  }

  if (project.hasOwnProperty('architectureDiagram') && project.architectureDiagram) {
    const { architectureDiagram } = project;
    const { type, mimeType } = getFileType(architectureDiagram);

    if (!(architectureDiagram instanceof File) && architectureDiagram.preview) {
      // Architecture Diagram Image is already uploaded
      newArchitectureDiagramImageToBeUploaded = architectureDiagram.preview.replace(SPACES_BASE_URL, '');
    } else if (type !== undefined) {
      // Architecture Diagram Image is a new file to be uploaded
      const picName = `pic${generateID()}`;
      let url;

      if (hackathonID) {
        url = await API.hackathon.getHackathonProjectImageUploadURL(username, hackathonID, type, picName);
      } else {
        url = await API.myProjects.getProjectImageUploadURL(username, project.uuid, type, picName);
      }

      const {
        data: { signedUrl, url: uri },
      } = url;

      const architectureImageData = {
        signedUrl,
        mimeType,
        file: architectureDiagram,
      };

      filesToBeUploaded.push(architectureImageData);
      newArchitectureDiagramImageToBeUploaded = uri;
    } else {
      // Cover Image is not a compatible file
      logger.error('api', new Error('Cover Image did not upload successfully'), {
        architectureDiagram,
        isCoverImageAFile: architectureDiagram instanceof File,
        fileType: getFileType(architectureDiagram),
      });
    }
  }

  if (project.hasOwnProperty('links') && project.links.length > 0) {
    const projectLinks = project.links;
    projectData = Object.assign({}, projectData, { links: projectLinks.map(link => link.text).join(',') });
  }

  if (project.hasOwnProperty('platforms') && Array.isArray(project.platforms)) {
    const projectPlatforms = project.platforms;
    projectData = Object.assign({}, projectData, { platforms: projectPlatforms });
  }

  if (newHashtags.length) {
    projectData = Object.assign({}, projectData, { hashtags: newHashtags.map(hashtag => hashtag.text) });
  }

  if (removedHashtags.length) {
    removeHashtagPromises = removedHashtags.map(hashtag => {
      if (hackathonID) {
        return API.hackathon.deleteHackathonProjectHashtag(username, hackathonID, project.uuid, hashtag.id);
      }
      return API.myProjects.deleteProjectHashtag(username, project.uuid, hashtag.id);
    });
  }

  if (newTracks.length) {
    projectData = Object.assign({}, projectData, { tracks: newTracks });
  }

  if (removedTracks.length) {
    removePrizePromises = removedTracks.map(trackID =>
      API.hackathon.deleteProjectTrack(username, hackathonID, project.uuid, trackID)
    );
  }

  await Promise.all([...removeHashtagPromises, ...removePrizePromises]);

  const projectVideoUrl = project.videoUrl;
  projectData = Object.assign({}, projectData, {
    video_url: typeof projectVideoUrl === 'string' && projectVideoUrl.length > 0 ? projectVideoUrl : null,
  });

  const projectAPIData = {
    name: project.name || '',
    tagline: project.tagline || '',
    favicon: newFaviconToBeUploaded,
    cover_img: newCoverImageToBeUploaded,
    description: project.description.length > 0 ? project.description : undefined,
    trackDescriptions: project.trackDescriptions ?? {},
    judging_submission_type:
      typeof project.judgingSubmissionType === 'string' ? project.judgingSubmissionType : undefined,
    demo_url: project.demoURL ?? undefined,
    source_code_url: project.sourceCodeURL ?? undefined,
    emoji: project.emoji ?? undefined,
    category: project.category ?? undefined,
    tech_web_frameworks:
      Array.isArray(project.techWebFrameworks) && project.techWebFrameworks.length > 0
        ? project.techWebFrameworks
        : undefined,
    tech_languages:
      Array.isArray(project.techLanguages) && project.techLanguages.length > 0 ? project.techLanguages : undefined,
    tech_devtools:
      Array.isArray(project.techDevtools) && project.techDevtools.length > 0 ? project.techDevtools : undefined,
    tech_design:
      Array.isArray(project.techDesignTools) && project.techDesignTools.length > 0
        ? project.techDesignTools
        : undefined,
    tech_databases:
      Array.isArray(project.techDatabases) && project.techDatabases.length > 0 ? project.techDatabases : undefined,
    tech_blockchains:
      Array.isArray(project.techBlockchains) && project.techBlockchains.length > 0
        ? project.techBlockchains
        : undefined,
    community_expo_opt_in: project.communityExpoOptIn ?? false,
    architecture_diagram: newArchitectureDiagramImageToBeUploaded,
    by_student_team: project.byStudentTeam ?? false,
    ...projectData,
  };

  return { projectData: projectAPIData, filesToBeUploaded };
};

export const isProjectVideoInvalid = (videoUrl, hackathonSlug) =>
  videoUrl.length > 0 &&
  // eslint-disable-next-line no-nested-ternary
  !(isValidURL(videoUrl) && hackathonSlug === ETHINDIA_2023_SLUG
    ? YOUTUBE_HOST_NAMES.includes(getHostName(videoUrl))
    : hackathonSlug === GOOGLE_GENAI_EXCHANGE_2024_SLUG
    ? GOOGLE_DRIVE_HOST_NAMES.includes(getHostName(videoUrl))
    : VIDEO_HOST_NAMES.includes(getHostName(videoUrl)));

// Check for elastic search null values
export const isNotInvalid = value => value && value !== 'null' && value !== 'Invalid date';

export const injectScript = src => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', e => {
      reject(e);
    });
    document.body.appendChild(script);
  });
};

export const isStatusReady = status => status === STATUS.READY;

/**
 * Matches the status values of previous props to the new props in componentDidMount
 * @param {object} prevProps the previous props
 * @param {object} newProps the updated props
 * @param {[string]} keys the keys that are to be compared from the previous props
 * @param {string} status the status the keys need to be compared to
 *
 * @returns {boolean} Whether the given condition is valid or not
 */
export const matchStatus = (prevProps, newProps, keys, status = STATUS.SUCCESS) => {
  return keys.reduce((res, key) => res || (prevProps[key] !== newProps[key] && newProps[key] === status), false);
};

/**
 * Returns the skill suggestions, by reading from session storage first
 * if not available in session storage then fetch the suggestions and store
 * it there for later use
 * @returns {[object]} array of suggested skills
 */
export const getSkillSuggestions = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      // const storedSuggestions = sessionStorage.getItem('skill_suggestions');
      // if (storedSuggestions) {
      //   const suggestions = JSON.parse(storedSuggestions);
      //   return resolve(suggestions);
      // }

      // const { hits } = await SEARCH_API.searchSkills('');
      const hits = [
        { name: 'Go', uuid: '715597b43676455faed24013e900081e' },
        { name: 'Kotlin', uuid: 'a305b08372964efea6b334278e7c8025' },
        { name: 'JavaScript', uuid: 'e59189759df54d51a91721744cb66a23' },
        { name: 'TypeScript', uuid: '48a74220af544e6d8db4ecceefc3a6e3' },
        { name: 'Rust', uuid: '78a65fdfebb54048b7b68548f948a3b0' },
        { name: 'Solidity', uuid: '67962960da224dd4a18e5badea9ba651' },
        { name: 'Node.js', uuid: '21cfcc75fe7046a687161d7dc43799ee' },
        { name: 'React.js', uuid: '60ba34b546a744288bb4e5e5e416bdf4' },
        { name: 'Python', uuid: '9ab0f6bd4042430db74b32ee1869e6ca' },
        { name: 'Shell', uuid: '978fdb3eb0f54fa8970de3aacbb785e1' },
      ];
      // sessionStorage.setItem('skill_suggestions', JSON.stringify(hits));

      return resolve(hits);
    } catch (error) {
      logger.error('api', error);
      return reject(error);
    }
  });
};

/*
  Get Twiiter handle from twitter profile link
*/

export const getTwitterhandle = link => {
  if (link) {
    const matches = link.match(/(?:https?:\/\/)?(?:www\.)?(twitter|x)\.com\/(?:#!\/)?@?([^/?\s]*)/);
    if (matches && matches[2]) {
      return `@${matches[2]}`;
    }
  }
  return '';
};

export const formatNumberToCurrency = (amount, significantDigits) =>
  new Intl.NumberFormat('en-IN', {
    maximumSignificantDigits: significantDigits,
  }).format(amount);

/**
 * This function checks for common API status codes
 * that can be used to set the severity level of errors
 * while pushing the logs to Sentry.
 */
export const apiErrorStatus = {
  internalServerError: error => {
    return error?.response?.status >= 500;
  },
  unprocessableRequestError: error => {
    return error?.response?.status === 422;
  },
};

/**
 * Returns the config with headers for file upload
 * @param {{contentType: string; isPrivate: boolean}} config.contentType The type of the file, config.isPrivate Is the file public or private
 */
export const getUploadFileConfig = ({ contentType, isPrivate }) => {
  return {
    headers: {
      'Content-Type': contentType,
      'Content-Disposition': 'inline',
      'Cache-Control': isPrivate ? 'max-age=0, must-revalidate' : 'public, max-age=31536000, immutable',
      [AMZ_HEADER]: isPrivate ? AMZ_ACL.PRIVATE : AMZ_ACL.PUBLIC_READ,
    },
  };
};

/**
 * This function returns the basic user details
 * that will be used by the analytics service for
 * identifying the user
 * @returns User Info object required as
 * required by the analytics service
 */
export const getAnalyticsUserInfo = () => {
  const { firstName, lastName, username, email } = getState(STATE.USER);

  return {
    firstName,
    lastName,
    username,
    email,
  };
};

/**
 * Initializes the google auth client and exposes the auth
 * client to the consumer.
 * @param object.handleCodeClientInit A function that is called when the client
 * is initialized and contains the auth client as the parameter
 * @param handleGoogleAuthCodeResponse A callback accepted by the google auth client
 * and is passed the code when google authentication is triggered.
 */
export const googleAuthInitializer = ({ handleCodeClientInit, handleGoogleAuthCodeResponse }) => {
  // Load google script
  // eslint-disable-next-line no-undef
  const isGoogleAvailable = typeof google !== 'undefined' && typeof google.accounts !== 'undefined';

  const initCodeClient = () => {
    // eslint-disable-next-line no-undef
    const client = google.accounts.oauth2.initCodeClient({
      client_id: GOOGLE_CLIENT_ID,
      /**
       * Since we're using the token for user authentication, we need these specific scopes.
       * See https://developers.google.com/identity/oauth2/web/guides/overview
       */
      scope: 'openid profile email',
      callback: handleGoogleAuthCodeResponse,
    });
    handleCodeClientInit(client);
  };

  if (isGoogleAvailable) {
    // Directly init the code client if the script
    // is already available
    initCodeClient();
  } else {
    injectScript('https://accounts.google.com/gsi/client')
      .then(() => {
        initCodeClient();
      })
      .catch(error => {
        logger.error('ui', new Error(`An error occured while loading the Google Client script ${error}`));
        toast('Please ensure that your browser is not preventing any third-party scripts from loading.', {
          type: toast.TYPE.ERROR,
        });
      });
  }
};

/**
 * This method fetches the hackathon file URL
 * for a file uploaded by the user while filling
 * the extra application form
 */
export const openPrivateHackathonFileInNewTab = async ({ userID, hackathonID, fieldID, fileURL }) => {
  const fileWindow = window.open('', '_blank');

  try {
    const response = await API.user.getHackathonFileViewURL(userID, hackathonID, fieldID, fileURL);
    if (typeof response?.data?.signedUrl === 'string') {
      fileWindow.location = response.data.signedUrl;
    }
  } catch (error) {
    logger.error('api', error, { userID, hackathonID, fieldID, fileURL });
  }
};

/**
 * Returns a formatted currency string according to
 * the given currency
 * @param amount The amount to be formatted
 * @param currency The currency to be used
 * @returns Formatted amount
 */
export const getFormattedAmount = (amount, currency = 'INR') => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(Math.round(amount));
};

/**
 * Returns the formatted amount for paisa to Rupees
 * @param amount The amount to be formatted
 * @returns Formatted amount
 */
export const getFormattedAmountFromPaisa = amount => {
  return getFormattedAmount(amount / 100);
};

export const connectOAuthProvider = async ({ provider, redirectURL }) => {
  const { username } = getState(STATE.USER);
  const redirectURLWithSearchParams = new URL(redirectURL);
  redirectURLWithSearchParams.searchParams.append('provider', provider);
  window.open(
    `${API_ROOT}users/${username}/oauth/${provider}/connect?redirect_url=${redirectURLWithSearchParams.toString()}`,
    '_self'
  );
};

import React from 'react';
import { Textarea } from './style';

class InputTextarea extends React.PureComponent {
  render() {
    const { name } = this.props;
    return <Textarea id={name} {...this.props} />;
  }
}

export default InputTextarea;

import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';

export const RichTextEditor = styled.div`
  cursor: text;
  overflow: auto;
  height: ${props => props.height};
  border-radius: 3px;
  line-height: 1.5;
  width: 100%;
  cursor: text;
  font-weight: 400;
  white-space: pre-line;
  font-size: 1em;
  color: ${({ theme }) => theme.color.black[0]};
  font-family: ${({ theme }) => theme.typography.font.primary};
`;

export const TextareaAuto = styled(TextareaAutosize)`
  background: none;
  border: none;
  color: ${props => props.theme.color.black[0]};
  font-family: ${props => props.theme.typography.font.primary};
  font-size: 17px;
  line-height: 25px;
  padding: 24px 0;
  width: 100%;
  resize: none;
  &:focus {
    border: none;
    outline: none;
  }
`;

import * as Sentry from '@sentry/browser';
import { Severity, Extras } from '@sentry/types';
import { AxiosError } from 'axios';

import { apiErrorStatus, getState } from '@helpers';
import { NODE_ENV } from '@constants/environment';
import { STATE } from '@constants';

type ValueOf<T> = T[keyof T];

export const ErrorType = {
  UI: 'ui',
  API: 'api',
} as const;

type ErrorInfoType = ValueOf<typeof ErrorType>;

type Error = Record<string, string> | AxiosError;

interface LogEvent {
  message?: string;
  level?: Severity;
  environment?: string;
  extra?: Extras;
  tags: Record<string, string>;
}

export const logEvent = ({ message, level = Severity.Info, environment = NODE_ENV, extra, tags }: LogEvent): void => {
  Sentry.captureEvent({
    message,
    level,
    environment,
    extra,
    tags,
  });
};

const captureException = (level: Severity, errorType: ErrorInfoType, error: unknown, extra: Extras = {}) => {
  Sentry.withScope(scope => {
    scope.setTag('error_type', errorType);
    scope.setLevel(Severity.Error);
    scope.setLevel(level);

    const { username, isAuthenticated, uuid: id } = getState(STATE.AUTHENTICATION);
    const { email } = getState(STATE.USER);

    if (isAuthenticated) {
      scope.setUser({ email, id, username });
    }

    Object.keys(extra).forEach(key => {
      scope.setExtra(key, extra[key]);
    });

    Sentry.captureException(error);
  });
};

export const logger = {
  error: (errorType: ErrorInfoType, error: unknown, extra?: Extras): void => {
    captureException(Severity.Error, errorType, error, extra);
  },
  warn: (errorType: ErrorInfoType, error: unknown, extra?: Extras): void => {
    captureException(Severity.Warning, errorType, error, extra);
  },
  info: (errorType: ErrorInfoType, error: unknown, extra?: Extras): void => {
    captureException(Severity.Info, errorType, error, extra);
  },
  debug: (errorType: ErrorInfoType, error: unknown, extra?: Extras): void => {
    captureException(Severity.Debug, errorType, error, extra);
  },
};

/**
 * Capture an error event if an internal server error or an
 * unknown error has occured in a Redux Async Action.
 *
 * @param action Action type name
 * @param error The error to be captured
 * @param data Additional data to go with extras
 * @param message Optional message
 *
 * @example
 * logActionError(types.SIGNIN, error, { hasReCaptchaLoaded, locationState })
 */
export const logActionError = (
  action: string,
  err: unknown,
  data: Record<string, string> = {},
  message?: string
): void => {
  const error = err as Error;
  const errorMeta = { action, data, message };

  if (NODE_ENV === 'development' || NODE_ENV === 'test') {
    console.error(error);
  }

  // Send an error log in case of internal server error.
  if (apiErrorStatus.internalServerError(error)) {
    logger.error('api', error, errorMeta);
    return;
  }

  // Send an error log in case of non-axios errors. For
  // example, a type error in the action.
  if (!error?.isAxiosError && error?.message) {
    logger.error('ui', error, errorMeta);
  }
};

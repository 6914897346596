import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';
import SAMPLE_QUIZ_QUESTIONS from '../constants/questions';

export const initialState = {
  csrfToken: '',
  quizzes: {
    data: [],
    count: 0,
    currentPage: 0,
  },
  quizzesPublic: {
    data: [],
    count: 0,
    currentPage: 0,
  },
  verifiedSkills: [],
  currentQuiz: {
    quizId: null,
    result: null,
  },
  currentQuestion: {
    codeSnippet: null,
    codeSnippetLanguage: 'javascript',
    options: [],
    estimatedTime: 0,
    number: 0,
    question: null,
    questionEndTime: null,
    total: 0,
  },
  sampleQuiz: {
    questions: SAMPLE_QUIZ_QUESTIONS,
  },
  status: {
    fetchQuizzes: STATUS.READY,
    fetchQuizzesPublic: STATUS.READY,
    submitResponse: STATUS.READY,
    submitSkip: STATUS.READY,
    submitTimeout: STATUS.READY,
    submitSampleResponse: STATUS.READY,
  },
};

const quiz = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_QUIZZES_PUBLIC: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          quizzesPublic: {
            ...state.quizzesPublic,
            count: payload.count,
            data: [...initialState.quizzesPublic.data, ...payload.quizzes],
          },
          status: {
            ...state.status,
            fetchQuizzesPublic: payload.status,
          },
        };
      }

      return {
        ...state,
        quizzesPublic: {
          ...state.quizzesPublic,
          currentPage: payload.currentPage || 0,
        },
        status: {
          ...state.status,
          fetchQuizzesPublic: payload.status,
        },
      };
    }

    case types.FETCH_QUIZZES: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          quizzes: {
            ...state.quizzes,
            count: payload.count,
            data: [...initialState.quizzes.data, ...payload.quizzes],
          },
          verifiedSkills: payload.verifiedSkills || [],
          status: {
            ...state.status,
            fetchQuizzes: payload.status,
          },
        };
      }

      return {
        ...state,
        quizzes: {
          ...state.quizzes,
          currentPage: payload.currentPage || 0,
        },
        status: {
          ...state.status,
          fetchQuizzes: payload.status,
        },
      };
    }

    case types.UPDATE_CURRENT_QUIZ: {
      const { payload } = action;
      return {
        ...state,
        currentQuiz: {
          ...payload,
        },
      };
    }

    case 'UPDATE_QUESTION': {
      const { payload } = action;
      return {
        ...state,
        currentQuestion: {
          ...payload,
        },
      };
    }

    case types.SUBMIT_QUESTION_RESPONSE: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          submitResponse: payload.status,
        },
      };
    }

    case types.SUBMIT_QUESTION_SKIP: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          submitSkip: payload.status,
        },
      };
    }

    case types.SUBMIT_QUESTION_TIMEOUT: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          submitTimeout: payload.status,
        },
      };
    }

    case types.SUBMIT_SAMPLE_QUESTION_RESPONSE: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          submitSampleResponse: payload.status,
        },
      };
    }

    default:
      return state;
  }
};

export default quiz;

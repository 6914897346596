import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';
import { getTextColorForBackground } from '../helpers/chroma';

export const initialState = {
  info: {
    name: '',
    description: '',
    textColor: '#243339',
    backgroundColor: '#3770ff',
    longDescription: '',
    illustrationSrc: '',
    types: [],
    successMessage: '',
    ticketLimitFemale: null,
    ticketLimitGeneral: null,
    ticketLimitMale: null,
    isRefundable: false,
  },
  status: {
    fetchTicketInfo: STATUS.READY,
    sendTransactionDetails: STATUS.READY,
  },
};

const ticket = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TICKET_INFO: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          info: {
            ...state.info,
            types: [...initialState.info.types, ...payload.types],
          },
          status: {
            ...state.status,
            fetchTicketInfo: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchTicketInfo: payload.status,
        },
      };
    }

    case types.SEND_TRANSACTION_DETAILS: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          sendTransactionDetails: payload.status,
        },
      };
    }

    case types.FETCH_HACKATHON_DATA_SUCCESS: {
      const {
        payload: { hackathon_setting: settings },
      } = action;

      return {
        ...state,
        info: {
          ...state.info,
          backgroundColor: settings?.primary_color || initialState.info.backgroundColor,
          description: settings?.razorpay_desc || initialState.info.description,
          illustrationSrc: settings?.ticket_img || initialState.info.illustrationSrc,
          longDescription: settings?.ticket_msg1 || initialState.info.longDescription,
          name: settings?.razorpay_title || initialState.info.name,
          successMessage: settings?.ticket_msg2 || initialState.info.successMessage,
          textColor:
            settings?.ticket_font_color ||
            getTextColorForBackground(settings?.primary_color || initialState.info.backgroundColor),
          ticketLimitFemale: settings?.ticket_limit_f,
          ticketLimitGeneral: settings?.ticket_limit,
          ticketLimitMale: settings?.ticket_limit_m,
          isRefundable: settings?.ticket_refundable || false,
        },
      };
    }

    default:
      return state;
  }
};

export default ticket;

import React from 'react';
import Media from 'react-media';
import Helmet from 'react-helmet';

import Logo from 'components/Logo';

import FiveHundred from 'assets/500-desktop.png';
import FiveHundredMobile from 'assets/500-mobile.png';

import { Container, Text } from './style';

const ServerError = () => (
  <Container>
    <Helmet>
      <title>500 | Devfolio</title>
    </Helmet>
    <Logo style={{ marginBottom: 16 }} />
    <Media
      query="(max-width: 767px)"
      render={() => (
        <React.Fragment>
          <img src={FiveHundredMobile} alt="Internal Server Error" style={{ width: '96vw', padding: '36px 0' }} />
          <Text>
            Looks like our servers need some cooling.
            <br />
            Please try again in a while.
          </Text>
        </React.Fragment>
      )}
    />
    <Media
      query="(min-width: 768px)"
      render={() => (
        <React.Fragment>
          <div style={{ maxWidth: 960, padding: 48 }}>
            <img src={FiveHundred} alt="Internal Server Error" style={{ width: '100%', height: 'auto' }} />
          </div>
          <Text>Looks like our servers need some cooling. Please try again in a while.</Text>
        </React.Fragment>
      )}
    />
  </Container>
);

export default ServerError;

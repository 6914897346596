const logger = console;

/**
 * Clears the local storage
 */
export const clearStorage = (): void => {
  if (typeof localStorage !== 'undefined' && localStorage) {
    localStorage.clear();
  }
};

/**
 * Gets value from localStorage corresponding to the key provided, if it doesn't exist returns null
 *
 * @param key key of the item that needs to be fetched from localStorage
 */
export const getItemFromStorage = (key: string): { [key: string]: unknown } | null => {
  if (typeof localStorage !== 'undefined' && localStorage) {
    try {
      const item: string | null = localStorage.getItem(key);
      if (item !== null) {
        return JSON.parse(item);
      }
    } catch (err) {
      logger.error(`Error getting item ${key} from localStorage`, err);
    }
  }
  return null;
};

/**
 * Stores an item to localStorage
 *
 * @param key key corresponding to which item needs to be stored in localStorage
 * @param item Item that will be stored
 */
export const storeItem = (key: string, item: unknown): void => {
  if (typeof localStorage !== 'undefined' && localStorage) {
    try {
      localStorage.setItem(key, JSON.stringify(item));
    } catch (err) {
      logger.error(`Error storing item ${key} to localStorage`, err);
    }
  }
};

/**
 * Remove an item from localStorage
 *
 * @param key key of an item that needs to removed from localStorage
 */
export const removeItemFromStorage = (key: string): void => {
  if (typeof localStorage !== 'undefined' && localStorage) {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      logger.error(`Error removing item ${key} from localStorage`, err);
    }
  }
};

import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Analytics, getDevfolioUserCookie } from '@devfolioco/helpers';
import { NODE_ENV } from '../client/constants/environment';
import { ACTION_STATUS, ANALYTICS_TRACKING_EVENTS, FILE } from '../client/constants';

import * as types from '../client/constants/actions';
import authentication, { initialState as initialAuth } from '../client/reducers/authentication';
import email from '../client/reducers/email';
import form from '../client/reducers/form';
import hackathon from '../client/reducers/hackathon';
import profile from '../client/reducers/profile';
import project from '../client/reducers/project';
import quiz from '../client/reducers/quiz';
import registration from '../client/reducers/registration';
import ticket from '../client/reducers/ticket';
import user from '../client/reducers/user';
import myProjects from '../client/reducers/myProjects';
import idea from '../client/reducers/idea';

/**
 * A custom middle ware to fire analytics events
 * for some redux actions
 */
const analyticsMiddleware = store => next => action => {
  const hackathonSlug = store.getState().hackathon?.currentHackathon?.slug;

  switch (action?.type) {
    case types.JOIN_TEAM_WITH_CODE_SUCCESS:
      Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.JOIN_TEAM, {
        hackathonSlug,
      });
      break;

    case types.CREATE_TEAM_SUCCESS:
      Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.CREATE_TEAM, {
        hackathonSlug,
      });
      break;

    case types.LEAVE_TEAM_SUCCESS:
      Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.LEAVE_TEAM, {
        hackathonSlug,
      });
      break;

    case types.DELETE_TEAM_SUCCESS:
      Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.DELETE_TEAM, {
        hackathonSlug,
      });
      break;

    case types.REVERT_INDIVIDUAL_APPLICATION_SUCCESS:
      Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.CONFIRM_WITHDRAW_INDIVIDUAL_APPLICATION, {
        hackathonSlug,
      });
      break;

    case types.DELETE_USER:
      if (action.payload.status === ACTION_STATUS.SUCCESS) {
        Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.CONFIRMED_ACCOUNT_DELETION);
      }
      break;

    case types.SORTED_SKILLS:
      Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.SORTED_SKILLS);
      break;

    case types.UPLOAD_RESUME_SUCCESS:
      Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.UPLOADED_RESUME);
      break;

    case types.UPLOAD_AVATAR:
      if (action.payload.status === FILE.UPLOADED) {
        Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.ADDED_NEW_AVATAR);
      }
      break;

    default:
      break;
  }

  next(action);
};

// eslint-disable-next-line import/no-mutable-exports
let initialState = {};

if (typeof window === 'object') {
  const devfolioAuth = getDevfolioUserCookie();

  if (devfolioAuth !== null) {
    initialState = { authentication: { ...initialAuth, ...devfolioAuth, isAuthenticated: true } };
  }

  if (typeof window === 'object' && window.__PRELOADED_STATE__) {
    // Grab the state from a global variable injected into the server-generated HTML
    const preloadedState = window.__PRELOADED_STATE__;

    // Allow the passed state to be garbage-collected
    delete window.__PRELOADED_STATE__;

    if (preloadedState) {
      initialState = { ...initialState, ...preloadedState };
    }
  }
}

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const appReducer = combineReducers({
  authentication,
  email,
  form,
  hackathon,
  profile,
  project,
  quiz,
  registration,
  ticket,
  user,
  myProjects,
  idea,
});

const rootReducer = (state, action) => {
  if (action.type === types.LOGOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, analyticsMiddleware)));

export { rootReducer, initialState, createStore, composeEnhancers, applyMiddleware, thunk };

export default store;

/*
  @deprecated This page has been migrated to v2
 */
/* eslint-disable max-len */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Heading, Paragraph as P, Card, Icon } from '@devfolioco/genesis';
import CopyMailTo from 'react-copy-mailto';

import { ArticlePage } from 'components/Globals';
import Logo from 'components/Logo';

import { Header, Section, SectionHeading, ContactRow } from './style';

const PrivacyPolicy = () => (
  <React.Fragment>
    <Helmet>
      <title>Privacy Policy | Devfolio</title>
    </Helmet>
    <ArticlePage>
      <Header>
        <Logo href="/" />
        <Heading appearance="H1" color="blue-8" style={{ marginBottom: 0 }}>
          Privacy Policy
        </Heading>
        <span>Effective Date: 29th December, 2020</span>
      </Header>

      <P style={{ fontWeight: 'bold', marginTop: 48 }}>
        We take your privacy seriously. Here we&#39;ve put forth some basic information about us, listed out all the
        types of data we collect, what we use it for, how we store it, whom we share it with, and how you can get it
        deleted.
      </P>

      <Section>
        <SectionHeading appearance="H2" color="blue-8">
          Applicability
        </SectionHeading>
        <P>
          This Policy applies to all products and services offered by NSB Classic PTE LTD, through{' '}
          <a href="https://www.devfolio.co">www.devfolio.co </a>
          (also referred to as &#34;us&#34;, &#34;the company&#34;, Devfolio&#34;, &#34;we&#34; or &#34;the
          website&#34;) or any of its sub-domains, with regard to the data of all natural and legal individuals (also
          referred to as &#34;you&#34;, &#34;your&#34;, &#34;users&#34;, or &#34;applicants&#34;). By using the website,
          with or without availing its services, you agree to the terms laid down herein.
        </P>
      </Section>
      <Section>
        <SectionHeading appearance="H2" color="blue-8">
          Information We Collect, and Why
        </SectionHeading>
        <P>
          The application form and profile builder on our website requires users to divulge certain personal
          information, namely, contact details, skills, education details, emergency contact, etc. Using cookies,
          certain data is being collected in the background as well. A brief description of the kinds of data we
          collect, and the specific purpose for which we collect the same, is as follows.
        </P>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            User Queries (keywords searched, source URLs)
          </Heading>
          <P>To know how you got to our website, and how we can get more visitors.</P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Contact Details (name, phone, email address)
          </Heading>
          <P>To contact you in the future, and keep you posted about new updates and opportunities at Devfolio.</P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Demographic Data (gender, skills, education, experience)
          </Heading>
          <P>To help us perform better analytics and to better target our services.</P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Geographical Data (IP address, address, city, region, country, timezone)
          </Heading>
          <P>
            To know what part of the world are users are based in, home-deliver marketing merchandise, and serve you
            more accurate and relevant content. As of May 2019, we have stopped asking for mailing addresses of our
            users.
          </P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Device Details (device type, OS, browser version)
          </Heading>
          <P>To help us ensure that all our services are compatible and usable on most kinds of devices.</P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            User Behaviour (pages seen, time spent, website search history)
          </Heading>
          <P>To better understand how you use our website and to improve user experience accordingly.</P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Payment ID
          </Heading>
          <P>
            All payments on our website are made through the <a href="https://razorpay.com/privacy/">Razorpay</a>{' '}
            payment gateway. Once a transaction is complete, we only save the payment ID for record-keeping purposes.
          </P>
        </div>
      </Section>
      <Section>
        <SectionHeading appearance="H2" color="blue-8">
          Sharing of Information
        </SectionHeading>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Hackathon Organizers
          </Heading>
          <P>
            If you use Devfolio to apply to a hackathon, we will share all details from your profile that the hackathon
            requires (including contact details, demographics, skills, etc.,). Any other information you volunteer on
            the specific hackathon application form will be shared with that hackathon only. Hackathons will also have
            the ability to retain the details of all its confirmed participants.
          </P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Hiring Companies{' '}
          </Heading>
          <P>
            If you take any of the quizzes on the website, opt-in to receive information about internship or job
            opportunities, and give us your prior and explicit consent regarding the same, we will share any and all
            details from your profile which a company interested in hiring you requires.
          </P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Legal Obligations
          </Heading>
          <P>
            We will also share any information with law enforcement and governmental bodies that we may be obligated to
            share under law.
          </P>
        </div>
      </Section>
      <Section>
        <SectionHeading appearance="H2" color="blue-8">
          Storage and Security
        </SectionHeading>
        <P>
          Our data is securely stored as per industry standards. All our data is stored on servers hosted by Amazon Web
          Services [<a href="https://aws.amazon.com/privacy/">legal</a>] in India. We will retain the information only
          till it is necessary for its stated purpose.
        </P>
      </Section>
      <Section>
        <SectionHeading appearance="H2" color="blue-8">
          Third Party Services
        </SectionHeading>
        <P>
          To manage various aspects of our services better, we have integrated many third party apps on our website.
          They may have access to certain parts of your personal information stored on our servers. These include,
        </P>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Google Analytics
          </Heading>
          <P>
            Used to track pages visited, and other similar user behaviour [
            <a href="https://policies.google.com/technologies/partner-sites">Privacy Policy</a>].
          </P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Amplitude
          </Heading>
          <P>
            Used to analyze user behaviour on our website [<a href="https://www.amplitude.com">www.amplitude.com</a>].
          </P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Razorpay
          </Heading>
          <P>
            Used to facilitate online payments made over the website [
            <a href="https://www.razorpay.com">www.razorpay.com</a>].
          </P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            RudderStack
          </Heading>
          <P>
            Used to collect, clean and analyze user data [<a href="https://www.rudderstack.com/">www.rudderstack.com</a>
            ].
          </P>
        </div>
        <div style={{ margin: '24px 0' }}>
          <Heading appearance="H3" color="blue-8">
            Sentry
          </Heading>
          <P>
            Used to track errors generated by our users on our system [
            <a href="https://www.sentry.io/privacy">www.sentry.io/privacy</a>].
          </P>
        </div>
      </Section>
      <Section>
        <SectionHeading appearance="H2" color="blue-8">
          Changes to the Policy
        </SectionHeading>
        <P>
          We reserve the right to update this policy from time to time. With each change, we shall notify all users by
          way of a banner on our website, or by an email, based on the significance of the changes. Continuing usage of
          the website will then imply acceptance of the new terms in the privacy policy.
        </P>
      </Section>
      <Section>
        <SectionHeading appearance="H2" color="blue-8">
          Contact Us
        </SectionHeading>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: 40,
          }}
        >
          <Card background="yellow-4" noPadding>
            <P style={{ margin: 24 }} color="teal-8">
              If you want to withdraw any data-related consent, or access, review, correct, modify, or delete your
              personal information, or exercise any of your data rights under the EU GDPR, or any other law on privacy,
              or, have any other related requests or queries, feel free to contact our Data Protection Officer, Mr.
              Denver Dsouza.
            </P>
          </Card>
          <Card noPadding>
            <div style={{ padding: 24 }}>
              <Heading appearance="H2" color="blue-8">
                Denver Dsouza
              </Heading>
              <div>
                <ContactRow>
                  <Icon name="mail" size="small" />
                  <CopyMailTo email="denver@devfolio.co" />
                </ContactRow>
                <ContactRow>
                  <Icon name="home" size="small" />
                  <span>No. 97, 4th main, 1st cross, New Thippasandra, Bengaluru - 560075</span>
                </ContactRow>
              </div>
            </div>
          </Card>
        </div>
      </Section>
    </ArticlePage>
  </React.Fragment>
);

export default PrivacyPolicy;

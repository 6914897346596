import * as qs from 'query-string';
import { getDevfolioUserCookie, Analytics } from '@devfolioco/helpers';
import { NODE_ENV, APP_ENV } from '@constants/environment';
import * as types from '../constants/actions';
import { ERRORS, SERVER_ERRORS } from '../constants/errors';
import { getError, isAuthenticated } from '../helpers';
import { API } from '../api';
import { history } from '../helpers/history';
import { removeItemFromStorage } from '../helpers/localStorage';
import { hydrateRegistrationState } from './registration';
import {
  ACTION_STATUS as STATUS,
  SIGN_IN_TOAST_STATES,
  ANALYTICS_TRACKING_EVENTS,
  NOTIFICATION_KEY,
} from '../constants';
import { removeDevfolioCookie } from '../helpers/cookie';
import { logActionError } from '../helpers/logger';
import { fetchUserBasicInfo } from './user';

// MISC.
export const clearAuthenticationErrors = () => ({
  type: types.CLEAR_AUTHENTICATION_ERRORS,
});

export const setAuthenticationState = (param, value) => ({
  type: types.SET_AUTHENTICATION_STATE,
  payload: { param, value },
});

// LOGOUT
export const logOutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});

/**
 * LogOut User Thunk:
 * Logs the user out, by clearing the devfolio auth local storage
 * object and removing the user session
 * @param {boolean} doesClearSession - we'll send a clear session
 * request to the server incase the user is logging out via the nav.
 * In other cases, such as when the access token is expired, or a
 * 401 is encountered, doesClearSession remains false so as to prevent
 * recursive logOut requests.
 */
export const logOut =
  (doesClearSession = false, navigateTo = null) =>
  async dispatch => {
    try {
      if (doesClearSession) {
        await API.authentication.logOut();
      }
      clearDevfolioBrowserData();
      Analytics.resetAnalytics();

      /**
       * When the navigateTo param is available then directly change the
       * window location to it instead of clearing the store first, to
       * avoid the UI from breaking due to the store being cleared when
       * redirecting. Also the store would be resetted anyways, because
       * of the page being reloaded.
       */
      if (typeof window !== 'undefined' && typeof navigateTo === 'string') {
        window.location.href = navigateTo;
        return;
      }

      dispatch(logOutSuccess());
    } catch (error) {
      logActionError('logOut', error);
    }
  };

// SIGNIN
export const signInFailure = error => ({
  type: types.SIGNIN_FAILURE,
  payload: error,
});

export const signInRequest = () => ({
  type: types.SIGNIN_REQUEST,
});

export const signInSuccess = data => ({
  type: types.SIGNIN_SUCCESS,
  payload: data,
});

/**
 * SignIn User Thunk:
 * @param {string} id - could either be a username or an email and can be
 * distinguished by the presence of an @ symbol
 * @param {string} password - the password associated with the id
 */
export const signIn =
  (id, password, captcha, captchaRef, followSignInRoute = true) =>
  async dispatch => {
    dispatch(signInRequest());
    try {
      await API.authentication.signIn(id, password, captcha);

      await createDevfolioAuth(dispatch);

      if (followSignInRoute) {
        routeOnSignIn();
      }
    } catch (error) {
      const err = getError(error);
      if (err?.hasOwnProperty('message')) {
        switch (err.message) {
          case SERVER_ERRORS.passwordInvalid:
            dispatch(signInFailure(ERRORS.passwordIncorrect));
            break;
          case SERVER_ERRORS.usernameInvalid:
            id.includes('@')
              ? dispatch(signInFailure(ERRORS.emailNotFound))
              : dispatch(signInFailure(ERRORS.usernameNotFound));
            break;
          case SERVER_ERRORS.passwordRateLimitExceeded:
            dispatch(signInFailure(ERRORS.passwordRateLimitExceeded));
            break;
          default:
            dispatch(signInFailure(ERRORS.loginFailed));
            break;
        }
      }

      logActionError(types.SIGNIN_FAILURE, error, { id, captcha, followSignInRoute });
    } finally {
      if (captchaRef && captchaRef.current) {
        captchaRef.current.reset();
      }
    }
  };

// EMAIL VERIFICATION
export const emailVerificationFailed = () => ({
  type: types.SIGNIN_FAILURE,
  payload: ERRORS.emailVerificationFailed,
});

export const emailVerificationSuccess = () => ({
  type: types.SIGNIN_SUCCESS,
});

export const verifyEmail = token => async dispatch => {
  /**
   * The user email verfication thunk. Once we are on the /signin
   * page with 'token' as a param, we try to verify it. The token
   * could have either expired or is invalid
   * @param {string} token - the verification token received via
   * the registered email address
   */
  try {
    await API.authentication.verifyEmail(token);
    dispatch(emailVerificationSuccess());
  } catch (error) {
    const err = getError(error);

    if (err?.hasOwnProperty('message')) {
      switch (err.message) {
        case SERVER_ERRORS.tokenExpired:
        case SERVER_ERRORS.tokenInvalid:
          dispatch(emailVerificationFailed());
          break;
        default:
          break;
      }
    }

    logActionError('verifyEmail', error);
  }
};

// GOOGLE ACCOUNT INTEGRATION
export const googleAuthenticationError = () => ({
  type: types.GOOGLE_AUTHENTICATION_FAILURE,
  payload: ERRORS.googleAuthenticationFailure,
});

/**
 * Handles the 'Sign In with Google' flow.
 * There exists a user to which the googleId belongs, hence we
 * signin with the related email/id and create an auth object
 * @param {object} userObject - the user object
 */
export const googleSignIn =
  ({ authCode, isFromRegistration, referralCode }) =>
  async dispatch => {
    let userObject = {
      isFromRegistration: isFromRegistration ?? false,
      referralCode: referralCode ?? undefined,
    };
    try {
      // Get the necessary data from the auth code
      const { data } = await API.authentication.getGoogleIdToken(authCode);

      userObject = {
        ...userObject,
        googleObject: {
          IDToken: data.id_token,
          imageUrl: data.profile.picture,
        },
        email: data.profile.email,
        firstName: data.profile.given_name,
        lastName: data.profile.family_name,
      };

      // Only allow devfolio emails in preview mode
      if (APP_ENV === 'preview' && !data.profile.email.endsWith('@devfolio.co')) {
        // eslint-disable-next-line no-alert
        alert('Only Devfolio emails are allowed, Did you mean to go to https://devfolio.co');
        return;
      }

      await API.authentication.googleSignIn(data.id_token);
      await createDevfolioAuth(dispatch);

      routeOnSignIn();
    } catch (error) {
      const err = getError(error);

      if (err?.hasOwnProperty('message')) {
        const errorMessage = err.message;

        if (errorMessage === SERVER_ERRORS.IDTokenInvalid) {
          dispatch(googleAuthenticationError());
        } else if (errorMessage === SERVER_ERRORS.userNotFound) {
          if (userObject.isFromRegistration) {
            dispatch(hydrateRegistrationState(userObject));
            Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.TRIVIAL_SIGNUP_WITH_GOOGLE);
            // We know that user is here via Sign up page, hence move to next
            history.push({
              pathname: '/signup/account',
              state: { referralCode },
            });
          } else {
            // Move to 'Sign Up With Google' intermediate page
            history.push({
              pathname: '/signin/google',
              state: userObject,
            });
          }
        }
      }

      logActionError('googleSignIn', error);
    }
  };

// FORGOT PASSWORD
const forgotPasswordRequest = () => ({
  type: types.FORGOT_PASSWORD_REQUEST,
});

const forgotPasswordFailure = error => ({
  type: types.FORGOT_PASSWORD_FAILURE,
  payload: error,
});

const forgotPasswordSuccess = () => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
});

export const forgotPassword = id => async dispatch => {
  /**
   * The async action that sends an reset password email to the associated user
   * @param {string} id - email or username
   */
  dispatch(forgotPasswordRequest());

  try {
    await API.authentication.forgotPassword(id);
    dispatch(forgotPasswordSuccess());

    history.push({
      pathname: '/reset-sent',
      state: {
        fromAction: true,
      },
    });
  } catch (error) {
    const err = getError(error);

    if (err?.hasOwnProperty('message')) {
      switch (err.message) {
        case SERVER_ERRORS.accountNotFound:
          id.includes('@')
            ? dispatch(forgotPasswordFailure(ERRORS.emailNotFound))
            : dispatch(forgotPasswordFailure(ERRORS.usernameNotFound));
          break;
        default:
          break;
      }
    }

    logActionError(types.FORGOT_PASSWORD_FAILURE, error, { id });
  }
};

// RESET PASSWORD
const resetPasswordRequest = () => ({
  type: types.RESET_PASSWORD_REQUEST,
});

const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS,
});

const resetPasswordFailure = () => ({
  type: types.RESET_PASSWORD_FAILURE,
});

export const resetPassword = (password, token) => async dispatch => {
  /**
   * The async action which resets the password
   * @param {string} password - the new password
   * @param {string} token - the token received in reset password
   */
  dispatch(resetPasswordRequest());

  try {
    if (isAuthenticated()) {
      dispatch(logOut());
    }
    await API.authentication.resetPassword(password, token);
    dispatch(resetPasswordSuccess());
    history.push({ pathname: '/signin', state: { showToast: SIGN_IN_TOAST_STATES.RESET_PASSWORD_SUCCESS } });
  } catch (error) {
    dispatch(resetPasswordFailure());
    const err = getError(error);

    if (err?.hasOwnProperty('message')) {
      switch (err.message) {
        case SERVER_ERRORS.tokenExpired:
          history.push({
            pathname: '/signin',
            state: { showToast: SIGN_IN_TOAST_STATES.RESET_PASSWORD_TOKEN_EXPIRED },
          });
          break;
        case SERVER_ERRORS.tokenInvalid:
        default:
          history.push({ pathname: '/signin', state: { showToast: SIGN_IN_TOAST_STATES.RESET_PASSWORD_FAILURE } });
      }
    } else {
      history.push({ pathname: '/signin', state: { showToast: SIGN_IN_TOAST_STATES.RESET_PASSWORD_FAILURE } });
    }

    logActionError(types.RESET_PASSWORD_FAILURE, error);
  }
};

const updateUserAuthentication = payload => ({
  type: types.UPDATE_USER_AUTHENTICATION,
  payload,
});

/**
 * Updates the redux store with the uuid stored in the
 * the devfolio user cookie created by the server, and
 * dispatches the action to fetch the basic info of the
 * user if required
 * @param {function} dispatch The redux action dispatcher
 * @param {boolean} shouldFetchBasicInfo Should the basic info action be dispatched
 */
export const createDevfolioAuth = async (dispatch, shouldFetchBasicInfo = true) => {
  const devfolioAuth = getDevfolioUserCookie();
  // Update the store with info in the devfolio auth cookie
  dispatch(updateUserAuthentication(devfolioAuth));

  if (shouldFetchBasicInfo) {
    // If the basic info needs to be fetched then dispatch the action
    // without updating the status because we wouldn't want the Routes
    // component to re-render unnecessarily and break the navigation flow
    await dispatch(fetchUserBasicInfo(false));
  }
  Analytics.identifyUser();
  // Dispatch the sign in success action once we have all the
  // data available in the authentication store
  dispatch(signInSuccess(devfolioAuth));
};

export const clearDevfolioBrowserData = () => {
  // Remove the quiz flow localstorage item.
  removeItemFromStorage('devfolio-quiz');

  // Remove the sample quiz localstorage item.
  removeItemFromStorage('devfolio-sample-quiz');

  // Remove the project localstorage item.
  removeItemFromStorage('devfolio-project');

  // Remove the online hackathon Intro flow local storage item
  removeItemFromStorage('devfolio-online-hackathon-intro');

  // Remove the offline hackathon required fields info local storage item
  removeItemFromStorage('devfolio-offline-hackathon-info');

  // Remove notification item from local storage
  removeItemFromStorage(NOTIFICATION_KEY);

  // Remove any old devfolio auth data. This was being used
  // by the previous authentication mechanism, removing it if
  // it was still somehow left in the user's browser
  removeItemFromStorage('devfolio-auth');

  // Delete the devfolio, ethicBoundsCookie and devfolio_user cookie
  removeDevfolioCookie(true);
};

const updateUsernameAction = payload => ({
  type: types.UPDATE_USERNAME,
  payload,
});

/**
 * Updates the username of a user.
 *
 * @param {string} username the old username
 * @param {string} newUsername the new username
 */
export const updateUsername = (username, newUsername) => async dispatch => {
  try {
    dispatch(updateUsernameAction({ status: STATUS.REQUEST }));

    await API.user.setUserData(username, 'username', newUsername);
    dispatch(logOut());
    history.push({ pathname: '/signin', search: '?username_reset=true' });

    dispatch(updateUsernameAction({ status: STATUS.SUCCESS, username: newUsername }));
  } catch (error) {
    const err = getError(error);

    if (err?.hasOwnProperty('message')) {
      if (err.message === SERVER_ERRORS.unprocessableRequest) {
        if ('username' in err.source) {
          switch (err.source.username.msg) {
            case SERVER_ERRORS.usernameUnavailable:
              dispatch(updateUsernameAction({ status: STATUS.FAILURE, error: SERVER_ERRORS.usernameUnavailable }));
              break;
            case SERVER_ERRORS.usernameInvalid:
              dispatch(updateUsernameAction({ status: STATUS.FAILURE, error: SERVER_ERRORS.usernameInvalid }));
              break;
            default:
              break;
          }
        }
      }
    }

    logActionError(types.UPDATE_USERNAME, error);
  }
};

const changePasswordAction = payload => ({
  type: types.CHANGE_PASSWORD,
  payload,
});

/**
 * This action updates the user's password
 *
 * @param {string} username
 * @param {string} oldPassword
 * @param {string} newPassword
 */
export const changePassword = (username, oldPassword, newPassword) => async dispatch => {
  try {
    dispatch(changePasswordAction({ status: STATUS.REQUEST }));

    await API.authentication.changePassword(username, oldPassword, newPassword);
    dispatch(logOut());
    history.push({ pathname: '/signin', search: '?password_reset=true' });

    dispatch(changePasswordAction({ status: STATUS.SUCCESS }));
  } catch (error) {
    const err = getError(error);

    if (err?.status === 400) {
      dispatch(changePasswordAction({ status: STATUS.FAILURE, error: SERVER_ERRORS.unprocessableRequest }));
      return;
    }

    if (err?.hasOwnProperty('message')) {
      if (err.message === SERVER_ERRORS.unprocessableRequest) {
        if ('new_password' in err.source) {
          dispatch(changePasswordAction({ status: STATUS.FAILURE, error: SERVER_ERRORS.passwordShort }));
        }
      }
    }

    logActionError(types.CHANGE_PASSWORD, error);
  }
};

/**
 * This is used to ensure that we only redirect to
 * devfolio URLs when the user tries to sign in
 * @param {string} url
 * @returns Whether the url is a valid devfolio url
 */
const isValidDevfolioURL = url => {
  try {
    const parsedUrl = new URL(url);
    return typeof parsedUrl.hostname === 'string' && parsedUrl.hostname.endsWith('devfolio.co');
  } catch (error) {
    return false;
  }
};

/**
 * Routes the user to the appropriate URL from where he came from
 * depending on the history state or query param
 */
export const routeOnSignIn = () => {
  // First check if the URL has a from query param which is used in case the user is
  // redirected from another application
  const searchQuery = qs.parse(history.location?.search);
  // Check if the URL in the from query param is valid, although allow the from query param
  // without a check in development environment because validator doesn't allow localhost domains
  const hasValidFromURL = searchQuery?.from
    ? isValidDevfolioURL(searchQuery.from) || NODE_ENV === 'development'
    : false;
  // Redirect the user to the application he came from and return
  if (hasValidFromURL && typeof document !== 'undefined') {
    document.location.href = searchQuery.from;
    return;
  }

  // Also check for inter application re-routing which is done by navigation state
  // If the state has pathname then redirect the user to the required URL else just
  // redirect him to /hackathons/applied
  const pathname = history.location.state?.from?.pathname;
  if (typeof pathname === 'string' && pathname !== '/') {
    history.push(pathname);
  } else {
    history.push('/hackathons/applied');
  }
};

export const updateApp = value => ({
  type: types.APP_UPDATED,
  payload: value,
});

const updateServerTimeOffsetStatus = payload => ({
  type: types.UPDATE_SERVER_TIME_OFFSET,
  payload,
});

export const updateServerTimeOffset = () => async dispatch => {
  try {
    dispatch(updateServerTimeOffsetStatus({ status: STATUS.REQUEST }));

    const { data } = await API.miscellaneous.getCurrentTime();

    // Calculate the difference between server time and client time
    // which will be used as an offset for calculating accurate time
    // on the client's machine
    const serverTimeOffset = data.epoch - Date.now();

    // Ignore if the offset is less than one second
    const approximatedOffset = serverTimeOffset > 1000 || serverTimeOffset < -1000 ? serverTimeOffset : 0;

    dispatch(updateServerTimeOffsetStatus({ status: STATUS.SUCCESS, serverTimeOffset: approximatedOffset }));
  } catch (error) {
    logActionError(types.UPDATE_SERVER_TIME_OFFSET, error);
    dispatch(updateServerTimeOffsetStatus({ status: STATUS.FAILURE }));
  }
};

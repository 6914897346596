import React from 'react';
import StyledButton from './style';

const ButtonLink = ({ appearance, onClick, children, ...props }) => (
  <StyledButton onClick={onClick} appearance={appearance} type="button" {...props}>
    {children}
  </StyledButton>
);

export default ButtonLink;

import debounce from 'lodash/debounce';
import imageCompression from 'browser-image-compression';
import moment from '@helpers/moment';
import { history } from 'helpers/history';
import {
  ACTION_STATUS as STATUS,
  CLIENT_PARAM,
  EMAIL_OTP,
  FILE,
  INTEGRATION,
  OTP,
  PROFILES,
  RESUME,
  SAVE,
  SERVER_PARAM,
  STATE,
  WAIT_TIME,
  EMPTY_EXPERIENCE,
  BETA_FEATURE_FLAG_UUID,
  OAUTH_PROVIDERS,
} from '@constants';
import { ERRORS, SERVER_ERRORS } from '@constants/errors';
import { isValidURL, isValidEmail, isValidUsername } from '@devfolioco/helpers';
import { gql } from 'graphql-request';

import { storeItem } from 'helpers/localStorage';
import * as types from '@constants/actions';
import {
  mapToClient,
  getState,
  getHostName,
  getError,
  getDetailedProfile,
  getFileType,
  getUploadFileConfig,
} from '@helpers';
import { uniq } from 'lodash';
import { graphqlClient, fetchOAuthProviders } from '../api/graphql';
import { logActionError } from '../helpers/logger';
import { API, EXTERNAL_API } from '../api';
import { logOut } from './authentication';

let prevAction = { name: '', value: '', hasError: false };

/**
 * Get the company names and logos via Clearbit
 * @param {*} profiles
 * @returns
 */

// Action Creators
// FETCH USER DATA
export const fetchUserData = username => async dispatch => {
  /**
   * This is fired on component mount or wherever we need to
   * fill the user data
   * @param {string} username - username of the user
   */
  dispatch(fetchUserDataRequest());
  try {
    const response = await API.user.fetchUserData(username);

    if (response.data && Array.isArray(response.data.profiles) && response.data.profiles.length > 0) {
      const { profiles } = response.data;
      const detailedProfiles = await Promise.all(profiles.map(profile => getDetailedProfile(profile)));
      for (const [index, profile] of profiles.entries()) {
        profile.logo = detailedProfiles[index].logo;
        profile.name = detailedProfiles[index].name;
      }
      profiles.sort((profileA, profileB) => profileA.name > profileB.name);
    }

    if (response.data.education.length > 0) {
      const education = response.data.education[0];

      // Flatten
      response.data.current_college = education.current_college;
      response.data.degree_type = education.degree_type;
      response.data.education_field = education.education_field;
      response.data.education_id = education.uuid;
      response.data.college = education.college;

      // We receive graduation_year in the YYYY-MM-DD format
      response.data.graduation_month = parseInt(education.graduation_year.substr(5, 7), 10);
      response.data.graduation_year = parseInt(education.graduation_year.substr(0, 4), 10);
    }

    if (Array.isArray(response.data.skills) && response.data.skills.length > 0) {
      response.data.user_skill = response.data.skills.filter(skill => skill.priority).slice(0, 5);
    }

    if (response.data.extras !== null) {
      response.data.is_education = response.data.extras.is_education;
    }

    const responseDOB = response.data.dob;
    if (typeof responseDOB === 'string') {
      response.data.dob = moment(responseDOB).format('DD/MM/YYYY');
    }

    if (
      response.data.extras &&
      !response.data.extras.no_work_exp &&
      Array.isArray(response.data.experience) &&
      !response.data.experience.length
    ) {
      response.data.experience = [EMPTY_EXPERIENCE];
    }

    response.data.user_id = response.data.uuid;

    dispatch(fetchUserDataSuccess(response));
  } catch (error) {
    dispatch(fetchUserDataFailure());
    logActionError(types.FETCH_USER_DATA_FAILURE, error);
  }
};

const fetchUserDataRequest = () => ({
  type: types.FETCH_USER_DATA_REQUEST,
});

const fetchUserDataSuccess = ({ data: user }) => ({
  type: types.FETCH_USER_DATA_SUCCESS,
  payload: mapToClient({
    ...user,
    ...user.contact,
    ...user.extras,
  }),
});

const fetchUserDataFailure = () => ({
  type: types.FETCH_USER_DATA_FAILURE,
});

export const deleteUser = userUUID => async dispatch => {
  try {
    dispatch(deleteUserStatus({ status: STATUS.REQUEST }));
    const {
      data: { delete_token: deleteToken },
    } = await API.user.deleteUser(userUUID);

    dispatch(deleteUserStatus({ status: STATUS.SUCCESS }));
    dispatch(logOut());

    history.push({ pathname: '/feedback', state: { deleteToken } });
  } catch (error) {
    dispatch(deleteUserStatus({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_USER, error);
  }
};

const deleteUserStatus = payload => ({
  type: types.DELETE_USER,
  payload,
});

export const postDeleteUserFeedback = (deleteToken, reason) => async dispatch => {
  try {
    dispatch(postDeleteUserFeedbackStatus({ status: STATUS.REQUEST }));
    await API.user.postDeleteUserFeedbackForm(deleteToken, reason);
    dispatch(postDeleteUserFeedbackStatus({ status: STATUS.SUCCESS }));
  } catch (error) {
    dispatch(postDeleteUserFeedbackStatus({ status: STATUS.FAILURE }));
  }
};

const postDeleteUserFeedbackStatus = payload => ({
  type: types.POST_DELETE_USER_FEEDBACK,
  payload,
});

export const userError = error => ({
  type: types.USER_ERROR,
  payload: error,
});

export const clearUserError = error => ({
  type: types.CLEAR_USER_ERROR,
  payload: error,
});

// SAVE USER DATA
/**
 * Sets the user state and data (API call).
 * @param {string} name - user property to be set (one of CLIENT_PARAM)
 * @param {string} value - value of the property to be updated
 */
export const saveUserData = (name, value) => dispatch => {
  dispatch(setUserState(name, value));
  dispatch(setUserData(name, value));
};

const catchSaveUserDataError = () => async dispatch => {
  dispatch(updateSaveStatus(SAVE.FAILURE));
};

// 2. EDUCATION SECTION

// 2.1. Formal Education Checkbox
// See the saveUserData thunk

// 2.2. Create a new Education
export const addEducation = educationObject => async dispatch => {
  /**
   * Creates a new education entity of the user
   * @param {Object} educationObject - contains all the required fields to add
   * education viz degreeType, educationInstitution, fieldOfStudy, isStudent, monthOfGraduation,
   * and yearOfGraduation
   */
  const { username } = getState(STATE.AUTHENTICATION);
  dispatch(updateSaveStatus(SAVE.PROGRESS));
  try {
    const response = await API.user.addEducation(username, educationObject);

    dispatch(setUserState('educationID', response.data.uuid));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(catchSaveUserDataError(error));
    logActionError('addEducation', error);
  }
};

// 2.3. Update the user education
export const updateEducation = () => async dispatch => {
  /**
   * Fires when an education ID is present to update the education data.
   */
  try {
    dispatch(updateSaveStatus(SAVE.PROGRESS));

    const { username } = getState(STATE.AUTHENTICATION);
    const {
      degreeType,
      educationID,
      educationInstitution,
      fieldOfStudy,
      isStudent,
      monthOfGraduation,
      yearOfGraduation,
    } = getState(STATE.USER);

    await API.user.updateEducation(
      degreeType,
      educationID,
      educationInstitution,
      fieldOfStudy,
      isStudent,
      monthOfGraduation,
      username,
      yearOfGraduation
    );

    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(catchSaveUserDataError(error));
    logActionError('updateEducation', error);
  }
};

// 2.4. Delete the user education entity
export const deleteEducation = () => async dispatch => {
  /**
   * Fired when all the filled fields are set to their defautlt value.
   * Education entity is deleted and the fields are cleared.
   */
  const { username } = getState(STATE.AUTHENTICATION);
  const { educationID } = getState(STATE.USER);

  if (educationID !== undefined) {
    dispatch(updateSaveStatus(SAVE.PROGRESS));

    try {
      await API.user.deleteEducation(username, educationID);

      dispatch(clearEducationState());
      dispatch(updateSaveStatus(SAVE.SUCCESS));
    } catch (error) {
      dispatch(catchSaveUserDataError(error));
      logActionError('deleteEducation', error);
    }
  }
};

const clearEducationState = () => ({
  type: types.CLEAR_EDUCATION_STATE,
});

// 2.5. Add a new college
export const addCollege = educationInstitution => async dispatch => {
  /**
   * Adds a user generated college and updates the educationInsitution state
   * @param {Object} educationInstitution - contains uuid, name, url
   */
  try {
    dispatch(updateSaveStatus(SAVE.PROGRESS));
    const response = await API.user.addCollege(educationInstitution);
    educationInstitution.uuid = response.data.uuid;

    const { username, educationID, fieldOfStudy, degreeType, isStudent, monthOfGraduation, yearOfGraduation } =
      getState(STATE.USER);

    if (
      degreeType !== '' &&
      educationID !== '' &&
      educationID !== undefined &&
      fieldOfStudy !== undefined &&
      monthOfGraduation !== '' &&
      yearOfGraduation !== ''
    ) {
      await API.user.updateEducation(
        degreeType,
        educationID,
        educationInstitution,
        fieldOfStudy,
        isStudent,
        monthOfGraduation,
        username,
        yearOfGraduation
      );
    }

    dispatch(addCollegeState(educationInstitution));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(catchSaveUserDataError(error));
    logActionError('addCollege', error);
  }
};

const addCollegeState = payload => ({
  type: types.ADD_COLLEGE_STATE,
  payload,
});

// 2. SKILLS SECTION

// 2.1. Hacker Type
// See the saveUserData thunk

// 2.2. Skills
export const updateSkills = skills => async dispatch => {
  /**
   * Receives the skills with udpated priority to send
   * @param {string} skills - skills with updated priorities
   */
  const { username } = getState(STATE.AUTHENTICATION);
  const { skills: skillsState } = getState(STATE.USER);

  dispatch(updateSaveStatus(SAVE.PROGRESS));

  dispatch(updateSkillsState(skillsState));
  try {
    const skillsWithoutExperience = skills.map(({ experience, ...rest }) => ({ ...rest }));
    await API.user.setUserSkills(username, skillsWithoutExperience);
    dispatch(updateSaveStatus(SAVE.SUCCESS));
    dispatch({ type: types.SORTED_SKILLS });
  } catch (error) {
    catchSaveUserDataError(error);
    logActionError('updateSkills', error);
  }
};

const deleteSkillState = skillIndex => ({
  type: types.DELETE_SKILL_STATE,
  payload: skillIndex,
});

const addSkillsState = newSkill => ({
  type: types.ADD_SKILL_STATE,
  payload: newSkill,
});

const updateSkillsState = skills => ({
  type: types.UPDATE_SKILL_STATE,
  payload: skills,
});

export const deleteSkill = (skillID, skillIndex) => async dispatch => {
  /**
   * Receives the skills with udpated priority to send
   * @param {string} skillID - skillID of the skill to be deleted
   * @param {string} skillIndex - the skill index to be removed
   */
  const { username } = getState(STATE.AUTHENTICATION);
  dispatch(updateSaveStatus(SAVE.PROGRESS));

  try {
    dispatch(deleteSkillState(skillIndex));
    await API.user.deleteSkill(username, skillID);
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    catchSaveUserDataError(error);
    logActionError('deleteSkill', error);
  }
};

export const addSkill = (newSkill, allSkills) => async dispatch => {
  const { username } = getState(STATE.AUTHENTICATION);
  dispatch(updateSaveStatus(SAVE.PROGRESS));

  try {
    dispatch(addSkillsState(newSkill));
    const allSkillsWithoutExperience = allSkills.map(({ experience, ...rest }) => ({ ...rest }));
    const { data: skills } = await API.user.setUserSkills(username, allSkillsWithoutExperience);
    skills.sort((skillA, skillB) => skillA.priority > skillB.priority);

    dispatch(updateSkillsState(skills));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    catchSaveUserDataError(error);
    logActionError('addSkill', error);
  }
};

// 2.3. Resume
export const uploadResume = resume => async dispatch => {
  /**
   * This async action handles the resume upload flow by the user
   * @param {string} resume - the accepted PDF file
   */
  const { username } = getState(STATE.AUTHENTICATION);
  dispatch(uploadResumeRequest());

  try {
    const response = await API.user.getResumeUploadURL(username);
    const { signedUrl, url } = response.data;

    // Upload the document to the DB
    await EXTERNAL_API.uploadFile(
      signedUrl,
      resume,
      getUploadFileConfig({ contentType: resume.type, isPrivate: true })
    );

    // Save the URL
    await API.user.setUserData(username, 'resume', url);

    dispatch(uploadResumeSuccess(url));
  } catch (error) {
    dispatch(uploadResumeFailure());
    logActionError(types.UPLOAD_RESUME_FAILURE, error);
  }
};

const uploadResumeRequest = () => ({
  type: types.UPLOAD_RESUME_REQUEST,
  payload: RESUME.UPLOADING,
});

const uploadResumeSuccess = payload => ({
  type: types.UPLOAD_RESUME_SUCCESS,
  payload,
});

export const uploadResumeFailure = () => ({
  type: types.UPLOAD_RESUME_FAILURE,
  payload: RESUME.FAILED,
});

// 2.4. Work Experience
/**
 * This async action performs an updation whenever a user selects an
 * option from the dropdown or clears the input or when the job title
 * is updated
 *
 * @param {*} index Index of the experience item to be updated. Required when
 * experience ID is not passed.
 * @param {string} [experienceID=''] uuid of the experience item to be updated
 */
export const updateExperience =
  (index, experienceID = '') =>
  async dispatch => {
    const { username } = getState(STATE.AUTHENTICATION);
    const { experience } = getState(STATE.USER);

    const currentExperience = experience[index];

    // Send an update request when we already have a uuid
    if (
      (currentExperience.hasOwnProperty('uuid') && currentExperience.uuid.length !== 0) ||
      experienceID.length !== 0
    ) {
      try {
        dispatch(updateSaveStatus(SAVE.PROGRESS));

        const experienceItem = experience.find(item => item.uuid === experienceID);
        /**
         * Fix for sentry issue - https://sentry.io/organizations/hack-inout-tech-llp/issues/2204052126/?project=1193563
         * This method is getting called sometimes with empty experienceID but with a uuid in currentExperience
         * when experience is getting created. Wasn't able to reproduce this issue, but this condition
         * is sufficient enough would prevent that from happening.
         */
        if (experienceItem) {
          await API.user.updateExperience(username, experienceItem);
          dispatch(updateSaveStatus(SAVE.SUCCESS));
        }
        // TODO: Although this whole experience update and create logic needs to be revisited when auto save is removed
        // since the currentExperience object is getting mutated right now, which is not a good sign.
      } catch (error) {
        dispatch(updateSaveStatus(SAVE.FAILURE));
        logActionError('updateExperience', error, { index, experienceID, currentExperience });
      }
    }
  };

const deleteExperienceAction = payload => ({
  type: types.DELETE_EXPERIENCE,
  payload,
});

export const deleteExperience = experienceID => async dispatch => {
  try {
    dispatch(updateSaveStatus(SAVE.PROGRESS));
    dispatch(deleteExperienceAction({ status: STATUS.REQUEST, experienceID }));

    const { username } = getState(STATE.AUTHENTICATION);

    if (typeof experienceID === 'string' && experienceID.length > 0) {
      await API.user.removeExperience(username, experienceID);
    }

    dispatch(updateSaveStatus(SAVE.SUCCESS));
    dispatch(deleteExperienceAction({ status: STATUS.SUCCESS }));
  } catch (error) {
    dispatch(updateSaveStatus(SAVE.FAILURE));
    dispatch(deleteExperienceAction({ status: STATUS.FAILURE }));
    logActionError(types.DELETE_EXPERIENCE, error);
  }
};

// 4. LINKS

// 4.2. Other Profile
const getProfileName = profileObject => {
  /**
   * Returns the name param to create or udpate a profile.
   * Note that this is not the displayed name.
   */
  const { profileFocus } = getState(STATE.USER);

  // GitHub can be received incase user has chosen to connect to
  // GitHub or entered a URL in the Other Profiles Input
  if (profileObject.name === INTEGRATION.GITHUB) {
    return INTEGRATION.GITHUB;
  }

  if (profileObject.name === null) {
    if (profileFocus.name === null || profileObject.uuid !== null) {
      // User clicked on + Add and then made a duplicate entry
      // Or entered a value with null name from Clearbit
      return `${PROFILES.OTHERS}${getHostName(profileObject.value)}`;
    }

    return profileFocus.name;
  }

  return profileObject.name;
};

export const addProfile = (profileObject, index) => async dispatch => {
  /**
   * Sends a request to add a profile.
   * @param {Object} profileObject - a ProfileObject (see Profile/Links.jsx)
   * @param {number} index - the index at which profile is added, can be null
   * when adding a linking/connecting GitHub
   */
  dispatch(updateSaveStatus(SAVE.PROGRESS));

  const { username, profileFocus } = getState(STATE.USER);
  const updatedName = getProfileName(profileObject);

  try {
    const response = await API.user.addProfile(username, { ...profileObject, name: updatedName });
    if (updatedName === INTEGRATION.GITHUB) {
      // Reflect in the UI that GitHub has been connected
      dispatch(addGitHubState(response.data));
    } else {
      // If the name obtained via Clearbit is different we'll remove that linked profile
      if (profileFocus.name !== updatedName) {
        dispatch(removeProfile(profileObject));
      }

      // Update the state with received info
      dispatch(updateProfileState(index, { name: response.data.name, uuid: response.data.uuid }));
      dispatch(setUserState('profileFocus', { name: response.data.name, uuid: response.data.uuid }));
    }

    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(catchSaveUserDataError(error));
    logActionError('addProfile', error);
  }
};

export const removeProfile =
  (profileObject, index = null) =>
  async dispatch => {
    if (profileObject.name === INTEGRATION.GITHUB) {
      dispatch(deleteGitHubState());
    } else if (index !== null) {
      dispatch(deleteProfileState(index));
    }

    if (profileObject.uuid !== null) {
      try {
        const { username } = getState(STATE.AUTHENTICATION);
        dispatch(updateSaveStatus(SAVE.PROGRESS));

        await API.user.removeProfile(username, profileObject.uuid);
        dispatch(updateSaveStatus(SAVE.SUCCESS));
      } catch (error) {
        dispatch(catchSaveUserDataError(error));
        logActionError('removeProfile', error);
      }
    }
  };

export const addOtherProfiles = profiles => async dispatch => {
  /**
   * Receives an array of {name, value} objects to add to the user linked accounts
   * @param {Object} profiles
   */
  const { username } = getState(STATE.AUTHENTICATION);
  try {
    const addProfiles = Object.keys(profiles).map(key =>
      API.user.addProfile(username, { name: key, value: profiles[key] })
    );

    await Promise.all(addProfiles);
  } catch (error) {
    dispatch(userError(ERRORS.fieldRequired));
    logActionError('addOtherProfiles', error);
  }
};

export const inputProfileDebouncer = (profileObject, index) => dispatch => {
  /**
   * Update the value immediately. Set the logo and name as null to prevent
   * displaying them while user is still typing
   */
  profileObject.logo = null;
  profileObject.name = null;
  dispatch(updateProfileState(index, profileObject));

  // Updation of logo handled via debounced function
  if (isValidURL(profileObject.value)) {
    debounceSetData(CLIENT_PARAM.profiles, { profileObject, index }, dispatch);
  }
};

const updateProfileAndState = value => async dispatch => {
  const updatedProfile = await getDetailedProfile(value.profileObject);
  // TODO: Figure out the problem here
  if (updatedProfile.name === 'Undefined') {
    return;
  }
  // Make an add profile request
  dispatch(addProfile(updatedProfile, value.index));

  // Update the profile state with logo and name
  // We do not need to set the value again here
  delete updatedProfile.value;
  dispatch(updateProfileState(value.index, updatedProfile));
};

const deleteProfileState = profileIndex => ({
  type: types.DELETE_PROFILE_STATE,
  payload: profileIndex,
});

export const addProfileState = newProfile => ({
  type: types.ADD_PROFILE_STATE,
  payload: newProfile,
});

const addGitHubState = githubProfile => ({
  type: types.ADD_GITHUB_STATE,
  payload: githubProfile,
});

const deleteGitHubState = () => ({
  type: types.DELETE_GITHUB_STATE,
});

const updateProfileState = (index, profileObject) => ({
  type: types.UPDATE_PROFILE_STATE,
  payload: { index, profileObject },
});

// CLEAR USER DATA
export const clearUserData = () => ({
  type: types.CLEAR_USER_DATA,
});

// AUTO SAVE ASYNC ACTIONS
export const setUserState = (name, value) => ({
  type: types.SET_USER_STATE,
  payload: {
    name,
    value,
  },
});

export const updateSaveStatus = status => ({
  type: types.UPDATE_SAVE_STATUS,
  payload: status,
});

// 5. CONTACT SECTION

const updatePhoneNumber = value => dispatch => {
  const { username } = getState(STATE.AUTHENTICATION);

  API.user
    .setUserData(username, SERVER_PARAM.phoneNumber, value)
    .then(response => dispatch(setUserState('isPhoneNumberVerified', response.data.phone_number_verified)))
    .catch(error => {
      if (error?.response?.data?.error?.source?.phone_number?.msg === SERVER_ERRORS.phoneNumberDuplicate) {
        dispatch(userError(ERRORS.phoneNumberDuplicate));
      } else {
        logActionError('updatePhoneNumber', error);
      }
    });
};

/**
 * This action checks whether the email entered by the user is available
 * or not and then sends the OTP if required by the param
 * @param {string} value Email that the user wants to send otp to
 * @param {boolean} sendEmailOTP Should the OTP be sent
 */
export const checkEmailAvailabilityAndSendOTP =
  (value, sendEmailOTP = true) =>
  async dispatch => {
    try {
      const { email: storeEmail } = getState(STATE.USER);
      const isValid = isValidEmail(value);
      // Set the verify email status as READY, this is required to
      // avoid a bug which shows the success state when verifying
      // email in the Verify component
      dispatch(verifyEmailOTPReady());
      await dispatch(updateSaveStatus(SAVE.PROGRESS));

      // If the email is invalid then
      // dispatch invalid email error
      if (!isValid) {
        dispatch(userError(ERRORS.emailInvalid));
        dispatch(updateSaveStatus(SAVE.FAILURE));
        return;
      }

      // Don't check for email availability if the user has entered the same
      // email again
      if (storeEmail !== value) {
        const isEmailAssociated = await API.registration.isEmailAssociated(value);
        // If the email is associated with another user then dispatch
        // the appropriate error
        if (isEmailAssociated) {
          dispatch(userError(ERRORS.emailDuplicate));
          dispatch(updateSaveStatus(SAVE.FAILURE));
          return;
        }
      }

      // Send an OTP if required
      if (sendEmailOTP) {
        await API.registration.sendEmailOTP({ email: value });
      }

      dispatch(updateSaveStatus(SAVE.SUCCESS));
    } catch (error) {
      dispatch(updateSaveStatus(SAVE.FAILURE));
      logActionError('checkEmailAvailabilityAndSendOTP', error);
    }
  };

const verifyOTPRequest = () => ({
  type: types.VERIFY_OTP_REQUEST,
  payload: OTP.VERIFY_REQUEST,
});

const verifyOTPSuccess = () => ({
  type: types.VERIFY_OTP_SUCCESS,
  payload: OTP.VERIFY_SUCCESS,
});

const verifyOTPFailure = payload => ({
  type: types.VERIFY_OTP_FAILURE,
  payload,
});

export const verifyOTP = otp => async dispatch => {
  /**
   * Request verification of the phone number via the given OTP
   * @param {number} otp - the user entered OTP
   */
  dispatch(verifyOTPRequest());
  try {
    const { username, phoneNumber } = getState(STATE.USER);
    await API.user.verifyOTP(username, phoneNumber, otp);

    storeItem('devfolio-verification', {
      isEmailVerified: true,
      isPhoneNumberVerified: true,
    });

    dispatch(verifyOTPSuccess());
  } catch (error) {
    const err = getError(error);
    let message = OTP.VERIFY_FAILURE;
    if (err?.status === 429) {
      message = OTP.VERIFY_RATE_LIMIT_EXCEEDED;
    }
    dispatch(verifyOTPFailure(message));
    logActionError(types.VERIFY_OTP_FAILURE, error);
  }
};

const sendOTPRequest = () => ({
  type: types.SEND_OTP_REQUEST,
  payload: OTP.REQUEST_OTP,
});

const sendOTPSuccess = () => ({
  type: types.SEND_OTP_SUCCESS,
  payload: OTP.REQUEST_SUCCESS,
});

const sendOTPFailure = payload => ({
  type: types.SEND_OTP_FAILURE,
  payload,
});

export const clearPhoneVerifyStatus = () => ({
  type: types.CLEAR_PHONE_VERIFY_STATUS,
  payload: null,
});

export const sendOTP = isResend => async dispatch => {
  dispatch(sendOTPRequest());

  try {
    const { phoneNumber, username } = getState(STATE.USER);
    if (!isResend) {
      await API.user.sendOTP(username, phoneNumber);
    } else {
      await API.user.resendOTP(username, phoneNumber);
    }

    dispatch(sendOTPSuccess());
  } catch (error) {
    const err = getError(error);
    let message = OTP.REQUEST_FAILURE;
    if (err?.status === 429) {
      message = OTP.REQUEST_RATE_LIMIT_EXCEEDED;
    } else if (err?.message === SERVER_ERRORS.phoneNumberInvalid) {
      message = OTP.REQUEST_INVALID_NUMBER;
    }
    dispatch(sendOTPFailure(message));
    logActionError(types.SEND_OTP_FAILURE, error);
  }
};

const sendEmailOTPSuccess = () => ({
  type: types.SEND_EMAIL_OTP_SUCCESS,
});

const sendEmailOTPFailure = () => ({
  type: types.SEND_EMAIL_OTP_FAILURE,
});

const sendEmailOTPRequest = () => ({
  type: types.SEND_EMAIL_OTP_REQUEST,
});

export const resendEmailOTP =
  (paramAuth = '') =>
  async dispatch => {
    /**
     * Send an OTP to the user's email using the auth param
     * @param {string} paramAuth - Email or username of the user
     */
    try {
      dispatch(sendEmailOTPRequest());

      const auth = paramAuth.length > 0 ? paramAuth : getState(STATE.AUTHENTICATION).username;
      const isValid = isValidUsername(auth) || isValidEmail(auth);

      if (isValid) {
        const type = isValidEmail(auth) ? 'email' : 'username';
        await API.registration.sendEmailOTP({ [type]: auth });
        dispatch(sendEmailOTPSuccess());
      } else {
        dispatch(userError(ERRORS.usernameInvalid));
        dispatch(sendEmailOTPFailure());
      }
    } catch (error) {
      dispatch(sendEmailOTPFailure());
      logActionError(types.SEND_EMAIL_OTP_FAILURE, error);
    }
  };

const verifyEmailOTPReady = () => ({
  type: types.EMAIL_VERIFY_OTP_READY,
  payload: STATUS.READY,
});

const verifyEmailOTPRequest = () => ({
  type: types.EMAIL_VERIFY_OTP_REQUEST,
  payload: EMAIL_OTP.VERIFY_REQUEST,
});

const verifyEmailOTPSuccess = () => ({
  type: types.EMAIL_VERIFY_OTP_SUCCESS,
  payload: EMAIL_OTP.VERIFY_SUCCESS,
});

const verifyEmailOTPFailure = () => ({
  type: types.EMAIL_VERIFY_OTP_FAILURE,
  payload: EMAIL_OTP.VERIFY_FAILURE,
});

export const verifyEmailOTP = (email, otp) => async dispatch => {
  /**
   * Request verification of the email via the given OTP
   * @param {string} email - the email for which the user received the OTP
   * @param {number} otp - the user entered OTP
   */
  try {
    const { username } = getState(STATE.AUTHENTICATION);
    dispatch(verifyEmailOTPRequest());

    await API.user.updateEmailAndVerifyOTP(username, email, parseInt(otp, 10));
    dispatch(setUserState('isEmailVerified', true));
    dispatch(setUserState('email', email));

    dispatch(verifyEmailOTPSuccess());
  } catch (error) {
    const err = getError(error);

    if (err?.hasOwnProperty('message')) {
      switch (err.message) {
        case SERVER_ERRORS.OTPInvalid:
          dispatch(verifyEmailOTPFailure());
          break;
        case SERVER_ERRORS.ethindiaUpdatesBlocked:
          dispatch({
            type: types.EMAIL_VERIFY_OTP_FAILURE,
            payload: ERRORS.emailUpdatesBlockedForETHIndia,
          });
          break;
        default:
          break;
      }
    }

    logActionError(types.EMAIL_VERIFY_OTP_FAILURE, error);
  }
};

const debounceSetData = debounce((name, value, dispatch) => {
  prevAction = { name, value, hasError: false };
  return dispatch(setUserData(name, value));
}, WAIT_TIME);

export const inputDebouncer = (name, value, hasError) => dispatch => {
  dispatch(setUserState(name, value));

  // prevAction will contain the name and value of the previous inputDebouncer
  // call. Using, the name key, we'll identify if the input has changed, and
  // call the API accordingly so even if the debouncer is overriden, the value
  // gets updated.
  const { name: prevName, value: prevValue, hasError: prevHasError } = prevAction;

  // Avoid updating the profiles in the input debouncer because it gets updated
  // in the inputProfileDebouncer function
  if (prevName.length && prevName !== name && !prevHasError && prevName !== CLIENT_PARAM.profiles) {
    dispatch(setUserData(prevName, prevValue));
  }

  prevAction = { name, value, hasError };
  if (!hasError) debounceSetData(name, value, dispatch);
};

export const setUserData = (name, value) => async dispatch => {
  /**
   * Make the relevant API call based on the name param.
   * Additionally, update the saveStatus state.
   * @param {string} name - user property to be set (one of CLIENT_PARAM)
   * @param {string} value - value of the property to be updated
   */

  try {
    const { username } = getState(STATE.AUTHENTICATION);
    const { error } = getState(STATE.USER);
    dispatch(updateSaveStatus(SAVE.PROGRESS));

    switch (name) {
      case CLIENT_PARAM.domain_expertise:
        await API.user.udpateHackerType(username, value);
        break;
      case CLIENT_PARAM.experience:
        await dispatch(updateExperience());
        break;
      case CLIENT_PARAM.is_education:
        dispatch(clearEducationState());
        await API.user.setHasEducation(username, !value);
        break;
      case CLIENT_PARAM.no_work_exp:
        await API.user.setHasExperience(username, value);
        break;
      case CLIENT_PARAM.phone_number:
        dispatch(updatePhoneNumber(value));
        break;
      case CLIENT_PARAM.profiles:
        // Get and set link info obtained via Clearbit
        // Send the updated link info
        dispatch(updateProfileAndState(value));
        break;
      case CLIENT_PARAM.city:
      case CLIENT_PARAM.country:
      case CLIENT_PARAM.emergency_contact_name:
      case CLIENT_PARAM.line_1:
      case CLIENT_PARAM.line_2:
      case CLIENT_PARAM.state:
      case CLIENT_PARAM.zip:
        await API.user.updateContact(username, SERVER_PARAM[name], value && value.length ? value : null);
        break;
      case CLIENT_PARAM.emergency_contact_number:
        await API.user.updateContact(username, SERVER_PARAM[name], value && value.length ? value : null);
        break;
      case CLIENT_PARAM.dietary_preferences:
      case CLIENT_PARAM.allergies:
        await API.user.updateUserExtra(username, {
          [SERVER_PARAM[name]]: value,
        });
        break;
      default:
        await API.user.setUserData(username, SERVER_PARAM[name], value);
        break;
    }

    // Incase the invalid emergency contact number error is in the store, clear it.
    if (error === ERRORS.emergencyPhoneNumberInvalid) {
      dispatch(clearUserError(error));
    }

    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(updateSaveStatus(SAVE.FAILURE));
    const err = getError(error);

    if (err?.hasOwnProperty('message')) {
      switch (err?.message) {
        case SERVER_ERRORS.unprocessableRequest:
          if (err?.source?.hasOwnProperty('emergency_contact_number')) {
            dispatch(userError(ERRORS.emergencyPhoneNumberInvalid));
          }
          break;
        case SERVER_ERRORS.ethindiaUpdatesBlocked:
          if (name === CLIENT_PARAM.first_name) {
            dispatch(userError(ERRORS.firstNameUpdatesBlockedForETHIndia));
          } else if (name === CLIENT_PARAM.last_name) {
            dispatch(userError(ERRORS.lastNameUpdatesBlockedForETHIndia));
          }
          break;
        default:
          break;
      }
    }

    logActionError(types.EMAIL_VERIFY_OTP_FAILURE, error);
  }
};

/**
 * Upload Avatar
 * This async action handles the avatar upload flow by the user
 * @param {string} avatar - the accepted image avatar file
 */
export const uploadAvatar = avatar => async dispatch => {
  try {
    dispatch(updateSaveStatus(SAVE.PROGRESS));
    const { username } = getState(STATE.AUTHENTICATION);
    dispatch(uploadAvatarRequest({ status: FILE.UPLOADING }));

    const compressedAvatar = await imageCompression(avatar, { maxSizeMB: 1, maxWidthOrHeight: 512 });

    const { type, mimeType } = getFileType(compressedAvatar);
    const response = await API.user.getAvatarUploadURL(username, type);
    const { signedUrl, url } = response.data;

    // Upload the document to the DB
    await EXTERNAL_API.uploadFile(
      signedUrl,
      compressedAvatar,
      getUploadFileConfig({ contentType: mimeType, isPrivate: false })
    );

    // Save the URL
    const { data } = await API.user.setUserData(username, 'profile_image', url);

    dispatch(uploadAvatarRequest({ status: FILE.UPLOADED, url: data.profile_image, file: avatar }));
    dispatch(updateSaveStatus(SAVE.SUCCESS));
  } catch (error) {
    dispatch(updateSaveStatus(SAVE.FAILURE));
    dispatch(uploadAvatarRequest({ status: FILE.FAILED }));
    logActionError(types.UPLOAD_AVATAR, error);
  }
};

const uploadAvatarRequest = payload => ({
  type: types.UPLOAD_AVATAR,
  payload,
});

export const deleteAvatar = () => dispatch => {
  dispatch(setUserData('profileImageURL', null));
  dispatch(setUserState('profileImageURL', null));
};

const updateTourStatus = payload => ({
  type: types.UPDATE_TOUR_TAKEN,
  payload,
});

export const updateTourTaken =
  (hasTakenTour = true) =>
  async dispatch => {
    try {
      dispatch(updateTourStatus({ status: STATUS.REQUEST }));

      const { username } = getState(STATE.AUTHENTICATION);
      await API.user.updateTourTaken(username, hasTakenTour);

      dispatch(updateTourStatus({ status: STATUS.SUCCESS, hasTakenTour }));
    } catch (error) {
      dispatch(updateTourStatus({ status: STATUS.FAILURE }));
      logActionError(types.UPDATE_TOUR_TAKEN, error);
    }
  };

const updateNameStatus = payload => ({
  type: types.UPDATE_NAME,
  payload,
});

export const updateName = (firstName, lastName) => async dispatch => {
  try {
    dispatch(updateNameStatus({ status: STATUS.REQUEST }));
    const { username } = getState(STATE.AUTHENTICATION);
    await API.user.setUserData(username, SERVER_PARAM.firstName, firstName);
    await API.user.setUserData(username, SERVER_PARAM.lastName, lastName);
    dispatch(updateNameStatus({ status: STATUS.SUCCESS, firstName, lastName }));
  } catch (error) {
    dispatch(updateNameStatus({ status: STATUS.FAILURE }));
    logActionError(types.UPDATE_NAME, error);
  }
};

const updatePublicProfileVisibleStatus = payload => ({
  type: types.UPDATE_PUBLIC_PROFILE_VISIBLE,
  payload,
});

export const updatePublicProfileVisible = visible => async dispatch => {
  try {
    const { username } = getState(STATE.AUTHENTICATION);
    dispatch(updatePublicProfileVisibleStatus({ visible, status: STATUS.REQUEST }));
    dispatch(updateSaveStatus(SAVE.PROGRESS));

    await API.user.updateUserExtra(username, { [SERVER_PARAM.publicProfileVisible]: visible });

    dispatch(updateSaveStatus(SAVE.SUCCESS));
    dispatch(updatePublicProfileVisibleStatus({ status: STATUS.SUCCESS }));
  } catch (error) {
    dispatch(updateSaveStatus(SAVE.FAILURE));
    dispatch(updatePublicProfileVisibleStatus({ status: STATUS.FAILURE }));
    logActionError(types.UPDATE_PUBLIC_PROFILE_VISIBLE, error);
  }
};

export const fetchUserBasicInfoStatus = payload => ({
  type: types.FETCH_USER_BASIC_INFO,
  payload,
});

/**
 * Fetches the basic info of the user, and dispatches actions that update the reducer
 * authentication state with the username and user state with the info
 * @param {boolean} [shouldUpdateStatus=true] Should the status be updated in the reducer
 * This param should be passed as false when you don't want the Routes component to re-render
 */
export const fetchUserBasicInfo =
  (shouldUpdateStatus = true) =>
  async dispatch => {
    try {
      const { uuid } = getState(STATE.AUTHENTICATION);
      dispatch(fetchUserBasicInfoStatus({ status: STATUS.REQUEST, shouldUpdateStatus }));

      const { data } = await API.user.fetchBasicUserData(uuid);
      data.user_id = data.uuid;
      const responseDOB = data.dob;
      if (typeof responseDOB === 'string') {
        data.dob = moment(responseDOB).format('DD/MM/YYYY');
      }
      const mappedData = mapToClient(data);

      dispatch(fetchUserBasicInfoStatus({ status: STATUS.SUCCESS, data: mappedData, shouldUpdateStatus }));
    } catch (error) {
      dispatch(fetchUserBasicInfoStatus({ status: STATUS.FAILURE, shouldUpdateStatus }));
      logActionError(types.FETCH_USER_BASIC_INFO, error);
    }
  };

const fetchUserStatsStatus = payload => ({
  type: types.FETCH_USER_STATS,
  payload,
});

export const fetchUserStats = () => async dispatch => {
  try {
    const { username } = getState(STATE.AUTHENTICATION);
    dispatch(fetchUserStatsStatus({ status: STATUS.REQUEST }));

    const { data } = await API.user.fetchUserStats(username);

    dispatch(fetchUserStatsStatus({ status: STATUS.SUCCESS, data }));
  } catch (error) {
    dispatch(fetchUserStatsStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_USER_STATS, error);
  }
};

const fetchUserExtraInfoStatus = payload => ({
  type: types.FETCH_USER_EXTRA_INFO,
  payload,
});

export const fetchUserExtraInfo = () => async dispatch => {
  try {
    const { username } = getState(STATE.AUTHENTICATION);
    dispatch(fetchUserExtraInfoStatus({ status: STATUS.REQUEST }));

    const { data } = await API.user.fetchUserExtraInfo(username);
    dispatch(fetchUserExtraInfoStatus({ status: STATUS.SUCCESS, data: mapToClient(data) }));
  } catch (error) {
    dispatch(fetchUserExtraInfoStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_USER_EXTRA_INFO, error);
  }
};

const fetchFeatureFlagsStatus = payload => ({
  type: types.FETCH_FEATURE_FLAGS,
  payload,
});

export const fetchFeatureFlags = () => async dispatch => {
  try {
    const { uuid } = getState(STATE.AUTHENTICATION);
    dispatch(fetchFeatureFlagsStatus({ status: STATUS.REQUEST }));
    const { data } = await API.user.fetchFeatureFlags(uuid);
    dispatch(fetchFeatureFlagsStatus({ status: STATUS.SUCCESS, featureFlags: data ?? [] }));
  } catch (error) {
    dispatch(fetchFeatureFlagsStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_FEATURE_FLAGS, error);
  }
};

export const toggleBetaStatus = payload => ({
  type: types.TOGGLE_BETA,
  payload,
});

export const toggleBeta = isBeta => async dispatch => {
  try {
    const { uuid } = getState(STATE.AUTHENTICATION);
    const { featureFlags } = getState(STATE.USER);
    dispatch(toggleBetaStatus({ status: STATUS.REQUEST }));
    if (isBeta) {
      await API.user.leaveBeta(uuid);
      dispatch(
        toggleBetaStatus({
          status: STATUS.SUCCESS,
          featureFlags: featureFlags.filter(({ uuid: featureFlagUUID }) => featureFlagUUID !== BETA_FEATURE_FLAG_UUID),
        })
      );
    } else {
      const { data } = await API.user.joinBeta(uuid);
      dispatch(toggleBetaStatus({ status: STATUS.SUCCESS, featureFlags: uniq([...featureFlags, ...data]) }));
    }
  } catch (error) {
    dispatch(toggleBetaStatus({ status: STATUS.FAILURE }));
    logActionError(types.TOGGLE_BETA, error);
  }
};

const disconnectOAuthProviderStatus = payload => ({
  type: types.DISCONNECT_OAUTH_PROVIDER,
  payload,
});

export const disconnectOAuthProvider = providerUUID => async dispatch => {
  try {
    const { username } = getState(STATE.AUTHENTICATION);
    dispatch(disconnectOAuthProviderStatus({ status: STATUS.REQUEST }));
    await API.user.disconnectOAuthProvider(username, providerUUID);
    dispatch(disconnectOAuthProviderStatus({ status: STATUS.SUCCESS, providerUUID }));
  } catch (error) {
    dispatch(disconnectOAuthProviderStatus({ status: STATUS.FAILURE }));
    logActionError(types.DISCONNECT_OAUTH_PROVIDER, error);
  }
};

const fetchDiscordIDStatus = payload => ({
  type: types.FETCH_DISCORD_ID,
  payload,
});

export const fetchDiscordID = () => async dispatch => {
  try {
    dispatch(fetchDiscordIDStatus({ status: STATUS.REQUEST }));

    const { me } = await fetchOAuthProviders();

    const discordProvider = me.oauth_providers?.find(({ provider_name: provider }) => provider === 'discord');

    dispatch(fetchDiscordIDStatus({ status: STATUS.SUCCESS, discordProviderUUID: discordProvider?.uuid ?? null }));
  } catch (error) {
    logActionError(types.FETCH_DISCORD_ID, error);
    dispatch(fetchDiscordIDStatus({ status: STATUS.FAILURE }));
  }
};

const disconnectDiscordStatus = payload => ({
  type: types.DISCONNECT_DISCORD,
  payload,
});

export const disconnectDiscord = () => async dispatch => {
  try {
    const { username } = getState(STATE.AUTHENTICATION);
    const { discordProviderUUID } = getState(STATE.USER);
    dispatch(disconnectDiscordStatus({ status: STATUS.REQUEST }));

    await API.user.disconnectOAuthProvider(username, discordProviderUUID);

    dispatch(disconnectDiscordStatus({ status: STATUS.SUCCESS }));
  } catch (error) {
    logActionError(types.DISCONNECT_DISCORD, error);
    dispatch(disconnectDiscordStatus({ status: STATUS.FAILURE }));
  }
};

const fetchAllOAuthProvidersStatus = payload => ({
  type: types.FETCH_OAUTH_PROVIDERS,
  payload,
});

const fetchAllOAuthProvidersWithUserDetails = async (username, oAuthProviders) => {
  const oAuthProvidersWithoutEthereum = oAuthProviders.filter(
    ({ provider_name: provider }) => provider !== OAUTH_PROVIDERS.ETHEREUM
  );

  const oAuthProvidersUserDetails = await Promise.allSettled(
    oAuthProvidersWithoutEthereum.map(provider => API.user.fetchOAuthUserInfo(username, provider.uuid))
  );

  const oAuthProvidersWithUserDetails = oAuthProvidersWithoutEthereum.map((provider, index) => ({
    ...provider,
    userInfo: oAuthProvidersUserDetails?.[index]?.value?.data ?? null,
  }));

  const ethereumProvider = oAuthProviders.find(({ provider_name: provider }) => provider === OAUTH_PROVIDERS.ETHEREUM);

  return [...(typeof ethereumProvider !== 'undefined' ? [ethereumProvider] : []), ...oAuthProvidersWithUserDetails];
};

export const fetchAllOAuthProviders =
  (withUserDetails = false) =>
  async dispatch => {
    try {
      const { username } = getState(STATE.AUTHENTICATION);
      dispatch(fetchAllOAuthProvidersStatus({ status: STATUS.REQUEST }));

      const { me } = await fetchOAuthProviders();

      if (withUserDetails) {
        const allOAuthProviders = await fetchAllOAuthProvidersWithUserDetails(username, me.oauth_providers);
        dispatch(fetchAllOAuthProvidersStatus({ status: STATUS.SUCCESS, allOAuthProviders }));
        return;
      }

      dispatch(fetchAllOAuthProvidersStatus({ status: STATUS.SUCCESS, allOAuthProviders: me.oauth_providers }));
    } catch (error) {
      logActionError(types.FETCH_OAUTH_PROVIDERS, error);
      dispatch(fetchAllOAuthProvidersStatus({ status: STATUS.FAILURE }));
    }
  };

const fetchUserQVDataStatus = payload => ({
  type: types.FETCH_USER_QV_DATA,
  payload,
});

const fetchUserQVDataQuery = async hackathonUUID =>
  graphqlClient.request(
    gql`
      query FetchUserHackathonQuadraticVoting($hackathonUUID: String!) {
        me {
          user_hackathons(where: { hackathon: { uuid: { _eq: $hackathonUUID } } }) {
            uuid
            quadratic_voting {
              uuid
              eas_uuid
              tx_hash
              projects {
                uuid
                votes
                project {
                  uuid
                  name
                  slug
                  favicon: _favicon
                  teams {
                    name
                  }
                  builders {
                    uuid
                    first_name
                    last_name
                    username
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      hackathonUUID,
    }
  );

export const fetchUserQVData = hackathonUUID => async dispatch => {
  try {
    dispatch(fetchUserQVDataStatus({ status: STATUS.REQUEST, hackathonUUID }));
    const data = await fetchUserQVDataQuery(hackathonUUID);
    dispatch(
      fetchUserQVDataStatus({
        status: STATUS.SUCCESS,
        data,
      })
    );
  } catch (error) {
    dispatch(fetchUserQVDataStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_USER_QV_DATA, error, { hackathonUUID });
  }
};

const fetchIsUserEligibleForQVStatus = payload => ({
  type: types.FETCH_IS_USER_ELIGIBLE_FOR_QV,
  payload,
});
export const fetchIsUserEligibleForQV = hackathonUUID => async dispatch => {
  try {
    const { uuid: userUUID } = getState(STATE.AUTHENTICATION);
    dispatch(fetchIsUserEligibleForQVStatus({ status: STATUS.REQUEST }));
    const data = await API.qv.isUserEligibleForQV({ userUUID, hackathonUUID });
    dispatch(
      fetchIsUserEligibleForQVStatus({
        status: STATUS.SUCCESS,
        data,
      })
    );
  } catch (error) {
    dispatch(fetchIsUserEligibleForQVStatus({ status: STATUS.FAILURE }));
    logActionError(types.FETCH_IS_USER_ELIGIBLE_FOR_QV, error, { hackathonUUID });
  }
};

import styled from 'styled-components';

const defaults = {
  getColor: appearance => {
    switch (appearance) {
      case 'danger':
        return '#FF3838';
      case 'primary':
        return '#3770ff';
      default:
        return '#125045';
    }
  },
};

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${props => defaults.getColor(props.appearance)};
  cursor: pointer;
  display: inline-block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1em;
  font-weight: 600;
  margin: 0;
  opacity: 0.8;
  outline: none;
  padding: 0;
  text-align: left;

  &:hover {
    opacity: 1;
  }
`;

export default StyledButton;

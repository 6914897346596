import { gql, GraphQLClient } from 'graphql-request';
import { GRAPHQL_ROOT } from '@constants/environment';

import {
  SiweGenerateNonceMutationVariables,
  SiweGenerateNonceMutation,
  HackathonStakingsQuery,
  HackathonStakingsQueryVariables,
  RsvpStakeMutation,
  RsvpStakeMutationVariables,
  HackathonPrizeTracksQuery,
  HackathonPrizeTracksQueryVariables,
  OAuthProvidersQuery,
  User_Hackathon_Staking_Status_Enum,
} from '../../interfaces/graphql';

export const graphqlClient = new GraphQLClient(GRAPHQL_ROOT, {
  credentials: 'include',
});

export const generateNonce = () =>
  graphqlClient.request<SiweGenerateNonceMutation, SiweGenerateNonceMutationVariables>(gql`
    mutation SiweGenerateNonce {
      siwe_generateNonce {
        nonce
      }
    }
  `);

export const fetchHackathonStakingInfo = ({ hackathonUUID }: HackathonStakingsQueryVariables) =>
  graphqlClient.request<HackathonStakingsQuery, HackathonStakingsQueryVariables>(
    gql`
      query HackathonStakings($hackathonUUID: String!) {
        hackathon_stakings(where: { hackathon: { uuid: { _eq: $hackathonUUID } } }) {
          type
          amount
          uuid
        }
      }
    `,
    { hackathonUUID }
  );

export const fetchUserHackathonStakingInfo = ({ hackathonUUID, username }) =>
  graphqlClient.request(
    gql`
      query UserHackathonStaking($hackathonUUID: String!, $username: citext!) {
        user_hackathon_stakings(
          where: {
            _and: [
              { hackathon: { uuid: { _eq: $hackathonUUID } } }
              { user: { username: { _eq: $username } } }
              { status: {_eq: ${User_Hackathon_Staking_Status_Enum.Completed} } }
            ]
          }
        ) {
          status
          tx_network
          tx_hash
          razorpay_payment_status
          uuid
        }
      }
    `,
    { hackathonUUID, username }
  );

export const createStakingOrder = ({ hackathonStakingUUID, txHash }: RsvpStakeMutationVariables) =>
  graphqlClient.request<RsvpStakeMutation, RsvpStakeMutationVariables>(
    gql`
      mutation RSVPStake($hackathonStakingUUID: String!, $txHash: String) {
        rsvpStaking(hackathon_staking_uuid: $hackathonStakingUUID, tx_hash: $txHash) {
          user_hackathon_staking_uuid
          razorpay_order_id
        }
      }
    `,
    { hackathonStakingUUID, txHash }
  );

export const fetchHackathonPrizeTracks = (variables: HackathonPrizeTracksQueryVariables) =>
  graphqlClient.request<HackathonPrizeTracksQuery, HackathonPrizeTracksQueryVariables>(
    gql`
      query HackathonPrizeTracks($hackathonUUID: String!, $hackathonSlug: citext!) {
        hackathons(where: { _or: [{ slug: { _eq: $hackathonSlug } }, { uuid: { _eq: $hackathonUUID } }] }) {
          prize_tracks {
            uuid
            name
            sponsor {
              uuid
              name
            }
          }
        }
      }
    `,
    variables
  );

export const fetchOAuthProviders = () =>
  graphqlClient.request<OAuthProvidersQuery>(gql`
    query OAuthProviders {
      me {
        oauth_providers {
          uuid
          provider_name
          user_info_id
          user_info
        }
      }
    }
  `);

import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

export const initialState = {
  lists: [],
  status: {
    fetchMailingLists: STATUS.READY,
    resubscribe: STATUS.READY,
    unsubscribe: STATUS.READY,
    updateMailingList: STATUS.READY,
    updateOpportunityPrefs: STATUS.READY,
  },
};

const email = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_MAILING_LIST: {
      const { payload } = action;

      return {
        ...state,
        lists: 'data' in payload ? [...payload.data] : state.lists,
        status: {
          ...state.status,
          fetchMailingLists: payload.status,
        },
      };
    }

    case types.UPDATE_MAILING_LIST: {
      const { payload } = action;

      if (payload.status === STATUS.REQUEST) {
        return {
          ...state,
          lists: state.lists.map(item => {
            if (item.uuid === payload.mailingListID) {
              return { ...item, subscribed: payload.subscribed };
            }

            return item;
          }),
          status: {
            ...state.status,
            updateMailingList: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          updateMailingList: payload.status,
        },
      };
    }

    case types.UPDATE_OPPORTUNITY_PREFS: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          updateOpportunityPrefs: payload.status,
        },
      };
    }

    case types.UNSUBSCRIBE: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          unsubscribe: payload.status,
        },
      };
    }

    case types.RESUBSCRIBE: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          resubscribe: payload.status,
        },
      };
    }

    default:
      return state;
  }
};

export default email;

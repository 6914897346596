import { API, EXTERNAL_API } from '../api';
import { getUploadFileConfig } from '.';
import { logActionError } from './logger';

/**
 * @description This will set an Avatar for a user using an image url provided by google while sign up
 *
 * @param username Username/uuid of a user
 * @param imageUrl URL provided by Google while signing up
 */
export const setUserAvatarFromGoogle = async (username: string, imageUrl: string): Promise<void> => {
  try {
    /**
     * @description Getting Image ArrayBuffer from the URL provided by Google
     */
    const {
      data: imageArrayBuffer,
      headers: { 'content-type': mimeType },
    } = await EXTERNAL_API.getImageArrayBufferFromURL(imageUrl);
    /**
     * @description The API response has mime-type stored in `content-type` key which can be used to determine the type/extension of file
     * @example
     * ```js
     * const mimeType = "image/jpg";
     * const imageTypeArray = mimeType.split('/'); // ["imageType", "jpg"]
     * ```
     */
    const imageTypeArray = mimeType.split('/');

    if (imageTypeArray.length !== 2) {
      throw new Error('Image type was not detected.');
    }

    const imageType = imageTypeArray[1];

    /**
     * @description This is to get the signedUrl for uploading file to s3 bucket.
     */
    const {
      data: { signedUrl, url },
    } = await API.user.getAvatarUploadURL(username, imageType);

    /**
     * @description Creating File object using ArrayBuffer
     * @note using 'dummy.<extension>' as a file name since it will get changed in the backend
     */
    const image = new File([imageArrayBuffer], `dummy.${imageType}`, { type: mimeType });

    /**
     * @description Uploading file to the s3 bucket.
     */
    await EXTERNAL_API.uploadFile(
      signedUrl,
      image,
      getUploadFileConfig({
        contentType: mimeType,
        isPrivate: false,
      })
    );

    await API.user.setUserData(username, 'profile_image', url);
  } catch (error) {
    logActionError('setUserAvatarFromGoogle', error, { username, imageUrl });
  }
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  align-items: center;
  background-color: #bdfbf2;
  border-radius: 3px;
  display: flex;
  height: 56px;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 6px 0;

  &:hover {
    cursor: pointer;
  }
`;

export const Checkmark = styled.div`
  align-items: center;
  background-color: #83f2df;
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: center;
  left: 16px;
  position: absolute;
  width: 32px;
  z-index: 3;

  &::after {
    background-color: rgba(18, 80, 69, 0.4);
    border-radius: 8px;
    content: '';
    display: none;
    height: 16px;
    width: 16px;
    z-index: 6;
  }
`;

export const Button = styled.div`
  display: block;
`;

export const StyledRadio = styled.input`
  background-color: #bdfbf2;
  border-radius: 3px;
  display: flex;
  height: 56px;
  opacity: 0;
  position: absolute;
  left: 0;
  width: 56px;
  z-index: 6;

  &:checked ~ label {
    z-index: 4;
  }

  &:checked ~ ${Button} {
    background-color: #34eacb;
    border-radius: 3px;
    border: 2px solid rgba(18, 80, 69, 0.4);
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 3;
  }

  &:checked ~ ${Checkmark} {
    z-index: 5;
    background-color: rgba(18, 80, 69, 0.4);
    display: block;

    &::after {
      display: block;
      left: 8px;
      position: absolute;
      top: 8px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Label = styled.label`
  color: #125045;
  font-size: 1rem;
  line-height: 56px;
  user-select: none;
  position: absolute;
  padding: 0 24px;
  width: 100%;
  text-align: left;
  padding-left: 64px;

  &:hover {
    cursor: pointer;
  }
`;

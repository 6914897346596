import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

import { API_ROOT } from '@constants/environment';
import { externalAxios as copyAxios, errorHandler } from './axios';
import { getHostName } from '../helpers';
import { GLOBAL_URLS, HACKATHON_LIMIT } from '../constants';
import { GetRandomGIFResponse, APIResponse, SearchCompaniesResponse, CheckMXRecordResponse } from './types';

const getSearchString = (input: string) => getHostName(input);

const searchAxios = axios.create();

searchAxios.interceptors.response.use((response: AxiosResponse<any>): any => {
  let data = [];
  let nbPages = 0;
  let nbHits = 0;
  if (response?.data?.hits) {
    const totalHits = response?.data?.hits;
    data = totalHits?.hits;
    nbHits = totalHits?.total?.value || 0;
    nbPages = Math.ceil(nbHits / HACKATHON_LIMIT) || 0;
  } else {
    data = response?.data?.suggest?.result?.[0]?.options;
  }
  const result = {
    hits: [],
    nbPages,
    nbHits,
  };
  if (Array.isArray(data)) {
    result.hits = data.map(item => {
      const { _source: source } = item;
      return source;
    });
  }
  return result;
}, errorHandler);

const searchRequest = (endpoint: string, data): Promise<AxiosResponse<any>> =>
  searchAxios.post(`${API_ROOT}search/${endpoint}`, data);

const EXTERNAL_API = {
  // Cleabit Autocomplete to search a company
  // Returns name, domain, logo
  searchCompanies: (input: string): APIResponse<SearchCompaniesResponse> => {
    const searchString = getSearchString(input);
    return copyAxios.get(`${GLOBAL_URLS.CLEARBIT_AUTOCOMPLETE}${searchString}`);
  },

  getRandomGIF: (): APIResponse<GetRandomGIFResponse> => copyAxios.get(GLOBAL_URLS.GIPHY),

  checkMXRecord: (domain: string): APIResponse<CheckMXRecordResponse> =>
    copyAxios.get(`${GLOBAL_URLS.MX_CHECK}${domain}`),

  // Upload the file
  uploadFile: (signedURL: string, data: File, config?: AxiosRequestConfig): Promise<any> =>
    copyAxios.put(signedURL, data, { ...config }),

  getImageArrayBufferFromURL: (url: string): APIResponse<ArrayBuffer> =>
    axios.get(url, {
      responseType: 'arraybuffer',
    }),
};

const SEARCH_API = {
  searchSkills: (query: string): APIResponse => searchRequest('skills', { q: query }),

  searchColleges: (query: string): APIResponse => searchRequest('colleges', { q: query }),

  getProject: (slug: string): APIResponse => searchRequest('projects', { slug }),

  searchHackathons: (filters, from): APIResponse =>
    searchRequest('hackathons', { ...filters, size: HACKATHON_LIMIT, from }),

  getHackathonHashtags: (query: string): APIResponse => searchRequest('hashtags', { q: query }),
};

export { EXTERNAL_API, SEARCH_API };

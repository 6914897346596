import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

const Normalize = createGlobalStyle`
    ${styledNormalize}

    * {
      box-sizing: border-box;
    }

    body {
      -webkit-font-smoothing: antialiased;
      background-color: ${props => props.theme.color.grey[1]};
      font-family: 'Nunito Sans', sans-serif;
      font-size: 17px !important;
      font-weight: 400;
      line-height: 1.5;
      overflow-x: hidden;
      overflow-y: scroll;
      text-rendering: optimizeLegibility;
    }

    :root {
      --w3m-z-index: 999999 !important;
    }

    code {
      font-family: 'Source Code Pro', Consolas, 'Lucida Console', 'Liberation Mono', Menlo, 'Courier New', monospace;
    }

    p {
      margin: 8px 0px;
    }

    a {
      text-decoration: none;
      color: ${props => props.theme.color.blue[4]};
    }

    button::-moz-focus-inner {
      border: 0 !important;
    }

    .ui-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100vh;
    }

    .input-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    }

    .line-separator {
      display: block;
      height: 1px;
      background-color: #abafab;
      opacity: 0.4;
      width: 100%;
      margin-bottom: 24px;
    }

    .or-separator {
      position: relative;
      width: 100%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-family: "Varela Round", sans-serif;
      font-weight: 400;
      font-size: 13.5px;
      color: ${props => props.theme.color.grey[6]};
      margin-top: 8px;
      margin-bottom: 8px;

      &::before,
      &::after {
        width: calc(50% - 16px);
        position: absolute;
        height: 1px;
        top: 24px;
        background-color: ${props => props.theme.color.grey[6]};
        content: "";
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    .skills-list,
    .suggestion-list {
      list-style: none !important;
      padding: 0;
      margin: 0;
    }

    .skills-list--item {
      display: flex;
      background-color: ${props => props.theme.color.blue[4]};
      border-radius: 3px;
      border: 1px solid rgba(11, 99, 83, 0.2);
      margin-bottom: 16px;
      color: ${props => props.theme.color.white[0]};
      font-family: "Nunito Sans", sans-serif;
      font-weight: 600;
      font-size: 1em;
      line-height: 1em;
      user-select: none;
      -webkit-touch-callout: none;

      &:hover {
        cursor: row-resize;
      }

      button {
        background: transparent;
        border: none;
        padding: 0;
        height: 24px;
        width: 24px;
        outline: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .skills-list--item > div {
      display: flex;
      height: 54px;
      padding: 0 16px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    .skills-list.compact .skills-list--item > div {
      height: 46px;
    }

    .drag-handle {
      cursor: row-resize;
    }

    .form-button {
      z-index: 9999;
    }

    .suggestions-container {
      padding: 0 1.5rem 1.5rem;

      &.absolute {
        position: absolute;
        top: 2rem;
        left: 100%;
      }

      @media (max-width: 500px) {
        padding: 0 0 1.5rem;

        .suggestion-list {
          li {
            display: inline;
            margin-right: 1rem;
          }
        }
      }

      &.horizontal {
        position: relative;
        margin-left: 0;
      }
    }

    .suggestion-list.horizontal {
      margin: 8px 0 32px 0;

      li {
        display: inline;
        margin-right: 12px;
      }
    }

    .suggestion-list--item {
      border: 0;
      margin: 0;
      padding: 0;
      outline: none;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 1rem;
      background: transparent;
      color: ${props => props.theme.color.blue[4]};
      text-align: left;
      position: relative;
      padding-left: 16px;

      &:hover {
        cursor: pointer;
      }

      &::before {
        content: "+ ";
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .checkbox-container {
      display: block;
      position: relative;
      cursor: pointer;
      user-select: none;
      padding-left: 40px;
      font-size: 1rem;
      color: ${props => props.theme.color.grey[8]};
      opacity: 0.92;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 9999;

        &:checked ~ .checkmark {
          background-color: ${props => props.theme.color.teal[2]};

          &::after {
            display: block;
          }
        }
      }

      &:hover input ~ .checkmark {
        background-color: ${props => props.theme.color.teal[2]};
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background: #cbf8f0;
      border-radius: 3px;
      z-index: 0;

      &::after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    .checkbox-container .checkmark::after {
      left: 8px;
      top: 4px;
      width: 7px;
      height: 13px;
      border: solid ${props => props.theme.color.teal[8]};
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }

    .fixed-menu {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 0;
      margin: 0;
      z-index: 999999;
    }

    .fake-padding {
      padding-bottom: 108px !important;
    }

    .fake-padding-hackathon-detail {
      padding-bottom: 196px !important;
    }

    @media (min-width: 768px) {
      .fixed-menu {
        height: 72px !important;
      }
    }

    .fake-anchor {
      cursor: pointer;
      color: ${props => props.theme.color.blue[4]};
      font-size: 1rem;
      font-family: 'Nunito Sans', sans-serif;
      font-weight: 600;

      &.button {
        border: none;
        background: transparent;
        outline: none;
        padding: 0;
      }
    }

    .skills-react-select {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .hover-underline {
      display: inline-block;
      position: relative;
      color: ${props => props.theme.color.teal[8]};

      &.blue {
        color: ${props => props.theme.color.blue[4]};

        &::after {
          background-color: ${props => props.theme.color.blue[4]};
        }
      }
    }

    .hover-underline::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2.4px;
      bottom: 0;
      left: 0;
      background-color: ${props => props.theme.color.teal[8]};
      transform-origin: bottom right;
      transition: transform 0.36s ease-out;
    }

    .hover-underline:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
      border-radius: 2px;
    }

    .anchor {
      font-size: 1rem;
      font-family: 'Nunito Sans', sans-serif;
      font-weight: 600;
      color: ${props => props.theme.color.teal[8]};
      text-decoration: none;

      &.blue {
        color: ${props => props.theme.color.blue[4]};
      }
    }

    .button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }

    .empty-state--card {
      display: flex;
      background-color: #dcdde1;
      align-items: center;
      justify-content: center;
      min-height: 160px;
      border-radius: 2px;
      padding: 40px;
    }

    .empty-state--text {
      font-size: 1rem;
      font-family: 'Nunito Sans', sans-serif;
      max-width: 520px;
      text-align: center;
      color: ${props => props.theme.color.grey[8]};
    }

    .hover {
      &:hover {
        cursor: pointer;
      }
    }

    .fp {
      font-family: 'Nunito Sans', sans-serif;
    }

    .fs {
      font-family: 'Montserrat', sans-serif;
    }

    .w7 {
      font-weight: 700;
    }

    .w6 {
      font-weight: 600;
    }

    .w4 {
      font-weight: 400;
    }

    .cl {
      color: #69757a;
    }

    .cd {
      color: ${props => props.theme.color.grey[8]};
    }

    .hackathon-detail--section-title {
      font-size: 14px;
      color: ${props => props.theme.color.blue[4]};
      display: flex;
      justify-content: center;
    }

    .status-icon {
      color: #959a95;
      margin-right: 10px;
    }

    @media (max-width: 1200px) {
      .status-icon {
        color: #fff;
      }
    }

    .status-icon--refresh {
      animation: spin 2.4s linear infinite;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    .geosuggest {
      width: 100% !important;
      margin: 0 !important;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    .rodal,
    .rodal-mask {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: rgba(39, 51, 57, 0.85);
      justify-content: center;
      position: fixed !important;
      padding: 10px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 9999998 !important;
    }

    .rodal-dialog {
      display: flex;
      margin: auto;
      height: auto !important;
      flex-direction: column;
      background-color: #fff;
      font-family: inherit;
      font-size: 1rem;
      padding: 1.25em;
      border-radius: 0.3125em;
      box-sizing: border-box;
      justify-content: center;
      position: relative !important;
      width: 100%;
      max-width: 100%;
      z-index: 9999999 !important;
    }

    .sortable-elevate {
      z-index: 1000000;
    }

    /* Make clicks pass-through */
    #nprogress {
      pointer-events: none;
    }

    #nprogress .bar {
      background: #3770ff;
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
    }

    /* Fancy blur effect */
    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px #3770ff, 0 0 5px #3770ff;
      opacity: 1;
      transform: rotate(3deg) translate(0, -4px);
    }

    /* Remove these to get rid of the spinner */
    #nprogress .spinner {
      display: block;
      position: fixed;
      z-index: 1031;
      top: 15px;
      right: 15px;
    }

    #nprogress .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: #3770ff;
      border-left-color: #3770ff;
      border-radius: 50%;
      animation: nprogress-spinner 400ms linear infinite;
    }

    .nprogress-custom-parent {
      overflow: hidden;
      position: relative;
    }

    .nprogress-custom-parent #nprogress .spinner,
    .nprogress-custom-parent #nprogress .bar {
      position: absolute;
    }

    @-webkit-keyframes nprogress-spinner {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes nprogress-spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .grecaptcha-badge {
      visibility: hidden;
    }
  `;

export default Normalize;

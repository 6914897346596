/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { SPACES_BASE_URL } from '@constants/environment';
import * as types from '../constants/actions';
import { ACTION_STATUS } from '../constants';

const initialState = {
  status: {
    fetchProject: ACTION_STATUS.READY,
    fetchComments: ACTION_STATUS.READY,
    postProject: ACTION_STATUS.READY,
    postLike: ACTION_STATUS.READY,
    postComment: ACTION_STATUS.READY,
    deleteComment: ACTION_STATUS.READY,
    fetchUserStats: ACTION_STATUS.READY,
    fetchProjectFunding: ACTION_STATUS.READY,
  },
  project: {
    uuid: null,
    createdAt: null,
    name: null,
    slug: null,
    tagline: null,
    description: [],
    hashtags: [],
    pictures: [],
    videoUrl: null,
    file: null,
    links: [],
    members: [],
    prizes: [],
    updates: [],
    funding: {},
  },

  comments: { data: [], currentPage: 0, count: 0, pages: 0 },
  likes: 0,
  hasLiked: null,
  isLoading: true,
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PROJECT_FAILURE:
      return {
        ...state,
        isLoading: false,
        status: { ...state.status, fetchProject: ACTION_STATUS.FAILURE },
      };
    case types.FETCH_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
        status: { ...state.status, fetchProject: ACTION_STATUS.REQUEST },
      };
    case types.FETCH_PROJECT_SUCCESS: {
      const { payload } = action;

      let projectData = {};
      if (payload.uuid) {
        projectData = Object.assign({}, projectData, { uuid: payload.uuid });
      }

      if (payload.name) {
        projectData = Object.assign({}, projectData, { name: payload.name });
      }

      if (payload.slug) {
        projectData = Object.assign({}, projectData, { slug: payload.slug });
      }

      if (payload.tagline) {
        projectData = Object.assign({}, projectData, { tagline: payload.tagline });
      }

      if (payload.description) {
        projectData = Object.assign({}, projectData, { description: payload.description });
      }

      if (payload.hashtags) {
        const hashtags = payload.hashtags.map(hashtag => ({
          id: hashtag.name,
          text: hashtag.name,
          verified: hashtag.verified,
        }));
        projectData = Object.assign({}, projectData, { hashtags });
      }

      if (payload.pictures) {
        const pictures = payload.pictures.split(',').map(picture => ({ preview: `${SPACES_BASE_URL}${picture}` }));
        projectData = Object.assign({}, projectData, { pictures });
      }

      if (payload.video_url) {
        projectData = Object.assign({}, projectData, { videoUrl: payload.video_url });
      }

      if (payload.file) {
        projectData = Object.assign({}, projectData, {
          file: { preview: `${SPACES_BASE_URL}${payload.file}` },
        });
      }

      if (payload.links) {
        const links = payload.links.split(',').map(link => ({ id: link, text: link }));
        projectData = Object.assign({}, projectData, { links });
      }

      if (payload.created_at) {
        projectData = Object.assign({}, projectData, { createdAt: payload.created_at });
      }

      if (payload.members) {
        projectData = Object.assign({}, projectData, { members: payload.members });
      }

      if (Array.isArray(payload.project_commits) && payload.project_commits.length) {
        projectData = Object.assign({}, projectData, { updates: payload.project_commits });
      }

      if (payload.hackathon) {
        projectData = Object.assign({}, projectData, {
          hackathon: payload.hackathon,
          updates: [
            { uuid: 0, commit: 'Created Project', created_at: payload.created_at },
            ...(Array.isArray(projectData?.updates) ? projectData.updates : []),
          ],
        });
      }

      if (payload.funding) {
        projectData = Object.assign({}, projectData, { funding: payload.funding });
      }

      if (Array.isArray(payload.prizes) && payload.prizes.length) {
        projectData = Object.assign({}, projectData, { prizes: payload.prizes });
      }

      return {
        ...state,
        isLoading: false,
        project: { ...initialState.project, ...projectData },
        views: payload?.views || 0,
        status: { ...state.status, fetchProject: ACTION_STATUS.SUCCESS },
      };
    }
    case types.FETCH_PROJECT_COMMENTS: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        const { comments, pages, likes, count, currentPage } = payload;
        return {
          ...state,
          comments: {
            ...state.comments,
            data: currentPage === 1 ? comments : [...state.comments.data, ...comments],
            pages,
            count,
            currentPage,
          },
          likes: likes || 0,
          status: {
            ...state.status,
            fetchComments: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchComments: payload.status,
        },
      };
    }

    case types.FETCH_PROJECT_FUNDING: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          project: {
            ...state.project,
            funding: payload.funding,
          },
          status: {
            ...state.status,
            fetchProjectFunding: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          fetchProjectFunding: payload.status,
        },
      };
    }

    case types.POST_PROJECT_COMMENT: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        const { data } = payload;
        return {
          ...state,
          comments: {
            ...state.comments,
            data: [data, ...state.comments.data],
            count: state.comments.count + 1,
          },
          status: {
            ...state.status,
            postComment: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          postComment: payload.status,
        },
      };
    }

    case types.POST_PROJECT_LIKE: {
      const { payload } = action;
      const { like } = payload;
      const totalLikes = like ? state.likes + 1 : state.likes - 1;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          status: {
            ...state.status,
            postLike: payload.status,
          },
        };
      }
      return {
        ...state,
        likes: payload.status === ACTION_STATUS.REQUEST ? totalLikes : state.likes - 1,
        hasLiked: payload.status === ACTION_STATUS.REQUEST ? like : !state.hasLiked,
        status: {
          ...state.status,
          postLike: payload.status,
        },
      };
    }

    case types.DELETE_PROJECT_COMMENT: {
      const { payload } = action;
      const { commentUUID } = payload;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          comments: {
            ...state.comments,
            data: state.comments.data.filter(({ uuid }) => uuid !== commentUUID),
            count: state.comments.count - 1,
          },
          status: {
            ...state.status,
            deleteComment: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteComment: payload.status,
        },
      };
    }

    case types.FETCH_USER_PROJECT_STATS: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        const { hasLiked } = payload;
        return {
          ...state,
          hasLiked,
          status: {
            ...state.status,
            fetchUserStats: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchUserStats: payload.status,
        },
      };
    }

    case types.FETCH_PROJECT_QV_STATS: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        const { data: quadraticVotingStats } = payload;
        return {
          ...state,
          quadraticVotingStats,
          status: {
            ...state.status,
            fetchProjectQVStats: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchProjectQVStats: payload.status,
        },
      };
    }

    case types.FETCH_PROJECT_CHEER_ELIGIBILITY: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        const { data: projectCheerEligibility } = payload;
        return {
          ...state,
          projectCheerEligibility,
          status: {
            ...state.status,
            fetchProjectCheerEligibility: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchProjectCheerEligibility: payload.status,
        },
      };
    }
    case types.FETCH_PROJECT_CHEER_CONTRIBUTIONS: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        const { data } = payload;
        return {
          ...state,
          projectCheerContributions: data?.funding,
          status: {
            ...state.status,
            fetchProjectCheerContributions: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchProjectCheerContributions: payload.status,
        },
      };
    }

    case 'INITIALIZE_PROJECT':
      return { ...initialState };
    default:
      return state;
  }
};

export default project;

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { Page } from 'hedron';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { Router } from 'react-router';
import { ThemeProvider } from '@devfolioco/genesis';
import { Analytics } from '@devfolioco/helpers';
// import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import React from 'react';

import ErrorBoundary from './components/ErrorBoundary';
import Normalize from './components/Normalize';
import OfflinePlugin from './components/OfflinePlugin';

import { history } from './helpers/history';
import { printHiringLog, getAnalyticsUserInfo } from './helpers';
import { SEGMENT_ENABLED } from './constants/settings';
import * as Sentry from './helpers/sentry';
import Routes from './routes';
import store from '../shared/store';
// import { updateApp } from './actions/authentication';

Sentry.init();

// if (NODE_ENV === 'production') {
//   LogRocket.init();
// }

// OfflinePluginRuntime.install({
//   onUpdateReady: () => OfflinePluginRuntime.applyUpdate(),
//   onUpdated: () => store.dispatch(updateApp(true)),
// });
// }

Analytics.init({
  userDetailsFetcher: getAnalyticsUserInfo,
  enabled: SEGMENT_ENABLED,
});

printHiringLog();

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider>
        <Page fluid className="ui-container">
          <Normalize />
          <ErrorBoundary>
            <OfflinePlugin>
              <Routes />
            </OfflinePlugin>
          </ErrorBoundary>
        </Page>
      </ThemeProvider>
    </Router>
  </Provider>
);

export default App;

render(<App />, document.getElementById('root'));

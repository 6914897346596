import React from 'react';

import DevfolioLogoSrc from 'assets/devfolio.svg';
import DevfolioLogomarkSrc from 'assets/devfolio-logomark.svg';
import DevfolioWhiteLogoSrc from 'assets/devfolio-white.svg';

const Logo = ({ isWhite = false, isSmall = false, style = {} }) => {
  if (isWhite) {
    return <DevfolioWhiteLogoSrc height={28} style={{ marginTop: 4, ...style }} />;
  }

  if (isSmall) {
    return <DevfolioLogomarkSrc height={28} style={{ marginTop: 4, ...style }} />;
  }

  return <DevfolioLogoSrc height={28} style={{ marginTop: 4, ...style }} />;
};

export default Logo;

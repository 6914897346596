import React from 'react';

import { Button, StyledRadio, Checkmark, Container, Label, Wrapper } from './style';

class Radio extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  handleChange = event => {
    const { value, name } = event.target;
    const { receiveValues } = this.props;

    this.setState({ value });

    receiveValues(name, value);
  };

  render() {
    const { options, name } = this.props;
    const { value } = this.state;

    return (
      <Container>
        {Object.entries(options).map(([key, label]) => (
          <Wrapper key={key}>
            <StyledRadio
              checked={value === key}
              id={key}
              key={key}
              name={name}
              onChange={this.handleChange}
              type="radio"
              value={key}
            />
            <Checkmark />
            <Label htmlFor={key}>{label}</Label>
            <Button />
          </Wrapper>
        ))}
      </Container>
    );
  }
}

export default Radio;

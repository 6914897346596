import styled from 'styled-components';
import { Heading } from '@devfolioco/genesis';

export const Header = styled.header`
  width: 100%;
  padding-top: 96px;

  h1 {
    margin: 16px 0;
  }
`;

export const Body = styled.div`
  display: flex;
`;

export const Section = styled.section`
  padding: 24px 0;

  p,
  li {
    color: ${props => props.theme.color.black[0]};
  }
`;

export const SectionHeading = styled(Heading)`
  border-bottom: 1px solid ${props => props.theme.color.grey[4]};
  margin-bottom: 24px;
  padding-bottom: 4px;
`;

export const ContactRow = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;

  > *:nth-child(2) {
    margin-left: 16px;
  }
`;

export const List = styled.ul`
  list-style-type: lower-alpha;
  padding-left: 16px;
  li {
    margin: 16px 0px;
  }
`;

import Chroma from 'chroma-js';

/**
 * This function will return a hexcode which will contrast the given background color
 *
 * @param background hexcode for background color.
 * @returns hexcode contrasting color
 */
export const getTextColorForBackground = (background: string): Chroma.Color => {
  const chromaBackground = Chroma(background);

  if (isBright(background)) {
    return chromaBackground.darken(2.5);
  }

  return chromaBackground.brighten(2.5);
};

/**
 * This function will return a bool value which will tell if a color is dark or bright
 *
 * @param hex hexcode
 * @returns true if the color is bright
 */
export const isBright = (hex: string): boolean => Chroma(hex).luminance() > 0.5;

import styled from 'styled-components';

export const Input = styled.input`
  display: flex;
  box-sizing: border-box;
  width: ${props => (props.width ? `${props.width}` : '100%')};
  height: ${props => props.height || '56px'};
  border-radius: 3px;
  outline: none;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 1em;
  padding-left: ${props => (props.appearance === 'error' ? '13px' : '15px')};
  padding-right: ${props => (props.appearance === 'error' ? '13px' : '15px')};
  color: ${({ theme }) => theme.color.black[0]};
  border: ${props => (props.appearance === 'error' ? `2px solid ${props.theme.color.red[4]}` : 'none')};
  background-color: ${props => {
    if (props.appearance === 'normal' && !props.disabled) {
      return props.theme.color.teal[1];
    }
    if (props.appearance === 'error') {
      return props.theme.color.red[0];
    }
    return props.theme.color.grey[2];
  }};

  &::placeholder {
    opacity: 0.56;
    color: ${props => {
      if (props.appearance === 'normal' && !props.disabled) {
        return props.theme.color.teal[8];
      }
      if (props.appearance === 'error') {
        return props.theme.color.red[0];
      }
      return props.theme.color.grey[6];
    }};
  }

  &:focus {
    padding-left: 13px;
    padding-right: 13px;
    background-color: ${props => (props.appearance === 'error' ? props.theme.color.red[0] : '#fafbfb')};
    border: 2px solid ${props => (props.appearance === 'error' ? props.theme.color.red[4] : props.theme.color.blue[4])};
  }

  &:focus&::placeholder {
    color: ${props => {
      if (props.appearance === 'normal') {
        return props.theme.color.grey[6];
      }
      if (props.appearance === 'error') {
        return props.theme.color.red[0];
      }
      return props.theme.color.grey[6];
    }};
  }

  &:invalid {
    box-shadow: none;
  }
`;

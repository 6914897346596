import styled from 'styled-components';

export const Textarea = styled.textarea`
  outline: none;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: ${props => props.height};
  background-color: #cffbf3;
  color: ${({ theme }) => theme.color.black[0]};
  border: 1px solid ${({ theme }) => theme.color.teal[4]};
  border-radius: 3px;
  font-family: ${({ theme }) => theme.typography.font.primary};
  font-weight: 400;
  font-size: 1em;
  padding: 15px;
  line-height: 1.5;

  &:focus {
    padding: 14px;
    background-color: #fafbfb;
    border: 2px solid ${({ theme }) => theme.color.blue[4]};
  }
`;

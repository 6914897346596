import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

const initialState = {
  projects: {
    drafts: {
      count: 0,
      pages: 0,
      data: [],
      currentPage: 0,
    },
    published: {
      count: 0,
      pages: 0,
      data: [],
      currentPage: 0,
    },
  },
  ideas: {
    data: [],
    pages: 0,
    currentPage: 0,
    count: 0,
  },
  status: {
    createProject: STATUS.READY,
    deleteProject: STATUS.READY,
    fetchProject: STATUS.READY,
    fetchProjects: STATUS.READY,
    saveProject: STATUS.READY,
    fetchIdeas: STATUS.READY,
  },
};

const myProject = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_SIDE_PROJECT:
      return {
        ...state,
        status: {
          ...state.status,
          createProject: action.payload.status,
        },
      };
    case types.DELETE_SIDE_PROJECT: {
      const { projectID } = action.payload;
      if (action.payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          projects: {
            ...state.projects,
            drafts: {
              ...state.projects.drafts,
              data: state.projects.drafts.data.filter(({ uuid }) => uuid !== projectID),
              count: state.projects.drafts.count - 1,
            },
            published: {
              ...state.projects.published,
              data: state.projects.published.data.filter(({ uuid }) => uuid !== projectID),
              count: state.projects.published.count - 1,
            },
          },
          status: {
            ...state.status,
            deleteProject: action.payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          deleteProject: action.payload.status,
        },
      };
    }

    case types.FETCH_SIDE_PROJECT:
      return {
        ...state,
        status: {
          ...state.status,
          fetchProject: action.payload.status,
        },
      };
    case types.SAVE_SIDE_PROJECT:
      return {
        ...state,
        status: {
          ...state.status,
          saveProject: action.payload.status,
        },
      };
    case types.FETCH_SIDE_PROJECTS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          projects: {
            ...state.projects,
            ...payload.projects,
          },
          status: {
            ...state.status,
            fetchProjects: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchProjects: payload.status,
        },
      };
    }
    case types.FETCH_IDEAS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ideas: {
            ...payload.ideas,
            data: payload.ideas.currentPage === 1 ? payload.ideas.data : [...state.ideas.data, ...payload.ideas.data],
          },
          status: {
            ...state.status,
            fetchIdeas: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchIdeas: payload.status,
        },
      };
    }

    default:
      return state;
  }
};

export default myProject;

import { createSelector } from 'reselect';
import moment from '@helpers/moment';
import { BETA_FEATURE_FLAG_UUID } from '@constants';

export const getAboutState = state => ({
  bio: state.user.bio || '',
  dob: state.user.dob || '',
  firstName: state.user.firstName || '',
  gender: state.user.gender || '',
  lastName: state.user.lastName || '',
  shirtSize: state.user.shirtSize || '',
  username: state.user.username || undefined,
});

export const getEducationState = state => ({
  educationID: state.user.educationID || undefined,
  educationInstitution: state.user.educationInstitution || [],
  hasNoFormalEducation: state.user.hasNoFormalEducation || false,
  isStudent: state.user.isStudent || false,
  monthOfGraduation: state.user.monthOfGraduation || '',
  yearOfGraduation: state.user.yearOfGraduation || '',
});

export const getSkillState = state => ({
  experience: state.user.experience,
  hasNoWorkExperience: state.user.hasNoWorkExperience,
  hackerType: state.user.hackerType || '',
  resumeURL: state.user.resumeURL || '',
  skills: state.user.skills || [],
});

export const getContactState = state => ({
  addressLineOne: state.user.addressLineOne || '',
  addressLineTwo: state.user.addressLineTwo || '',
  city: state.user.city || '',
  country: state.user.country || '',
  email: state.user.email || '',
  emergencyContactName: state.user.emergencyContactName || '',
  emergencyContactNumber: state.user.emergencyContactNumber || '',
  isEmailVerified: state.user.isEmailVerified || false,
  isPhoneNumberVerified: state.user.isPhoneNumberVerified || false,
  phoneNumber: state.user.phoneNumber || '',
  pincode: state.user.pincode || '',
  state: state.user.state || '',
});

export const getLinksState = state => ({
  otherProfiles: state.user.otherProfiles || [],
  gitHubProfile: state.user.gitHubProfile || undefined,
});

const getSectionsCompletionMetric = (aboutState, educationState, skillsState, contactState) => {
  if (aboutState.username !== undefined) {
    // ABOUT SECTION METRIC
    let aboutFilledFields = 0;
    const { firstName, lastName, bio, shirtSize, gender } = aboutState;

    [firstName, lastName, bio, gender, shirtSize].forEach(e => {
      if (e.length > 0) {
        aboutFilledFields += 1;
      }
    });

    const aboutPercentage = (aboutFilledFields / 5) * 100;

    // EDUCATION SECTION METRIC
    // let educationFilledFields = 0;
    // const { hasNoFormalEducation, educationID } = educationState;

    // if (!hasNoFormalEducation && educationID !== undefined) {
    //   educationFilledFields += 1;
    // }

    // if (hasNoFormalEducation) {
    //   educationFilledFields += 1;
    // }

    // const educationPercentage = educationFilledFields * 100;
    const educationPercentage = 100;

    // SKILLS SECTION METRIC
    let skillsFilledFields = 0;
    const { hackerType, skills } = skillsState;

    // Check if the user has entered hacker domain and skills
    [hackerType, skills].forEach(e => {
      if (e.length > 0) {
        skillsFilledFields += 1;
      }
    });

    // const hasExperience = Array.isArray(experience) && experience.length > 0;
    // // When the checkbox is unchecked we'll consider added experiences
    // if (hasExperience) {
    //   if (experience.some(item => item.hasOwnProperty('uuid') && item.uuid.length > 0)) {
    //     skillsFilledFields += 1;
    //   }
    // } else if (hasNoWorkExperience) {
    //   skillsFilledFields += 1;
    // }

    const skillsPercentage = (skillsFilledFields / 2) * 100;

    // LINKS SECTION METRIC
    // let profilesFilledFields = 0;
    // const { otherProfiles } = linksState;

    // if (otherProfiles.length > 0) {
    //   profilesFilledFields += 1;
    // }

    // if (gitHubProfile !== undefined) {
    //   profilesFilledFields += 1;
    // }

    const linksPercentage = 100;

    // CONTACT SECTION METRIC
    let contactFilledFields = 0;

    const { email, emergencyContactName, emergencyContactNumber, isEmailVerified } = contactState;

    [email, emergencyContactName, emergencyContactNumber].forEach(e => {
      if (e.length > 0) {
        contactFilledFields += 1;
      }
    });

    if (isEmailVerified) {
      contactFilledFields += 1;
    }

    const contactPercentage = (contactFilledFields / 4) * 100;

    return {
      aboutPercentage,
      skillsPercentage,
      linksPercentage,
      educationPercentage,
      contactPercentage,
    };
  }

  return {
    aboutPercentage: 0,
    skillsPercentage: 0,
    linksPercentage: 0,
    educationPercentage: 0,
    contactPercentage: 0,
  };
};

export const getProfileCompletionMetric = createSelector(
  [getAboutState, getEducationState, getSkillState, getContactState, getLinksState],
  (aboutState, educationState, skillsState, contactState, linksState) => {
    const { aboutPercentage, skillsPercentage, linksPercentage, educationPercentage, contactPercentage } =
      getSectionsCompletionMetric(aboutState, educationState, skillsState, contactState, linksState);

    return (
      (aboutPercentage + skillsPercentage + linksPercentage + educationPercentage + contactPercentage) /
      5
    ).toFixed(0);
  }
);

export const getIncompleteSections = createSelector(
  [getAboutState, getEducationState, getSkillState, getContactState, getLinksState],
  (aboutState, educationState, skillsState, contactState, linksState) => {
    const incompleteSections = ['about', 'education', 'skills', 'contact', 'links'];
    const sectionsMetric = getSectionsCompletionMetric(
      aboutState,
      educationState,
      skillsState,
      contactState,
      linksState
    );

    Object.entries(sectionsMetric).forEach(([key, value]) => {
      if (value === 100) {
        const section = key.split('Percentage')[0];
        incompleteSections.splice(incompleteSections.indexOf(section), 1);
      }
    });

    return incompleteSections;
  }
);

export const getHackathonState = hackathon => ({
  otherFields: hackathon.otherFields,
  hackathonDetails: hackathon.hackathonDetails,
});

const getHackathonDetails = (hackathon, props) =>
  hackathon.allHackathons.find(hack => hack.uuid === props.match.params.hackathon_name);

const getHackathonID = props => props.match.hackathon_name;

const getPrizes = hackathon => {
  const { prizes } = hackathon;

  return prizes.map(prize => `${prize.quantity}x ${prize.name}`);
};

const getUserID = state => state.user.userID;

const getTeamMembers = state => state.hackathon.teamMembers;

export const getCurrentHackathon = createSelector([getHackathonDetails], hackathon => hackathon);

export const getHackathonIDFromParam = createSelector([getHackathonID], hackathonID => hackathonID);

export const selectPrizesFromHackathon = createSelector([getPrizes], prizes => prizes);

export const selectApplicantEligibility = createSelector([getContactState], contact => {
  const { isEmailVerified, isPhoneNumberVerified } = contact;

  if (!isEmailVerified || !isPhoneNumberVerified) return false;

  return true;
});

export const isUserTeamAdmin = createSelector([getTeamMembers, getUserID], (teamMembers, userID) => {
  if (teamMembers.length > 0) {
    const admin = teamMembers.find(member => member.is_admin === true);
    if (admin) return admin.uuid === userID;
  }

  return false;
});

const getUserHackathonsFromHackathon = hackathon => hackathon.userHackathons;

const getFormType = (state, props) => props.match.params.formType;

const getHackathonSlug = (state, props) => props.match.params.hackathonSlug;

export const isAlreadyRegisteredForRole = createSelector(
  [getUserHackathonsFromHackathon, getFormType, getHackathonSlug],
  (userHackathons, formType, hackathonSlug) =>
    userHackathons &&
    userHackathons.filter(item => item.role === formType && item.hackathon.slug === hackathonSlug).length > 0
);

export const isAlreadyRegisteredForHackathon = createSelector(
  [getUserHackathonsFromHackathon, getHackathonSlug],
  (userHackathons, hackathonSlug) =>
    userHackathons && userHackathons.filter(item => item.hackathon.slug === hackathonSlug).length > 0
);

/**
 * Returns whether the user is a student based on the isStudent or the year
 * of graduation.
 */
export const isHackerStudent = createSelector([getEducationState], educationState => {
  const { monthOfGraduation, yearOfGraduation, isStudent } = educationState;

  // Checks if the user has set a graduation date in the future
  const hasNotGraduated = moment(`${monthOfGraduation} ${yearOfGraduation}`, 'M YYYY').isAfter(moment(), 'month');

  return hasNotGraduated || isStudent;
});

const getUserRoles = user => user.roles || [];

export const isUserAdmin = createSelector([getUserRoles], roles => {
  const adminRoles = ['root', 'admin'];
  const isAdmin = roles.find(({ name }) => adminRoles.includes(name));
  return !!isAdmin;
});

const getUserFeatureFlags = user => user.featureFlags || [];

export const isUserBeta = createSelector([getUserFeatureFlags], data => {
  const betaFeatureFlag = data?.find(({ uuid }) => {
    return uuid === BETA_FEATURE_FLAG_UUID;
  });

  return betaFeatureFlag?.name === 'beta';
});

export const isUserAlpha = createSelector([getUserFeatureFlags], data => {
  const alphaFeatureFlag = data?.findIndex(({ name }) => {
    return name === 'alpha';
  });

  return alphaFeatureFlag !== -1;
});

const getOAuthProviders = state => state.user.allOAuthProviders ?? [];

export const getOAuthProviderInfo = createSelector(
  [getOAuthProviders, (state, provider) => provider],
  (OAuthProviders, provider) => {
    return OAuthProviders.find(providerObj => providerObj.provider_name === provider);
  }
);

export {
  canApply,
  canProceedToPreview,
  canProceedToSaveDraft,
  canSubmitProject,
  isHackathonLiveSelector,
  canTeamSubmit,
  hackathonLocalTimeSelector,
  hasHackathonBegunSelector,
  hasHackathonEndedSelector,
  projectSelector,
  selectOtherFields,
  teamAdminSelector,
  isOnlineWithoutReview,
  isOnlineWithReview,
  isOnline,
} from './hackathon';

import React from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from '@devfolioco/genesis';
import { toast } from 'react-toastify';
import { fetchFeatureFlags } from '@actions/user';
import { ACTION_STATUS as STATUS, OAUTH_PROVIDERS, OAUTH_PROVIDER_LABELS } from '@constants';
import 'react-toastify/dist/ReactToastify.css';
import { isUserBeta } from '@selectors';
import { SERVER_ERRORS } from '@constants/errors';

class OfflinePlugin extends React.Component {
  state = { isOnline: window ? window.navigator.onLine : false };

  offlinetoastId = null;

  unsubscribeFromBetaSubscription = null;

  componentDidMount() {
    const { history, dispatchFetchFeatureFlags, isAuthenticated } = this.props;
    this.handleOAuthErrors();
    this.updateNetworkStatusEventListeners(history.location); // Initial call
    history.listen(this.updateNetworkStatusEventListeners); // On path change
    if (isAuthenticated) {
      dispatchFetchFeatureFlags(); // Fetch feature flags
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { imageUploadStatus, isCurrentUserBeta, isAuthenticated, dispatchFetchFeatureFlags, fetchFeatureFlagStatus } =
      this.props;

    if (prevProps.imageUploadStatus !== imageUploadStatus) {
      if (imageUploadStatus === STATUS.REQUEST) {
        window.addEventListener('beforeunload', this.confirmReload);
        toast("Please don't close the tab while we upload the images", { type: toast.TYPE.INFO });
      }
      if (imageUploadStatus === STATUS.SUCCESS) {
        this.removeConfirmReload();
        toast('All images uploaded successfully', { type: toast.TYPE.SUCCESS });
      }
      if (imageUploadStatus === STATUS.FAILURE) {
        this.removeConfirmReload();
        toast("Sorry we couldn't upload the images please head to project tab to upload them again", {
          type: toast.TYPE.ERROR,
        });
      }
    }

    if (prevProps.isAuthenticated !== isAuthenticated) {
      dispatchFetchFeatureFlags();
    }

    // Subscribe to announcement
    if (
      ((prevProps.fetchFeatureFlagStatus !== fetchFeatureFlagStatus && fetchFeatureFlagStatus === STATUS.SUCCESS) ||
        prevProps.isCurrentUserBeta !== isCurrentUserBeta) &&
      isAuthenticated
    ) {
      // Unsubscribe from any previous subscription
      if (typeof this.unsubscribeFromBetaSubscription === 'function') {
        this.unsubscribeFromBetaSubscription();
      }
    }

    // Unsubscribe when user is not longer authenticated
    if (prevProps.isAuthenticated !== isAuthenticated && typeof this.unsubscribeFromBetaSubscription === 'function') {
      this.unsubscribeFromBetaSubscription();
    }

    if (!snapshot) {
      return;
    }
    const { isOnline } = snapshot;
    const content = (
      <div>
        <strong>{isOnline ? 'ONLINE' : 'OFFLINE'}</strong>
        <div>{isOnline ? 'Back Online' : 'Changes you make will not be saved'}</div>
      </div>
    );

    const toastId = toast(
      content,
      Object.assign(
        {
          autoClose: isOnline ? 2000 : false,
          onOpen: isOnline ? this.onlineCallback : () => this.offlineCallback(toastId),
        },
        !isOnline && { type: toast.TYPE.ERROR, closeButton: false }
      )
    );
  }

  componentWillUnmount() {
    this.removeNetworkStatusEventListeners();
    if (typeof this.unsubscribeFromBetaSubscription === 'function') {
      this.unsubscribeFromBetaSubscription();
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { isOnline } = this.state;

    if (prevState.isOnline !== isOnline) {
      return { isOnline };
    }

    return null;
  }

  // Conditionally add or remove network status event listeners such
  // as 'online' and 'offline', depending on the pathname of the page
  updateNetworkStatusEventListeners = ({ pathname }) => {
    const includedPaths = ['/profile', '/:hackathonSlug/dashboard', '/settings'];
    if (matchPath(pathname, { path: includedPaths })) {
      this.addNetworkStatusEventListeners();
    } else {
      this.removeNetworkStatusEventListeners();
    }
  };

  // Add online and offline listeners to the window
  addNetworkStatusEventListeners = () => {
    window.addEventListener('offline', this.setOffline);
    window.addEventListener('online', this.setOnline);
  };

  // Remove online, offline and beforeunload listeners from the window
  removeNetworkStatusEventListeners = () => {
    window.removeEventListener('offline', this.setOffline);
    window.removeEventListener('online', this.setOnline);
    this.removeConfirmReload();
  };

  confirmReload = event => {
    event.returnValue = 'Are you sure? Progress might be lost.';
  };

  removeConfirmReload = () => {
    window.removeEventListener('beforeunload', this.confirmReload);
  };

  setOnline = () => {
    this.setState({ isOnline: true });
  };

  setOffline = () => {
    this.setState({ isOnline: false });
  };

  onlineCallback = () => {
    toast.dismiss(this.offlineToastId);
    this.offlineToastId = null;
  };

  offlineCallback = toastId => {
    this.offlineToastId = toastId;
  };

  handleOAuthErrors = () => {
    const { location, history } = this.props;
    const urlSearchParams = new URLSearchParams(location.search);
    const error = urlSearchParams.get('error');
    const provider = urlSearchParams.get('provider');
    if (
      error === SERVER_ERRORS.accountAlreadyConnected &&
      typeof provider === 'string' &&
      Object.values(OAUTH_PROVIDERS).includes(provider)
    ) {
      const content = (
        <div>
          <strong>
            {provider === OAUTH_PROVIDERS.ETHEREUM
              ? 'Wallet already linked'
              : `${OAUTH_PROVIDER_LABELS[provider]} already linked`}
          </strong>
          <div style={{ marginTop: 4 }}>
            This {/* eslint-disable-next-line max-len */}
            {provider === OAUTH_PROVIDERS.ETHEREUM
              ? 'Ethereum wallet'
              : `${OAUTH_PROVIDER_LABELS[provider]} account`}{' '}
            is already linked to a different Devfolio account. You will either need to disconnect it from the original
            account or connect to a different{' '}
            {provider === OAUTH_PROVIDERS.ETHEREUM ? 'Ethereum wallet' : `${OAUTH_PROVIDER_LABELS[provider]} account`}.
          </div>
        </div>
      );
      toast(content, {
        type: toast.TYPE.ERROR,
        bodyStyle: {
          width: '470px',
        },
        autoClose: 7000,
      });

      history.replace({
        pathname: location.pathname,
        search: '',
      });
    }
  };

  render() {
    const { children } = this.props;
    return (
      <>
        <ToastContainer />
        {children}
      </>
    );
  }
}

const mapStateToProps = ({ authentication, hackathon, user }) => ({
  imageUploadStatus: hackathon.status.uploadProjectImages,
  isCurrentUserBeta: isUserBeta(user),
  isAuthenticated: authentication.isAuthenticated,
  fetchFeatureFlagStatus: user.status.fetchFeatureFlags,
});

const mapDispatchToProps = dispatch => ({
  dispatchFetchFeatureFlags: () => {
    dispatch(fetchFeatureFlags());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OfflinePlugin));

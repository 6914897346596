import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

const initialState = {
  email: '',
  error: '',
  firstName: '',
  lastName: '',
  password: '',
  username: '',
  isUsernameUnavailable: false,
  googleObject: null,
  isRegistrationLoading: false,
  registerStatus: STATUS.READY,
  status: {
    registerUser: STATUS.READY,
  },
};

const registration = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_REGISTRATION_ERROR:
      return {
        ...state,
        error: '',
      };

    case types.SIGNUP_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.SET_REGISTRATION_STATE:
      return {
        ...state,
        [action.payload.param]: action.payload.value,
      };

    case types.IS_USERNAME_UNAVAILABLE:
      return {
        ...state,
        isUsernameUnavailable: action.payload,
      };

    case types.SET_REGISTRATION_LOADING_STATE:
      return {
        ...state,
        isRegistrationLoading: action.payload,
      };

    case types.REGISTER_USER: {
      const { payload } = action;

      return {
        ...state,
        status: {
          ...state.status,
          registerUser: payload.status,
        },
      };
    }

    default:
      return state;
  }
};

export default registration;

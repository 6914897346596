import styled from 'styled-components';

export const Checkmark = styled.span`
  position: absolute;
  border: 1px solid #125045;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background: #cbf8f0;
  border-radius: 3px;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 4px;
    width: 7px;
    height: 13px;
    border: solid #125045;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const Container = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 40px;
  font-size: 1rem;
  color: #273339;
  opacity: 0.92;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;

    &:checked ~ ${Checkmark} {
      background-color: #34eacb;

      &::after {
        display: block;
      }
    }
  }

  &:hover input ~ ${Checkmark} {
    background-color: #34eacb;
  }
`;

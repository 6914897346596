import moment from 'moment';
import { Hackathon_Type_Enum as HackathonTypeEnum } from '../interfaces/graphql';
// An exhaustive list of all the hackathon slugs that are initiatives
const INITIATIVE_SLUGS = ['wagmidrops'];

// The list of all hackathon slugs which are demo day
const DEMO_DAY_SLUGS = ['sgcindia-demoday'];

// The ETHIndia 2022 hackathon slug
export const ETHINDIA_2022_SLUG = 'ethindia2022';

// The ETHIndia 2023 hackathon slug
export const ETHINDIA_2023_SLUG = 'ethindia2023';

// The ETHDenver 2024 hackathon slug
export const ETHDENVER_2024_SLUG = 'ethdenver2024';

// The ETHIndia 2024 hackathon slug
export const ETHINDIA_2024_SLUG = 'ethindia2024';

// The Onchain Summer Buildathon slug
export const ONCHAIN_SUMMER_BUILDATHON_2024_SLUG = 'onchain-summer';

// The Google GenAI Exchange Hackathon slug
export const GOOGLE_GENAI_EXCHANGE_2024_SLUG = 'google-genaiexchange';

export const BASE_AROUND_WORLD_INDIA_SLUG = 'based-india';
export const BASE_AROUND_WORLD_LATAM_SLUG = 'based-latam';
export const BASE_AROUND_WORLD_AFRICA_SLUG = 'based-africa';
export const BASE_AROUND_WORLD_SEA_SLUG = 'based-sea';

// The MumbaiHacks 2024 slug
export const MUMBAIHACKS_2024_SLUG = 'mumbaihacks';

// The ETHDenver 2025 hackathon slug
export const ETHDENVER_2025_SLUG = 'ethdenver2025';

// The ETHSF 2025 hackathon slug
export const ETHSF_2025_SLUG = 'ethsf2025';

export const SUI_OVERFLOW_2025_SLUG = 'sui-overflow-2025';

export const BASE_ONCHAIN_AI_BLR = 'onchain-ai-blr';

// The Safe Agent hackathon slug
export const SAFE_AGENT_HACKATHON_SLUG = 'safe-agents';

export const BASE_AROUND_WORLD_SLUGS = [
  BASE_AROUND_WORLD_INDIA_SLUG,
  BASE_AROUND_WORLD_LATAM_SLUG,
  BASE_AROUND_WORLD_AFRICA_SLUG,
  BASE_AROUND_WORLD_SEA_SLUG,
];

export const BASE_BATCH_SLUGS = [
  'base-batch-latam',
  'base-batch-india',
  'base-batch-europe',
  'base-batch-africa',
  'base-batch-apac',
  'base-batch-america',
];

export const AI_FEATURES_ALLOWED_HACKATHON_LIST = [ETHDENVER_2025_SLUG, ETHSF_2025_SLUG];

// A list of all the hackathons where we want to show title
// as partners instead of sponsors
const HACKATHONS_SLUGS_WITH_PARTNER_TITLE = ['unfold', ...DEMO_DAY_SLUGS];

/** Returns whether a given type is fellowship or not */
export const isFellowshipType = (type?: `${HackathonTypeEnum}`) => type === HackathonTypeEnum.Fellowship;

/** Returns whether a given type is grants or not */
export const isGrantType = (type?: `${HackathonTypeEnum}`) => type === HackathonTypeEnum.Grant;

/** Returns whether a given type is workshop or not */
export const isWorkshopType = (type?: `${HackathonTypeEnum}`) => type === HackathonTypeEnum.Workshop;

/**
 * Returns whether the given slug is an initiative and not an actual hackathon.
 *
 * To add a slug to the list of initiatives, add it to the {@link INITIATIVE_SLUGS} array.
 */
export const isHackathonAnInitiative = (hackathonSlug: string): boolean => INITIATIVE_SLUGS.includes(hackathonSlug);

/**
 * Returns whether the given slug is a demo day and not an actual hackathon.
 *
 * To add a slug to the list of demo days, add it to the {@link DEMO_DAY_SLUGS} array.
 */
export const isDemoDayHackathon = (hackathonSlug: string): boolean => DEMO_DAY_SLUGS.includes(hackathonSlug);

/**
 * Returns the whether a partner title should be shown for the given hackathon.
 *
 * Add a slug to the {@link HACKATHONS_SLUGS_WITH_PARTNER_TITLE} array, if we want to have the title as
 * partner instead of sponsors.
 */
export const shouldShowPartnerTitle = (hackathonSlug: string): boolean =>
  HACKATHONS_SLUGS_WITH_PARTNER_TITLE.includes(hackathonSlug);

/**
 * Returns the hackathon mode name depending on the hackathon slug.
 */
export const getModeCopy = (hackathonSlug: string, type?: `${HackathonTypeEnum}`) => {
  if (isDemoDayHackathon(hackathonSlug)) {
    return 'Demo Day';
  }
  if (isHackathonAnInitiative(hackathonSlug)) {
    return 'event';
  }
  if (isFellowshipType(type)) {
    return 'fellowship';
  }
  if (isGrantType(type)) {
    return 'program';
  }
  if (isWorkshopType(type)) {
    return 'workshop';
  }

  return 'hackathon';
};

export const STUDENT_TRACK_PHASE = 6;

// A list of all phases for Google Gen AI Exchange 2024
export const GOOGLE_GENAI_EXCHANGE_2024_PHASES: Array<{
  phase: number;
  title: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  projectUpdatesAllowed: boolean;
  color: string;
}> = [
  {
    phase: 1,
    title: 'Registration & Qualification Submission',
    startDate: moment('2024-09-12T18:30:00Z'),
    endDate: moment('2024-10-02T18:59:59.999Z'),
    projectUpdatesAllowed: true,
    color: '#2874F3',
  },
  {
    phase: 2,
    title: 'Prototype Development & Mentorship',
    startDate: moment('2024-10-07T18:30:00.000Z'),
    endDate: moment('2024-10-10T18:29:59.999Z'),
    projectUpdatesAllowed: true,
    color: '#E93E30',
  },
  {
    phase: 3,
    title: 'Final submission & pitching',
    startDate: moment('2024-10-10T18:30:00.000Z'),
    endDate: moment('2024-10-14T18:29:59.999Z'),
    projectUpdatesAllowed: false,
    color: '#3AAA58',
  },
  {
    phase: 4,
    title: 'Showcase day (in-person)',
    startDate: moment('2024-10-17T18:30:00.000Z'),
    endDate: moment('2024-10-18T18:29:59.999Z'),
    projectUpdatesAllowed: false,
    color: '#FBBF0E',
  },
];

export const isUserInCurrentPhase = (hackathonPhase: number, userHackathonPhase: number) => {
  return userHackathonPhase === hackathonPhase;
};

export const isInCurrentPhasePeriod = (hackathonPhase: number, hackathonSlug: string) => {
  const isGoogleHackathon = hackathonSlug === GOOGLE_GENAI_EXCHANGE_2024_SLUG;

  if (!isGoogleHackathon) {
    return true;
  }

  const currentPhaseDetail = GOOGLE_GENAI_EXCHANGE_2024_PHASES?.find(
    phaseDetail => phaseDetail.phase === hackathonPhase
  );

  if (!currentPhaseDetail) {
    return false;
  }

  const now = moment().utc();
  const { startDate, endDate } = currentPhaseDetail;

  return moment(now).isAfter(startDate) && moment(now).isBefore(endDate);
};

export const isInBufferPeriod = (hackathonPhase: number): boolean => {
  const currentPhaseDetail = GOOGLE_GENAI_EXCHANGE_2024_PHASES?.find(
    phaseDetail => phaseDetail.phase === hackathonPhase
  );

  if (!currentPhaseDetail) {
    return false;
  }

  const { startDate, endDate } = currentPhaseDetail;

  const now = moment().utc();

  return moment(now).isBefore(moment(startDate)) || moment(now).isAfter(moment(endDate));
};

export type UserPhaseStatus =
  | 'PROJECT_SUBMITTED'
  | 'ACCEPTED'
  | 'REJECTED'
  | 'JUDGING_ONGOING'
  | 'STUDENT_TRACK'
  | 'SHOWCASE_DAY';

export const getMultiPhaseStatus = (
  hackathonPhase: number,
  userHackathonPhase: number,
  isProjectSubmitted: boolean
): UserPhaseStatus | undefined => {
  if (userHackathonPhase < hackathonPhase) {
    return 'REJECTED';
  }

  const currentPhaseDetail = GOOGLE_GENAI_EXCHANGE_2024_PHASES?.find(
    phaseDetail => phaseDetail.phase === hackathonPhase
  );

  // If phase details are not found do not render the card
  if (!currentPhaseDetail) {
    return undefined;
  }

  // If user_hackathon phase is 6 return return `STUDENT_TRACK`
  if (userHackathonPhase === STUDENT_TRACK_PHASE) {
    return 'STUDENT_TRACK';
  }

  // Check if in buffer period
  const now = moment().utc();
  const { startDate, endDate } = currentPhaseDetail;

  const isBeforeFirstPhase = moment(now).isBefore(moment(startDate)) && hackathonPhase === 1;

  if (isBeforeFirstPhase) {
    return undefined;
  }

  if (isInBufferPeriod(hackathonPhase) && isProjectSubmitted && hackathonPhase !== 4) {
    return 'PROJECT_SUBMITTED';
  }

  switch (hackathonPhase) {
    case GOOGLE_GENAI_EXCHANGE_2024_PHASES?.[0].phase:
      return undefined;
    case GOOGLE_GENAI_EXCHANGE_2024_PHASES?.[1].phase:
      return 'ACCEPTED';
    case GOOGLE_GENAI_EXCHANGE_2024_PHASES?.[2].phase:
      return 'JUDGING_ONGOING';
    case GOOGLE_GENAI_EXCHANGE_2024_PHASES?.[3].phase:
      return 'SHOWCASE_DAY';
    default:
      return undefined;
  }
};

export const isBaseAroundWorldHackathon = (hackathonSlug: string): boolean => {
  return BASE_AROUND_WORLD_SLUGS.includes(hackathonSlug);
};

export const getBaseAroundWorldRegion = (hackathonSlug: string): string => {
  switch (hackathonSlug) {
    case BASE_AROUND_WORLD_INDIA_SLUG:
      return 'Based India';
    case BASE_AROUND_WORLD_AFRICA_SLUG:
      return 'Based Africa';
    case BASE_AROUND_WORLD_LATAM_SLUG:
      return 'Based LatAm';
    case BASE_AROUND_WORLD_SEA_SLUG:
      return 'Based SEA';
    default:
      return '';
  }
};

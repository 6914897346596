import React from 'react';

import Checkbox from './components/Checkbox';
import CheckboxGroup from './components/CheckboxGroup';
import Radio from './components/Radio';
import Select from './components/Select';
import Text from './components/Text';
import Textarea from './components/Textarea';
import RichTextEditor from './components/RichTextEditor';

const renderInputType = props => {
  switch (props.type) {
    case 'checkbox':
      return <Checkbox {...props} />;
    case 'checkboxgroup':
      return <CheckboxGroup {...props} />;
    case 'radio':
      return <Radio {...props} />;
    case 'select':
      return <Select {...props} />;
    case 'text':
      return <Text {...props} />;
    case 'textarea':
      return <Textarea {...props} />;
    case 'richtexteditor':
      return <RichTextEditor {...props} />;
    default:
      return <Text {...props} />;
  }
};

const Input = props => <React.Fragment>{renderInputType(props)}</React.Fragment>;

export default Input;

import React from 'react';
import { useSelector } from 'react-redux';
import { updateServerTimeOffset } from '@actions/authentication';
import { ACTION_STATUS } from '@constants';
import useAction from './useAction';

const useServerTimeOffset = (): boolean => {
  const dispatchUpdateServerTimeOffset = useAction(updateServerTimeOffset);
  const isServerTimeOffsetLoading = useSelector(
    ({ authentication }) => authentication.status.updateServerTimeOffset === ACTION_STATUS.REQUEST
  );

  const isAuthenticated = useSelector(({ authentication }) => authentication.isAuthenticated);

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatchUpdateServerTimeOffset();
    }
  }, [dispatchUpdateServerTimeOffset, isAuthenticated]);

  return isServerTimeOffsetLoading;
};

export default useServerTimeOffset;

import { createSelector } from 'reselect';
import moment from '@helpers/moment';
import { isValidURL, isValueSet } from '@devfolioco/helpers';

import {
  APPLY_STATUS,
  HACKATHON_STATUS,
  MAX_DESC_ITEM_CHARS,
  MAX_LINKS,
  MAX_NAME_CHARS,
  MAX_TAGLINE_CHARS,
  MAX_TAGS,
  MAX_VIDEO_CHARS,
  ROLE,
  MAX_TAG_CHARS,
  MAX_LINK_CHARS,
  GENDER,
  SCOPES,
  SERVER_INPUT_TYPE,
} from '@constants';
import {
  hasHackathonEnded,
  getLocalTimeFromUTC,
  isFieldEmpty,
  hasFilledRequiredLinks,
  isProjectVideoInvalid,
  getHostName,
} from 'helpers';
import {
  getModeCopy,
  ETHINDIA_2023_SLUG,
  GOOGLE_GENAI_EXCHANGE_2024_SLUG,
  isBaseAroundWorldHackathon,
  MUMBAIHACKS_2024_SLUG,
} from '../../shared/hackathon';

const getOtherFields = hackathon => ({
  otherFields: hackathon.otherFields,
  otherFieldsState: hackathon.otherFieldsState,
});

const getTeamInformation = hackathon => ({
  hackathon: hackathon.currentHackathon,
  team: hackathon.team,
  teamMembers: hackathon.teamMembers,
});

const getCurrentHackathon = ({ hackathon }) => hackathon.currentHackathon;

export const selectOtherFields = createSelector([getOtherFields], hackathonState => hackathonState.otherFields);

export const canApply = createSelector([getOtherFields], async hackathonState => {
  const { otherFieldsState, otherFields } = hackathonState;

  if (otherFields.length === 0) {
    return true;
  }

  const shouldProceed = otherFields.map(field => {
    if (field.required && field.type !== 'bool') {
      const fieldInState = otherFieldsState.find(otherField => otherField.uuid === field.uuid);

      if (field.type === 'InputLink') {
        return fieldInState.value !== fieldInState.initialValue;
      }

      return isValueSet(field.uuid, { [field.uuid]: fieldInState.value });
    }

    return true;
  });

  const result = await Promise.all(shouldProceed);
  return Promise.resolve(result.every(value => value === true));
});

export const canTeamSubmit = createSelector([getTeamInformation], teamState => {
  const { teamMembers, hackathon } = teamState;
  const eligibleMembers = teamMembers.filter(member => member.application_status === APPLY_STATUS.SUBMIT);

  return teamMembers.length === eligibleMembers.length && teamMembers.length >= hackathon.team_min;
});

const getHackathonLocalTime = hackathon => {
  const { currentHackathon } = hackathon;
  const { ends_at: endTime, starts_at: startTime, hackathon_setting: settings } = currentHackathon;
  const { reg_ends_at: regEndTime, reg_starts_at: regStartTime } = settings || {};

  return {
    endTime: getLocalTimeFromUTC(endTime),
    startTime: getLocalTimeFromUTC(startTime),
    regEndTime: getLocalTimeFromUTC(regEndTime),
    regStartTime: getLocalTimeFromUTC(regStartTime),
  };
};

export const hasHackathonBegunSelector = createSelector([getHackathonLocalTime], timeState => {
  const { startTime } = timeState;

  return moment(startTime).diff(moment(), 'seconds') < 0;
});

export const hasHackathonEndedSelector = createSelector([getHackathonLocalTime], timeState => {
  const { endTime } = timeState;

  return moment(endTime).diff(moment(), 'seconds') < 0;
});

export const areHackathonRegistrationsOpenSelector = createSelector([getHackathonLocalTime], timeState => {
  const { regStartTime, regEndTime } = timeState;
  const now = moment();

  return moment(regStartTime).isBefore(now) && moment(regEndTime).isAfter(now);
});

export const hackathonLocalTimeSelector = createSelector([getHackathonLocalTime], timeState => timeState);

export const teamAdminSelector = createSelector([getTeamInformation], teamState => {
  const { teamMembers } = teamState;
  const teamAdmin = teamMembers.find(member => member.is_admin === true);

  return teamAdmin;
});

const canTeamSubmitProject = createSelector([getTeamInformation], teamState => {
  const { teamMembers, hackathon, team } = teamState;

  if (team === null) {
    return false;
  }

  return (
    teamMembers.length >= hackathon.team_min &&
    (team.status === APPLY_STATUS.CHECK_IN || team.status === APPLY_STATUS.REIMBURSE)
  );
});

export const canSubmitProject = createSelector(
  canTeamSubmitProject,
  hasHackathonBegunSelector,
  hasHackathonEndedSelector,
  (canSubmit, hasHackathonBegun, hasEnded) => canSubmit && hasHackathonBegun && !hasEnded
);

export const isHackathonLiveSelector = createSelector(
  hasHackathonBegunSelector,
  hasHackathonEndedSelector,
  (hasHackathonBegun, hasEnded) => hasHackathonBegun && !hasEnded
);

const getProject = state => {
  const { hackathon } = state;
  const { project } = hackathon;

  return {
    description: project.description || [],
    file: project.file || '',
    hashtags: project.hashtags || [],
    links: project.links || [],
    name: project.name || '',
    pictures: project.pictures || [],
    favicon: project.favicon || '',
    coverImage: project.coverImage || '',
    tagline: project.tagline || '',
    teamMembers: hackathon.teamMembers || [],
    videoUrl: project.videoUrl || '',
    tracks: project.tracks || [],
    trackDescriptions: project.trackDescriptions || {},
    platforms: project.platforms || [],
    // ETHIndia spefific fields
    judgingSubmissionType: project.judgingSubmissionType ?? null,
    emoji: project.emoji ?? null,
    sourceCodeURL: project.sourceCodeURL ?? '',
    demoURL: project.demoURL ?? '',
    category: project.category ?? '',
    techWebFrameworks: project.techWebFrameworks ?? [],
    techLanguages: project.techLanguages ?? [],
    techDevtools: project.techDevtools ?? [],
    techDesignTools: project.techDesignTools ?? [],
    techDatabases: project.techDatabases ?? [],
    techBlockchains: project.techBlockchains ?? [],
    // ETHDenver2024 specific fields
    communityExpoOptIn: project.communityExpoOptIn ?? false,
    // Google GenAIExhchange 2024 specific fields
    architectureDiagram: project.architectureDiagram || '',
    // MumbaiHacks 2024 specific fields
    byStudentTeam: project.byStudentTeam ?? false,
  };
};

export const canProceedToPreview = createSelector([getProject, getCurrentHackathon], (project, hackathon) => {
  const {
    description,
    hashtags,
    links,
    name,
    tagline,
    videoUrl,
    tracks,
    trackDescriptions,
    judgingSubmissionType,
    emoji,
    demoURL,
    sourceCodeURL,
    category,
    techWebFrameworks,
    techLanguages,
    techDevtools,
    techDesignTools,
    techDatabases,
    techBlockchains,
    pictures,
    architectureDiagram,
  } = project;

  const hackathonSlug = hackathon?.slug;

  const isGoogleHackathon = hackathonSlug === GOOGLE_GENAI_EXCHANGE_2024_SLUG;
  const isBaseAroundWorld = isBaseAroundWorldHackathon(hackathonSlug);

  const isDescriptionValid =
    description[0]?.content?.length && description.every(item => item?.content?.length <= MAX_DESC_ITEM_CHARS);
  const isNameValid = name.length && name.length <= MAX_NAME_CHARS;
  const isTaglineValid = tagline.length && tagline.length <= MAX_TAGLINE_CHARS;
  const isVideoURLValid =
    (isGoogleHackathon || isBaseAroundWorld ? videoUrl.length : true) &&
    videoUrl.length <= MAX_VIDEO_CHARS &&
    !isProjectVideoInvalid(videoUrl, hackathonSlug);
  const areTagsValid =
    hashtags.length && hashtags.length <= MAX_TAGS && hashtags.every(tag => tag.text.length <= MAX_TAG_CHARS);
  const areLinksValid =
    // eslint-disable-next-line no-nested-ternary
    hackathonSlug === ETHINDIA_2023_SLUG
      ? true
      : hackathonSlug === MUMBAIHACKS_2024_SLUG
      ? links.length &&
        links.length >= 2 &&
        links.length <= MAX_LINKS &&
        links.every(link => link.text.length <= MAX_LINK_CHARS)
      : links.length && links.length <= MAX_LINKS && links.every(link => link.text.length <= MAX_LINK_CHARS);

  const hasFilledAllTrackDescriptions = tracks.every(
    track => typeof trackDescriptions[track] === 'string' && trackDescriptions[track].trim().length > 0
  );

  const isDemoURLValid = typeof demoURL === 'string' && demoURL.length > 0 ? isValidURL(demoURL) : true;

  const isSourceCodeURLValid = isGoogleHackathon
    ? typeof sourceCodeURL === 'string' &&
      isValidURL(sourceCodeURL) &&
      sourceCodeURL?.length > 0 &&
      getHostName(sourceCodeURL) === 'github.com'
    : true;

  const isArchitectureDiagramValid = isGoogleHackathon
    ? (typeof architectureDiagram === 'string' && architectureDiagram.length > 0) ||
      typeof architectureDiagram === 'object'
    : true;

  const hasFilledEthindiaSpecificDetails =
    hackathonSlug === ETHINDIA_2023_SLUG
      ? typeof judgingSubmissionType === 'string' &&
        typeof emoji === 'string' &&
        isDemoURLValid &&
        typeof sourceCodeURL === 'string' &&
        isValidURL(sourceCodeURL) &&
        getHostName(sourceCodeURL) === 'github.com' &&
        Array.isArray(pictures) &&
        pictures.length > 0 &&
        typeof category === 'string' &&
        Array.isArray(techWebFrameworks) &&
        techWebFrameworks.length > 0 &&
        Array.isArray(techLanguages) &&
        techLanguages.length > 0 &&
        Array.isArray(techDevtools) &&
        techDevtools.length > 0 &&
        Array.isArray(techDesignTools) &&
        techDesignTools.length > 0 &&
        Array.isArray(techDatabases) &&
        techDatabases.length > 0 &&
        Array.isArray(techBlockchains) &&
        techBlockchains.length > 0
      : true;

  return (
    isDescriptionValid &&
    isNameValid &&
    isTaglineValid &&
    isVideoURLValid &&
    areTagsValid &&
    areLinksValid &&
    hasFilledAllTrackDescriptions &&
    hasFilledEthindiaSpecificDetails &&
    isSourceCodeURLValid &&
    isArchitectureDiagramValid
  );
});

export const canProceedToSaveDraft = createSelector([getProject, getCurrentHackathon], (project, hackathon) => {
  const {
    description,
    hashtags,
    links,
    name,
    tagline,
    videoUrl,
    tracks,
    trackDescriptions,
    judgingSubmissionType,
    emoji,
    demoURL,
    sourceCodeURL,
    category,
    techWebFrameworks,
    techLanguages,
    techDevtools,
    techDesignTools,
    techDatabases,
    techBlockchains,
    pictures,
    architectureDiagram,
  } = project;

  const hackathonSlug = hackathon?.slug;

  const isGoogleHackathon = hackathonSlug === GOOGLE_GENAI_EXCHANGE_2024_SLUG;

  const isDescriptionValid =
    description[0]?.content?.length && description.every(item => item?.content?.length <= MAX_DESC_ITEM_CHARS);
  const isNameValid = name.length && name.length <= MAX_NAME_CHARS;
  const isTaglineValid = tagline.length && tagline.length <= MAX_TAGLINE_CHARS;
  const isVideoURLValid =
    (isGoogleHackathon ? videoUrl.length : true) &&
    videoUrl.length <= MAX_VIDEO_CHARS &&
    !isProjectVideoInvalid(videoUrl, hackathonSlug);
  const areTagsValid =
    hashtags.length && hashtags.length <= MAX_TAGS && hashtags.every(tag => tag.text.length <= MAX_TAG_CHARS);
  const areLinksValid =
    hackathonSlug === ETHINDIA_2023_SLUG
      ? true
      : links.length && links.length <= MAX_LINKS && links.every(link => link.text.length <= MAX_LINK_CHARS);

  const hasFilledAllTrackDescriptions = tracks.every(
    track => typeof trackDescriptions[track] === 'string' && trackDescriptions[track].trim().length > 0
  );

  const isDemoURLValid = typeof demoURL === 'string' && demoURL.length > 0 ? isValidURL(demoURL) : true;

  const isSourceCodeURLValid = isGoogleHackathon
    ? typeof sourceCodeURL === 'string' &&
      isValidURL(sourceCodeURL) &&
      sourceCodeURL?.length > 0 &&
      getHostName(sourceCodeURL) === 'github.com'
    : true;

  const isArchitectureDiagramValid = isGoogleHackathon
    ? typeof architectureDiagram === 'string' && architectureDiagram.length > 0
    : true;

  const hasFilledEthindiaSpecificDetails =
    hackathonSlug === ETHINDIA_2023_SLUG
      ? typeof judgingSubmissionType === 'string' &&
        typeof emoji === 'string' &&
        isDemoURLValid &&
        typeof sourceCodeURL === 'string' &&
        isValidURL(sourceCodeURL) &&
        getHostName(sourceCodeURL) === 'github.com' &&
        Array.isArray(pictures) &&
        pictures.length > 0 &&
        typeof category === 'string' &&
        Array.isArray(techWebFrameworks) &&
        techWebFrameworks.length > 0 &&
        Array.isArray(techLanguages) &&
        techLanguages.length > 0 &&
        Array.isArray(techDevtools) &&
        techDevtools.length > 0 &&
        Array.isArray(techDesignTools) &&
        techDesignTools.length > 0 &&
        Array.isArray(techDatabases) &&
        techDatabases.length > 0 &&
        Array.isArray(techBlockchains) &&
        techBlockchains.length > 0
      : true;

  return (
    isNameValid &&
    (isDescriptionValid ||
      isTaglineValid ||
      isVideoURLValid ||
      areTagsValid ||
      areLinksValid ||
      hasFilledAllTrackDescriptions ||
      hasFilledEthindiaSpecificDetails ||
      isSourceCodeURLValid ||
      isArchitectureDiagramValid)
  );
});

export const projectSelector = createSelector(getProject, project => project);

const getHackathons = hackathon => hackathon.userHackathons;

const getAllHackathons = hackathon =>
  hackathon.allHackathons.sort(
    (hackathonA, hackathonB) => new Date(hackathonA.starts_at) - new Date(hackathonB.starts_at)
  );

export const isOrganizer = createSelector(getHackathons, hackathons =>
  hackathons.some(hackathon => hackathon.role === ROLE.ORGANIZER)
);

export const isSquadMember = createSelector(getHackathons, hackathons =>
  hackathons.some(hackathon => hackathon.role === ROLE.SQUAD)
);

export const getUserHackathons = createSelector(getHackathons, hackathons =>
  hackathons.filter(hackathon => hackathon.role === ROLE.HACKER)
);

export const getOrganizerHackathons = createSelector(getHackathons, hackathons =>
  hackathons.filter(hackathon => hackathon.role === ROLE.ORGANIZER)
);

export const getSquadHackathons = createSelector(getHackathons, hackathons =>
  hackathons.filter(hackathon => hackathon.role === ROLE.SQUAD)
);

export const getHackerAndOrganizerHackathons = createSelector(getHackathons, hackathons =>
  hackathons.filter(
    hackathon => hackathon.role === ROLE.HACKER || hackathon.role === ROLE.ORGANIZER || hackathon.role === ROLE.SQUAD
  )
);

export const getNonHackerAndNonOrganizerHackathons = createSelector(getHackathons, hackathons =>
  hackathons.filter(
    hackathon => hackathon.role !== ROLE.ORGANIZER && hackathon.role !== ROLE.HACKER && hackathon.role !== ROLE.SQUAD
  )
);

export const getPublishedHackathons = createSelector(getAllHackathons, hackathons =>
  hackathons.filter(hackathon => hackathon.status === HACKATHON_STATUS.PUBLISH)
);

/**
 * Get all the non active and published non-Devfolio official hackathons including the invisible hackathons
 */
export const getPastUnofficialHackathons = createSelector(getAllHackathons, hackathons =>
  hackathons.filter(
    hackathon =>
      hackathon.status === HACKATHON_STATUS.PUBLISH &&
      !hackathon.devfolio_official &&
      hasHackathonEnded(hackathon.ends_at)
  )
);

/**
 * Get all the active and published non-Devfolio official hackathons not including the invisible hackathons
 */
export const getActiveUnofficialHackathons = createSelector(getAllHackathons, hackathons =>
  hackathons.filter(
    hackathon =>
      hackathon.status === HACKATHON_STATUS.PUBLISH &&
      !hackathon.devfolio_official &&
      !hasHackathonEnded(hackathon.ends_at)
  )
);

/**
 * Get all the non active and published Devfolio official hackathons
 */
export const getPastOfficialHackathons = createSelector(getAllHackathons, hackathons =>
  hackathons.filter(
    hackathon =>
      hackathon.status === HACKATHON_STATUS.PUBLISH &&
      hackathon.devfolio_official &&
      hasHackathonEnded(hackathon.ends_at)
  )
);

/**
 * Get all the non active and published Devfolio official hackathons
 */
export const getActiveOfficialHackathons = createSelector(getAllHackathons, hackathons =>
  hackathons.filter(
    hackathon =>
      hackathon.status === HACKATHON_STATUS.PUBLISH &&
      hackathon.devfolio_official &&
      !hasHackathonEnded(hackathon.ends_at)
  )
);

const getAboutState = ({ user }) => ({
  bio: user.bio || '',
  dob: user.dob || '',
  firstName: user.firstName || '',
  gender: user.gender || '',
  lastName: user.lastName || '',
  shirtSize: user.shirtSize || '',
  username: user.username || undefined,
});

/**
 * Checks if the user satisfies a given hackathon constraint.
 * Default value is true
 */
export const doesSatisfyConstraint = createSelector([getCurrentHackathon, getAboutState], (hackathon, user) => {
  const isWomenOnly = hackathon.hasOwnProperty('hackathon_setting') && hackathon.hackathon_setting.women_only;

  if (isWomenOnly) {
    return GENDER[user.gender] === GENDER.f || GENDER[user.gender] === GENDER.nb;
  }

  return true;
});

/**
 * Return only the unanswered feedback questions
 */
export const getUnansweredQuestions = createSelector([getCurrentHackathon], currentHackathon => {
  const hackathonFeedbackQuestions = currentHackathon.feedback;

  if (Array.isArray(hackathonFeedbackQuestions)) {
    hackathonFeedbackQuestions.forEach(feedback => {
      feedback.question = feedback.question
        .replace('hackathon_name', currentHackathon.name || 'hackathon')
        .replace('hackathon', getModeCopy(currentHackathon.slug, currentHackathon.type));
    });

    return hackathonFeedbackQuestions.filter(question => question.answer === null);
  }
  return [];
});

export const getHackathonMetric = createSelector([getUserHackathons], userHackathons => {
  const appliedCount = userHackathons.filter(hackathon => hackathon.status !== null).length;

  const inProgressCount = userHackathons.filter(
    hackathon => hackathon.status === null && !hasHackathonEnded(hackathon.hackathon.ends_at)
  ).length;

  const acceptedCount = userHackathons.filter(
    hackathon =>
      hackathon.status === APPLY_STATUS.ACCEPT_SENT ||
      hackathon.status === APPLY_STATUS.CHECK_IN ||
      hackathon.status === APPLY_STATUS.REIMBURSE ||
      hackathon.status === APPLY_STATUS.RSVP
  ).length;

  return { applied: appliedCount, accepted: acceptedCount, inProgress: inProgressCount };
});

const getProjectMembers = project => project.project.members;

export const getProjectMembersUsername = createSelector([getProjectMembers], members =>
  members.map(member => member.username)
);

const getUser = state => state.user;

export const sideProjectSelector = createSelector(
  [getProject, getCurrentHackathon, getUser],
  (project, hackathon, user) => ({
    ...project,
    hackathon: { ...hackathon, ...hackathon.hackathon_settings },
    teamMembers: project.teamMembers.length
      ? project.teamMembers
      : [
          {
            first_name: user.firstName,
            last_name: user.lastName,
            username: user.username,
          },
        ],
  })
);

const getRequiredFields = state => state.hackathon.requiredFields;

const getUserError = state => state.user.error;

export const getSectionWiseRequiredFieldsPercentage = createSelector(
  [getUser, getRequiredFields, getUserError],
  (user, requiredFields, userError) => {
    const sectionWisePercentage = Object.entries(requiredFields).reduce((acc, [section, items]) => {
      switch (section) {
        case SCOPES.LINKS: {
          if (hasFilledRequiredLinks(items)) {
            return { ...acc, [section]: 1 };
          }
          if (
            items.includes('otherProfiles') &&
            !(user.otherProfiles.length || user.otherProfiles.some(profile => profile.uuid))
          ) {
            return { ...acc, [section]: 0 };
          }
          const hasProfiles = items.map(link => {
            const hasProfile = user.otherProfiles.find(profile => profile.name === link);
            return hasProfile && hasProfile.uuid;
          });
          return { ...acc, [section]: hasProfiles.filter(profile => !!profile).length / hasProfiles.length };
        }
        case SCOPES.EDUCATION:
          if (user.hasNoFormalEducation) {
            return { ...acc, [section]: 1 };
          }

          /* If Education Institute is present and it's website URL isn't,
             percentage of education institution section is set to 0
          */
          if (!user.educationInstitution?.uuid) {
            return { ...acc, [section]: 0 };
          }
        // eslint-disable-next-line no-fallthrough
        default: {
          const emptyItems = items
            .map(item => {
              return { isEmpty: isFieldEmpty(user, item, userError), item };
            })
            .filter(item => !item.isEmpty);
          // console.log(section, emptyItems);
          return { ...acc, [section]: emptyItems.length / items.length };
        }
      }
    }, {});
    return sectionWisePercentage;
  }
);

export const getOtherFieldsPercentage = createSelector([getOtherFields], ({ otherFields, otherFieldsState }) => {
  if (!otherFields.length) {
    return 1;
  }
  const requiredFields = otherFields.filter(field => field.required && field.type !== SERVER_INPUT_TYPE.BOOL);
  if (!requiredFields.length) {
    return 1;
  }
  const filledFields = requiredFields.filter(field => {
    const fieldState = otherFieldsState[field.uuid];
    if (typeof fieldState === 'string') {
      return fieldState && fieldState.trim();
    }
    if (typeof fieldState === 'object') {
      return fieldState && fieldState.value;
    }
    return !!fieldState;
  });
  return filledFields.length / requiredFields.length;
});

export const isOnlineWithReview = createSelector(
  [getCurrentHackathon],
  currentHackathon => !currentHackathon.is_online && currentHackathon?.hackathon_setting?.review
);

export const isOnlineWithoutReview = createSelector(
  [getCurrentHackathon],
  currentHackathon => currentHackathon.is_online
);

export const isOnline = createSelector(
  [isOnlineWithReview, isOnlineWithoutReview],
  (onlineWithReview, onlineWihoutReview) => onlineWihoutReview || onlineWithReview
);

// Action Types

// USER SPECIFIC
export const ADD_COLLEGE_STATE = 'ADD_COLLEGE_STATE';
export const ADD_GITHUB_STATE = 'ADD_GITHUB_STATE';
export const ADD_PROFILE_STATE = 'ADD_PROFILE_STATE';
export const ADD_SKILL_STATE = 'ADD_SKILL_STATE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CLEAR_AUTHENTICATION_ERRORS = 'CLEAR_AUTHENTICATION_ERRORS';
export const CLEAR_EDUCATION_STATE = 'CLEAR_EDUCATION_STATE';
export const CLEAR_REGISTRATION_ERROR = 'CLEAR_REGISTRATION_ERROR';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const DELETE_GITHUB_STATE = 'DELETE_GITHUB_STATE';
export const DELETE_PROFILE_STATE = 'DELETE_PROFILE_STATE';
export const DELETE_SKILL_STATE = 'DELETE_SKILL_STATE';
export const EMAIL_VERIFY_OTP_READY = 'EMAIL_VERIFY_OTP_READY';
export const EMAIL_VERIFY_OTP_FAILURE = 'EMAIL_VERIFY_OTP_FAILURE';
export const EMAIL_VERIFY_OTP_REQUEST = 'EMAIL_VERIFY_OTP_REQUEST';
export const EMAIL_VERIFY_OTP_SUCCESS = 'EMAIL_VERIFY_OTP_SUCCESS';
export const FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE';
export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const GOOGLE_AUTHENTICATION_FAILURE = 'GOOGLE_AUTHENTICATION_FAILURE';
export const GOOGLE_AUTHENTICATION_SUCCESS = 'GOOGLE_AUTHENTICATION_SUCCESS';
export const INPUT_UPDATE = 'INPUT_UPDATE';
export const IS_USERNAME_UNAVAILABLE = 'IS_USERNAME_UNAVAILABLE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_USER = 'REGISTER_USER';
export const DELETE_USER = 'DELETE_USER';
export const POST_DELETE_USER_FEEDBACK = 'POST_DELETE_USER_FEEDBACK';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SAVE_USER_DATA_FAILURE = 'SAVE_USER_DATA_FAILURE';
export const SAVE_USER_DATA_REQUEST = 'SAVE_USER_DATA_REQUEST';
export const SAVE_USER_DATA_SUCCESS = 'SAVE_USER_DATA_SUCCESS';
export const SEND_EMAIL_OTP_FAILURE = 'SEND_EMAIL_OTP_FAILURE';
export const SEND_EMAIL_OTP_REQUEST = 'SEND_EMAIL_OTP_REQUEST';
export const SEND_EMAIL_OTP_SUCCESS = 'SEND_EMAIL_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';
export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_VIA_CALL_FAILURE = 'SEND_OTP_VIA_CALL_FAILURE';
export const SEND_OTP_VIA_CALL_REQUEST = 'SEND_OTP_VIA_CALL_REQUEST';
export const SEND_OTP_VIA_CALL_SUCCESS = 'SEND_OTP_VIA_CALL_SUCCESS';
export const SET_AUTHENTICATION_STATE = 'SET_AUTHENTICATION_STATE';
export const SET_REGISTRATION_STATE = 'SET_REGISTRATION_STATE';
export const SET_REGISTRATION_LOADING_STATE = 'SET_REGISTRATION_LOADING_STATE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_STATE = 'SET_USER_STATE';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const UPDATE_PROFILE_STATE = 'UPDATE_PROFILE_STATE';
export const UPDATE_SAVE_STATUS = 'UDPATE_SAVE_STATUS';
export const UPDATE_SKILL_STATE = 'UPDATE_SKILL_STATE';
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const UPDATE_USERNAME = 'UPDATE_USERNAME';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const UPLOAD_RESUME_FAILURE = 'UPLOAD_RESUME_FAILURE';
export const UPLOAD_RESUME_REQUEST = 'UPLOAD_RESUME_REQUEST';
export const UPLOAD_RESUME_SUCCESS = 'UPLOAD_RESUME_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const UPDATE_NAME = 'UPDATE_NAME';
export const INITIALIZE_AUTH_STATE = 'INITIALIZE_AUTH_STATE';
export const UPDATE_TOUR_TAKEN = 'UPDATE_TOUR_TAKEN';
export const CLEAR_PHONE_VERIFY_STATUS = 'CLEAR_PHONE_VERIFY_STATUS';
export const UPDATE_PUBLIC_PROFILE_VISIBLE = 'UPDATE_PUBLIC_PROFILE_VISIBLE';
export const UPDATE_USER_AUTHENTICATION = 'UPDATE_USER_AUTHENTICATION';
export const SORTED_SKILLS = 'SORTED_SKILLS';
export const FETCH_FEATURE_FLAGS = 'FETCH_FEATURE_FLAGS';
export const TOGGLE_BETA = 'TOGGLE_BETA';
export const UPDATE_ETHEREUM_WALLET = 'UPDATE_ETHEREUM_WALLET';
export const DISCONNECT_OAUTH_PROVIDER = 'DISCONNECT_OAUTH_PROVIDER';
export const FETCH_OAUTH_PROVIDERS = 'FETCH_OAUTH_PROVIDERS';

// HACKATHON SPECIFIC
export const ADD_HACKATHON_EXTRA_FAILURE = 'ADD_HACKATHON_EXTRA_FAILURE';
export const ADD_HACKATHON_EXTRA_REQUEST = 'ADD_HACKATHON_EXTRA_REQUEST';
export const ADD_HACKATHON_EXTRA_SUCCESS = 'ADD_HACKATHON_EXTRA_SUCCESS';
export const CHECK_APPLICATION_REQUIREMENTS_FAILURE = 'CHECK_APPLICATION_REQUIREMENTS_FAILURE';
export const CHECK_APPLICATION_REQUIREMENTS_REQUEST = 'CHECK_APPLICATION_REQUIREMENTS_REQUEST';
export const CHECK_APPLICATION_REQUIREMENTS_SUCCESS = 'CHECK_APPLICATION_REQUIREMENTS_SUCCESS';
export const CHECK_APPLICATION_REQUIREMENTS_READY = 'CHECK_APPLICATION_REQUIREMENTS_READY';
export const CLEAR_HACKATHON_ERROR = 'CLEAR_HACKATHON_ERROR';
export const CLEAR_HACKATHON_TEAM_ERROR = 'CLEAR_HACKATHON_TEAM_ERROR';
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE';
export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILURE = 'DELETE_TEAM_FAILURE';
export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const FETCH_ALL_HACKATHON_USER_DETAILS_FAILURE = 'FETCH_ALL_HACKATHON_USER_DETAILS_FAILURE';
export const FETCH_ALL_HACKATHON_USER_DETAILS_REQUEST = 'FETCH_ALL_HACKATHON_USER_DETAILS_REQUEST';
export const FETCH_ALL_HACKATHON_USER_DETAILS_SUCCESS = 'FETCH_ALL_HACKATHON_USER_DETAILS_SUCCESS';
export const FETCH_ANNOUNCEMENTS = 'FETCH_ANNOUNCEMENTS';
export const UPDATE_EMAIL_ANNOUNCEMENT_PREFERENCE = 'UPDATE_EMAIL_ANNOUNCEMENT_PREFERENCE';
export const FETCH_CURRENT_HACKATHON_SUCCESS = 'FETCH_CURRENT_HACKATHON_SUCCESS';
export const FETCH_FEEDBACK = 'FETCH_FEEDBACK';
export const FETCH_HACKATHON_DATA_FAILURE = 'FETCH_HACKATHON_DATA_FAILURE';
export const FETCH_HACKATHON_DATA_REQUEST = 'FETCH_HACKATHON_DATA_REQUEST';
export const FETCH_HACKATHON_DATA_SUCCESS = 'FETCH_HACKATHON_DATA_SUCCESS';
export const FETCH_HACKATHON_USER_DETAILS_FAILURE = 'FETCH_HACKATHON_USER_DETAILS_FAILURE';
export const FETCH_HACKATHON_USER_DETAILS_REQUEST = 'FETCH_HACKATHON_USER_DETAILS_REQUEST';
export const FETCH_HACKATHON_USER_DETAILS_SUCCESS = 'FETCH_HACKATHON_USER_DETAILS_SUCCESS';
export const FETCH_HACKATHONS_DATA_FAILURE = 'FETCH_HACKATHONS_DATA_FAILURE';
export const FETCH_HACKATHONS_DATA_REQUEST = 'FETCH_HACKATHONS_DATA_REQUEST';
export const FETCH_HACKATHONS_DATA_SUCCESS = 'FETCH_HACKATHONS_DATA_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';
export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_REIMBURSEMENT_AMOUNT_FAILURE = 'FETCH_REIMBURSEMENT_AMOUNT_FAILURE';
export const FETCH_REIMBURSEMENT_AMOUNT_REQUEST = 'FETCH_REIMBURSEMENT_AMOUNT_REQUEST';
export const FETCH_REIMBURSEMENT_AMOUNT_SUCCESS = 'FETCH_REIMBURSEMENT_AMOUNT_SUCCESS';
export const FETCH_USER_HACKATHONS = 'FETCH_USER_HACKATHONS';
export const HACKATHON_ERROR = 'HACKATHON_ERROR ';
export const HACKATHON_FILE_UPLOAD_FAILURE = 'HACKATHON_FILE_UPLOAD_FAILURE';
export const HACKATHON_FILE_UPLOAD_REQUEST = 'HACKATHON_FILE_UPLOAD_REQUEST';
export const HACKATHON_FILE_UPLOAD_SUCCESS = 'HACKATHON_FILE_UPLOAD_SUCCESS';
export const HACKATHON_TEAM_ERROR = 'HACKATHON_TEAM_ERROR';
export const HYDRATE_PROJECT = 'HYDRATE_PROJECT';
export const INVITE_TO_SQUAD = 'INVITE_TO_SQUAD';
export const JOIN_TEAM_WITH_CODE_FAILURE = 'JOIN_TEAM_WITH_CODE_FAILURE';
export const JOIN_TEAM_WITH_CODE_REQUEST = 'JOIN_TEAM_WITH_CODE_REQUEST';
export const JOIN_TEAM_WITH_CODE_SUCCESS = 'JOIN_TEAM_WITH_CODE_SUCCESS';
export const LEAVE_TEAM_FAILURE = 'LEAVE_TEAM_FAILURE';
export const LEAVE_TEAM_REQUEST = 'LEAVE_TEAM_REQUEST';
export const LEAVE_TEAM_SUCCESS = 'LEAVE_TEAM_SUCCESS';
export const MISSING_SCOPE_ERROR = 'MISSING_SCOPE_ERROR';
export const REMOVE_MISSING_FIELD = 'REMOVE_MISSING_FIELD';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SET_APPLY_MODE = 'SET_APPLY_MODE';
export const SET_HACKATHON_ID = 'SET_HACKATHON_ID';
export const SET_HACKATHON_PROJECT_STATE = 'SET_HACKATHON_PROJECT_STATE';
export const SET_MISSING_FIELD = 'SET_MISSING_FIELD';
export const SET_MISSING_SECTIONS_STATUS = 'SET_MISSING_SECTIONS_STATUS';
export const SET_OTHER_FIELD_STATE = 'SET_OTHER_FIELD_STATE';
export const SET_OTHER_FIELDS = 'SET_OTHER_FIELDS';
export const SET_OTHER_FIELDS_STATE = 'SET_OTHER_FIELDS_STATE';
export const SAVE_OTHER_FIELDS = 'SAVE_OTHER_FIELDS';
export const SUBMIT_HACKATHON_PROJECT_FAILURE = 'SUBMIT_HACKATHON_PROJECT_FAILURE';
export const SUBMIT_HACKATHON_PROJECT_REQUEST = 'SUBMIT_HACKATHON_PROJECT_REQUEST';
export const SUBMIT_HACKATHON_PROJECT_SUCCESS = 'SUBMIT_HACKATHON_PROJECT_SUCCESS';
export const SUBMIT_INDIVIDUAL_FAILURE = 'SUBMIT_INDIVIDUAL_FAILURE';
export const SUBMIT_INDIVIDUAL_REQUEST = 'SUBMIT_INDIVIDUAL_REQUEST';
export const SUBMIT_INDIVIDUAL_SUCCESS = 'SUBMIT_INDIVIDUAL_SUCCESS';
export const SUBMIT_OTHER_FIELDS_FAILURE = 'SUBMIT_OTHER_FIELDS_FAILURE';
export const SUBMIT_OTHER_FIELDS_REQUEST = 'SUBMIT_OTHER_FIELDS_REQUEST';
export const SUBMIT_OTHER_FIELDS_SUCCESS = 'SUBMIT_OTHER_FIELDS_SUCCESS';
export const SUBMIT_TEAM_APPLICATION_FAILURE = 'SUBMIT_TEAM_APPLICATION_FAILURE';
export const SUBMIT_TEAM_APPLICATION_REQUEST = 'SUBMIT_TEAM_APPLICATION_REQUEST';
export const SUBMIT_TEAM_APPLICATION_SUCCESS = 'SUBMIT_TEAM_APPLICATION_SUCCESS';
export const UPDATE_GET_ALL_EXTRA_FIELDS = 'UPDATE_GET_ALL_EXTRA_FIELDS';
export const UPDATE_HACKER_STATUS_FAILURE = 'UPDATE_HACKER_STATUS_FAILURE';
export const UPDATE_HACKER_STATUS_REQUEST = 'UPDATE_HACKER_STATUS_REQUEST';
export const UPDATE_HACKER_STATUS_SUCCESS = 'UPDATE_HACKER_STATUS_SUCCESS';
export const UPDATE_PAYMENT_DETAILS_FAILURE = 'UPDATE_PAYMENT_DETAILS_FAILURE';
export const UPDATE_PAYMENT_DETAILS_REQUEST = 'UPDATE_PAYMENT_DETAILS_REQUEST';
export const UPDATE_PAYMENT_DETAILS_SUCCESS = 'UPDATE_PAYMENT_DETAILS_SUCCESS';
export const UPDATE_RSVP_CARD_KEY = 'UPDATE_RSVP_CARD_KEY';
export const UPLOAD_TICKET_FAILURE = 'UPLOAD_TICKET_FAILURE';
export const UPLOAD_TICKET_REQUEST = 'UPLOAD_TICKET_REQUEST';
export const UPLOAD_TICKET_SUCCESS = 'UPLOAD_TICKET_SUCCESS';
export const VERIFY_INVITE_ADD_SQUAD = 'VERIFY_INVITE_ADD_SQUAD';
export const FETCH_PROJECT_COMMENTS = 'FETCH_PROJECT_COMMENTS';
export const POST_PROJECT_COMMENT = 'POST_PROJECT_COMMENT';
export const DELETE_PROJECT_COMMENT = 'DELETE_PROJECT_COMMENT';
export const POST_PROJECT_LIKE = 'POST_PROJECT_LIKE';
export const FETCH_USER_PROJECT_STATS = 'FETCH_USER_PROJECT_STATS';
export const FETCH_PROJECT_FUNDING = 'FETCH_PROJECT_FUNDING';
export const REVERT_INDIVIDUAL_APPLICATION_SUCCESS = 'REVERT_INDIVIDUAL_APPLICATION_SUCCESS';
export const UPDATE_HACKATHON_STAKING_INFO = 'UPDATE_HACKATHON_STAKING_INFO';
export const UPDATE_USER_HACKATHON_TRACK = 'UPDATE_USER_HACKATHON_TRACK';
export const FETCH_PROJECT_QV_STATS = 'FETCH_PROJECT_QV_STATS';
export const FETCH_USER_QV_DATA = 'FETCH_USER_QV_DATA';
export const FETCH_IS_USER_ELIGIBLE_FOR_QV = 'FETCH_IS_USER_ELIGIBLE_FOR_QV';
export const FETCH_PROJECT_CHEER_ELIGIBILITY = 'FETCH_PROJECT_CHEER_ELIGIBILITY';
export const FETCH_PROJECT_CHEER_CONTRIBUTIONS = 'FETCH_PROJECT_CHEER_CONTRIBUTIONS';
export const USER_HACKATHON_STAKING_INFO_REQUEST = 'USER_HACKATHON_STAKING_INFO_REQUEST';
export const USER_HACKATHON_STAKING_INFO_SUCCESS = 'USER_HACKATHON_STAKING_INFO_SUCCESS';
export const USER_HACKATHON_STAKING_INFO_FAILURE = 'USER_HACKATHON_STAKING_INFO_FAILURE';

export const FETCH_MAILING_LIST = 'FETCH_MAILING_LIST';
export const RESUBSCRIBE = 'RESUBSCRIBE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const UPDATE_MAILING_LIST = 'UPDATE_MAILING_LIST';
export const UPDATE_OPPORTUNITY_PREFS = 'UPDATE_OPPORTUNITY_PREFS';

export const PUBLISH_PROJECT = 'PUBLISH_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export const FETCH_FEATURED_HACKATHON = 'FETCH_FEATURED_HACKATHON';
export const CLEAR_CURRENT_HACKATHON = 'CLEAR_CURRENT_HACKATHON';

export const SET_HACKATHON_REQUIRED_FIELDS = 'SET_HACKATHON_REQUIRED_FIELDS';
export const SET_HACKATHON_TRACKS = 'SET_HACKATHON_TRACKS';
export const UPDATE_REQUIRED_LINK = 'UPDATE_REQUIRED_LINK';

export const SET_OLD_PROJECT_STATE = 'SET_OLD_PROJECT_STATE';

export const APPLY_TO_HACKATHON = 'APPLY_TO_HACKATHON';

// FORM SPECIFIC
export const FORM_GET_FAILURE = 'FORM_GET_FAILURE';
export const FORM_GET_REQUEST = 'FORM_GET_REQUEST';
export const FORM_GET_SUCCESS = 'FORM_GET_SUCCESS';
export const FORM_POST_FAILURE = 'FORM_POST_FAILURE';
export const FORM_POST_REQUEST = 'FORM_POST_REQUEST';
export const FORM_POST_SUCCESS = 'FORM_POST_SUCCESS';

export const FETCH_TICKET_INFO = 'FETCH_TICKET_INFO';
export const SEND_TRANSACTION_DETAILS = 'SEND_TRANSACTION_DETAILS';

// QUIZZES
export const FETCH_QUIZZES = 'FETCH_QUIZZES';
export const FETCH_QUIZZES_PUBLIC = 'FETCH_QUIZZES_PUBLIC';
export const UPDATE_CURRENT_QUIZ = 'UPDATE_CURRENT_QUIZ';
export const SUBMIT_QUESTION_RESPONSE = 'SUBMIT_QUESTION_RESPONSE';
export const SUBMIT_QUESTION_SKIP = 'SUBMIT_QUESTION_SKIP';
export const SUBMIT_QUESTION_TIMEOUT = 'SUBMIT_QUESTION_TIMEOUT';
export const SUBMIT_SAMPLE_QUESTION_RESPONSE = 'SUBMIT_SAMPLE_QUESTION_RESPONSE';

export const APP_UPDATED = 'APP_UPDATED';

// PROFILE
export const FETCH_USER_INFO = 'FETCH_USER_INFO';

// My Projects
export const CREATE_SIDE_PROJECT = 'CREATE_SIDE_PROJECT';
export const DELETE_SIDE_PROJECT = 'DELETE_SIDE_PROJECT';
export const SAVE_SIDE_PROJECT = 'SAVE_SIDE_PROJECT';
export const FETCH_SIDE_PROJECT = 'FETCH_SIDE_PROJECT';

export const FETCH_SIDE_PROJECTS = 'FETCH_SIDE_PROJECTS';
export const FETCH_IDEAS = 'FETCH_IDEAS';

// Idea

export const FETCH_IDEA = 'FETCH_IDEA';
export const FETCH_IDEA_STATS = 'FETCH_IDEA_STATS';
export const SUBMIT_IDEA = 'SUBMIT_IDEA';
export const FETCH_USER_BASIC_INFO = 'FETCH_USER_BASIC_INFO';
export const FETCH_USER_EXTRA_INFO = 'FETCH_USER_EXTRA_INFO';
export const FETCH_USER_STATS = 'FETCH_USER_STATS';
export const UPLOAD_PROJECT_IMAGE = 'UPLOAD_PROJECT_IMAGE';

// Miscellaneous
export const UPDATE_SERVER_TIME_OFFSET = 'UPDATE_SERVER_TIME_OFFSET';
export const FETCH_DISCORD_ID = 'FETCH_DISCORD_ID';
export const DISCONNECT_DISCORD = 'DISCONNECT_DISCORD';
export const CHECK_ETHINDIA_DISCORD_SERVER = 'CHECK_ETHINDIA_DISCORD_SERVER';
export const UPDATE_TOKENPROOF_NONCE = 'UPDATE_TOKENPROOF_NONCE';
export const UPDATE_TOKENPROOF_VERIFIED_STATUS = 'UPDATE_TOKENPROOF_VERIFIED_STATUS';

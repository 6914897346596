import styled from 'styled-components';

export const SelectInput = styled.select`
  width: 100%;
  appearance: none;
  background-color: #cffbf3;
  border-radius: 3px;
  border-style: none;
  color: #273339;
  cursor: pointer;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  height: 56px;
  outline: none;
  padding: 0 15px 0;

  &:focus {
    border: 2px solid #34eacb;
    padding: 0 13px;
  }
`;

export const Container = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 24px;
    top: 21px;
    padding: 3px;
    transform: rotate(45deg);
    border: solid #125045;
    border-width: 0 3px 3px 0;
    display: inline-block;
  }
`;

/* eslint-disable max-len */
const currentTime = Date.now();
const questionEndTime = currentTime + 60 * 1 * 1000;

const baseQuestion = {
  currentTime,
  codeSnippet: null,
  estimatedTime: 60,
  questionEndTime,
  total: 5,
};

const sampleQuestions = [
  {
    ...baseQuestion,
    number: 1,
    question:
      'A technique to turn features of an application on and off without deploying new code is known by which of the following term?',
    options: [
      {
        value: 'A/B Testing',
        uuid: 0,
      },
      {
        value: 'Feature flagging',
        uuid: 1,
      },
      {
        value: 'Polyfilling',
        uuid: 2,
      },
      {
        value: 'Canary releasing',
        uuid: 3,
      },
    ],
  },
  {
    ...baseQuestion,
    number: 2,
    question:
      'In semantic HTML, which of the following elements is best suited to be used for blog posts, comments or other reusable blocks of content?',
    options: [
      {
        value: 'section',
        uuid: 0,
      },
      {
        value: 'main',
        uuid: 1,
      },
      {
        value: 'p',
        uuid: 2,
      },
      {
        value: 'article',
        uuid: 3,
      },
    ],
  },
  {
    ...baseQuestion,
    number: 3,
    question: 'Which of the following indicates *wrong* message corresponding to the status code?',
    options: [
      {
        value: '400: Bad request',
        uuid: 0,
      },
      {
        value: '403: Unauthorized',
        uuid: 1,
      },
      {
        value: '500: Internal Server Error',
        uuid: 2,
      },
      {
        value: '503: Service Unavailable',
        uuid: 3,
      },
    ],
  },
  {
    ...baseQuestion,
    number: 4,
    question: 'What will `console.log(typeof [])` output?',
    options: [
      {
        value: 'array',
        uuid: 0,
      },
      {
        value: 'object',
        uuid: 1,
      },
      {
        value: 'undefined',
        uuid: 2,
      },
      {
        value: 'null',
        uuid: 3,
      },
    ],
  },
  {
    ...baseQuestion,
    number: 5,
    question:
      'For smooth animations with CSS, why is translate preferred over tweaking the position of an absolute positioned element?',
    options: [
      {
        value: 'Translate is easier to manage',
        uuid: 0,
      },
      {
        value: 'Absolute positioned elements are not accessible',
        uuid: 1,
      },
      {
        value: 'Translate is given priority over other processes in CPU',
        uuid: 2,
      },
      {
        value: 'Translate uses GPU to render, effectively smoothening the animation',
        uuid: 3,
      },
    ],
  },
];

export default sampleQuestions;

import * as types from '../constants/actions';
import { ACTION_STATUS } from '../constants';

export const initialState = {
  uuid: '',
  username: '',
  appUpdated: false,
  error: '',
  isAuthenticated: false,
  isLoading: false,
  serverTimeOffset: 0,
  status: {
    changePassword: ACTION_STATUS.READY,
    updateUsername: ACTION_STATUS.READY,
    updateServerTimeOffset: ACTION_STATUS.READY,
  },
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case types.FORGOT_PASSWORD_FAILURE:
    case types.GOOGLE_AUTHENTICATION_FAILURE:
    case types.SIGNIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        error: action.payload,
      };

    case types.SIGNIN_REQUEST:
    case types.FORGOT_PASSWORD_REQUEST:
    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.SIGNIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };

    case types.RESET_PASSWORD_FAILURE:
    case types.FORGOT_PASSWORD_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case types.GOOGLE_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
      };

    case types.CLEAR_AUTHENTICATION_ERRORS:
      return { ...state, error: '' };

    case types.INITIALIZE_AUTH_STATE:
    case types.LOGOUT_SUCCESS:
      return { ...initialState };

    case types.SET_AUTHENTICATION_STATE:
      return {
        ...state,
        [action.payload.param]: action.payload.value,
      };

    case types.UPDATE_USERNAME: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          username: payload.username,
          status: {
            ...state.status,
            updateUsername: payload.status,
          },
        };
      }

      return {
        ...state,
        error: 'error' in payload ? payload.error : state.error,
        status: {
          ...state.status,
          updateUsername: payload.status,
        },
      };
    }

    case types.CHANGE_PASSWORD: {
      const { payload } = action;

      return {
        ...state,
        error: 'error' in payload ? payload.error : state.error,
        status: {
          ...state.status,
          changePassword: payload.status,
        },
      };
    }

    case types.APP_UPDATED:
      return {
        ...state,
        appUpdated: action.payload,
      };

    case types.FETCH_USER_BASIC_INFO: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          username: payload.data.username,
          isAuthenticated: true,
          isLoading: false,
        };
      }
      return state;
    }

    case types.UPDATE_USER_AUTHENTICATION:
      return {
        ...state,
        ...action.payload,
      };

    case types.UPDATE_SERVER_TIME_OFFSET: {
      const { payload } = action;
      if (payload.status === ACTION_STATUS.SUCCESS) {
        return {
          ...state,
          serverTimeOffset: payload.serverTimeOffset,
          status: {
            ...state.status,
            updateServerTimeOffset: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateServerTimeOffset: payload.status,
        },
      };
    }
    default:
      return state;
  }
};

export default authentication;

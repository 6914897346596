import moment from '@helpers/moment';
import { getInt } from './index';

/**
 * This will return month from the given date.
 *
 * @param date Date string
 * @param format Format of the date string
 * @returns Month number
 *
 * @example
 * ```ts
 * console.log(getMonthFromStringDate('2021-02-02', 'YYYY-MM-DD'));
 * // returns 2
 * ```
 */
export const getMonthFromStringDate = (date: string, format = 'YYYY-MM-DD'): number =>
  getInt(moment(date, format).format('M'));

/**
 * This will return year from the given date.
 *
 * @param date Date string
 * @param format Format of the date string
 * @returns Year
 *
 * @example
 * ```ts
 * console.log(getYearFromStringDate('2021-02-02', 'YYYY-MM-DD'));
 * // returns 2021
 * ```
 */
export const getYearFromStringDate = (date: string, format = 'YYYY-MM-DD'): number =>
  getInt(moment(date, format).format('YYYY'));

/**
 * Formats date string in the given format
 *
 * @param date Date string
 * @param format Format in which date needs to be formatted
 * @returns Formatted Date string
 *
 * @example
 * ```ts
 * console.log(formatDate('2021-02-02T09:06:04.894Z', 'MMMM DD'));
 * // returns February 02
 * ```
 */
export const formatDate = (date: string, format = 'MMMM DD, YYYY'): string => moment(date).format(format);

/**
 * Returns an iSO string for with the first date for a given
 * month and year.
 * @param {number} month Number representing the month
 * @param {number} year Number for the year
 * @returns ISO string with the UTC date
 */
export const getUTCDateFromMonthAndYear = (month: number, year: number): string =>
  moment.utc(`${year}-${month}-01`, 'YYYY-MM-DD').toISOString();

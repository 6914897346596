import React from 'react';

import { Button, Checkbox, Checkmark, Container, Label, Wrapper } from './style';
import IconCheck from '../../../../assets/check.svg';

class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: [],
    };
  }

  getValues = (name, values) => {
    const { receiveValues } = this.props;
    const valueString = values.toString();
    receiveValues(name, valueString.length === 0 ? null : valueString);
  };

  handleChange = event => {
    const { value, name } = event.target;
    const { values } = this.state;

    if (values.includes(value)) {
      const valueIndex = values.findIndex(valuesItem => valuesItem === value);
      values.splice(valueIndex, 1);
      this.setState({ values });
    } else {
      values.push(value);
      this.setState({ values });
    }

    this.getValues(name, values);
  };

  render() {
    const { options, name } = this.props;
    const { values } = this.state;

    return (
      <Container>
        {Object.entries(options).map(([key, label]) => (
          <Wrapper key={key}>
            <Checkbox
              id={key}
              value={key}
              key={key}
              name={name}
              type="checkbox"
              checked={values.includes(key)}
              onChange={this.handleChange}
            />
            <Checkmark>
              <IconCheck />
            </Checkmark>
            <Label htmlFor={key}>{label}</Label>
            <Button />
          </Wrapper>
        ))}
      </Container>
    );
  }
}

export default CheckboxGroup;

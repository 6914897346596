import { RESUME, SAVE, FILE, ACTION_STATUS as STATUS } from '../constants';
import * as types from '../constants/actions';

// 🚨 Important
// Uodate an entry in the SERVER_PARAM constant when changing
// initialState below (client/constants/index.js)
const initialState = {
  addressLineOne: '',
  addressLineTwo: '',
  bio: '',
  city: '',
  country: '',
  degreeType: '',
  dob: '',
  educationID: undefined,
  educationInstitution: undefined,
  email: '',
  emergencyContactName: '',
  emergencyContactNumber: '',
  ethereumAddress: '',
  experience: [],
  fieldOfStudy: undefined,
  firstName: '',
  gender: '',
  gitHubProfile: undefined,
  hackerType: '',
  hasNoFormalEducation: false,
  hasNoWorkExperience: false,
  hasFilledQuestionnaire: false,
  hasTakenTour: false,
  isEmailVerified: false,
  isGitHubConnected: false,
  isLoading: false,
  isPhoneNumberVerified: false,
  isStudent: false,
  lastName: '',
  monthOfGraduation: '',
  otherProfiles: [],
  opportunities: '',
  phoneNumber: '',
  pincode: '',
  profileImageURL: '',
  publicProfileVisible: true,
  referralCode: '',
  referralCount: 0,
  resumeStatus: RESUME.READY,
  resumeURL: '',
  roles: [],
  saveStatus: SAVE.SUCCESS,
  shirtSize: '',
  skills: [],
  allSkills: [],
  state: '',
  userID: '',
  username: '',
  userStatus: '',
  yearOfGraduation: '',
  userStats: {
    hackathons: 0,
    badges: 0,
    projects: 0,
  },
  discordProviderUUID: null,
  featureFlags: [],
  allOAuthProviders: [],
  status: {
    fetchAllUserData: STATUS.READY,
    deleteExperience: STATUS.READY,
    uploadAvatar: FILE.READY,
    updateName: STATUS.READY,
    updatePublicProfile: STATUS.READY,
    fetchUserBasicInfo: STATUS.READY,
    fetchUserStats: STATUS.READY,
    fetchFeatureFlags: STATUS.READY,
    toggleBeta: STATUS.READY,
    deleteUser: STATUS.READY,
    postDeleteUserFeedback: STATUS.READY,
    updateEthereumAddress: STATUS.READY,
    disconnectOAuthProvider: STATUS.READY,
    fetchAllOAuthProviders: STATUS.READY,
  },

  // Showing the 500 page via error boundary
  serverError: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_USER_DATA:
      return {
        ...initialState,
      };
    case types.CLEAR_EDUCATION_STATE:
      return {
        ...state,
        degreeType: '',
        educationID: undefined,
        educationInstitution: undefined,
        fieldOfStudy: undefined,
        isStudent: false,
        monthOfGraduation: '',
        yearOfGraduation: '',
      };
    case types.FETCH_USER_DATA_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          fetchAllUserData: STATUS.FAILURE,
        },
      };
    case types.UPDATE_SAVE_STATUS:
      return {
        ...state,
        saveStatus: action.payload,
      };
    case types.SET_USER_STATE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case types.DELETE_USER: {
      const { payload } = action;
      return {
        ...state,
        status: {
          ...state.status,
          deleteUser: payload.status,
        },
      };
    }

    case types.POST_DELETE_USER_FEEDBACK: {
      const { payload } = action;
      return {
        ...state,
        status: {
          ...state.status,
          postDeleteUserFeedback: payload.status,
        },
      };
    }

    case types.FETCH_USER_DATA_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          fetchAllUserData: STATUS.REQUEST,
        },
      };
    case types.FETCH_USER_DATA_SUCCESS: {
      const { experience } = action.payload;
      return {
        ...state,
        ...action.payload,
        experience: Array.isArray(experience) && experience.length > 0 ? experience : initialState.experience,
        hasNoFormalEducation: !action.payload.hasNoFormalEducation,
        status: {
          ...state.status,
          fetchAllUserData: STATUS.SUCCESS,
        },
      };
    }
    case types.DELETE_SKILL_STATE:
      return {
        ...state,
        skills: [...state.skills.slice(0, action.payload), ...state.skills.slice(action.payload + 1)],
      };
    case types.ADD_SKILL_STATE:
      return {
        ...state,
        skills: [...state.skills],
      };
    case types.UPDATE_SKILL_STATE:
      return {
        ...state,
        skills: action.payload,
      };
    case types.ADD_GITHUB_STATE:
      return {
        ...state,
        gitHubProfile: action.payload,
      };
    case types.DELETE_PROFILE_STATE:
      return {
        ...state,
        otherProfiles: [
          ...state.otherProfiles.slice(0, action.payload),
          ...state.otherProfiles.slice(action.payload + 1),
        ],
      };
    case types.DELETE_GITHUB_STATE:
      return {
        ...state,
        gitHubProfile: undefined,
      };
    case types.UPDATE_PROFILE_STATE:
      return {
        ...state,
        otherProfiles: state.otherProfiles.map((profile, index) => {
          if (index !== action.payload.index) {
            return profile;
          }
          return {
            ...profile,
            ...action.payload.profileObject,
          };
        }),
      };
    case types.ADD_PROFILE_STATE:
      return {
        ...state,
        otherProfiles: [...state.otherProfiles, action.payload],
      };
    case types.UPLOAD_RESUME_FAILURE:
      return {
        ...state,
        saveStatus: SAVE.FAILURE,
        resumeStatus: action.payload,
      };
    case types.UPLOAD_RESUME_REQUEST:
      return {
        ...state,
        saveStatus: SAVE.PROGRESS,
        resumeStatus: action.payload,
      };
    case types.UPLOAD_RESUME_SUCCESS:
      return {
        ...state,
        saveStatus: SAVE.SUCCESS,
        resumeStatus: RESUME.UPLOADED,
        resumeURL: action.payload,
      };
    case types.SEND_OTP_FAILURE:
    case types.SEND_OTP_REQUEST:
    case types.SEND_OTP_SUCCESS:
    case types.SEND_OTP_VIA_CALL_FAILURE:
    case types.SEND_OTP_VIA_CALL_REQUEST:
    case types.SEND_OTP_VIA_CALL_SUCCESS:
    case types.VERIFY_OTP_REQUEST:
    case types.CLEAR_PHONE_VERIFY_STATUS:
      return {
        ...state,
        phoneVerifyStatus: action.payload,
      };
    case types.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        phoneVerifyStatus: action.payload,
        isPhoneNumberVerified: true,
      };
    case types.VERIFY_OTP_FAILURE:
      return {
        ...state,
        phoneVerifyStatus: action.payload,
        isPhoneNumberVerified: false,
      };
    case types.SEND_EMAIL_OTP_REQUEST:
      return {
        ...state,
        isEmailVerificationLinkSent: undefined,
      };
    case types.SEND_EMAIL_OTP_FAILURE:
      return {
        ...state,
        isEmailVerificationLinkSent: false,
      };
    case types.SEND_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        isEmailVerificationLinkSent: true,
      };
    case types.EMAIL_VERIFY_OTP_FAILURE:
    case types.EMAIL_VERIFY_OTP_SUCCESS:
    case types.EMAIL_VERIFY_OTP_REQUEST:
    case types.EMAIL_VERIFY_OTP_READY:
      return {
        ...state,
        emailVerifyStatus: action.payload,
      };

    case types.CLEAR_USER_ERROR: {
      return {
        ...state,
        error: '',
      };
    }
    case types.USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    // For showing the 500 page
    case 'SERVER_ERROR': {
      return {
        ...state,
        serverError: true,
      };
    }

    case types.ADD_COLLEGE_STATE: {
      return {
        ...state,
        educationInstitution: {
          ...state.educationInstitution,
          ...action.payload,
        },
      };
    }

    case types.UPLOAD_AVATAR: {
      const { payload } = action;
      if (payload.status === FILE.UPLOADED) {
        const urlCreator = window.URL || window.webkitURL;
        const profileImageBlob = urlCreator.createObjectURL(payload.file);

        return {
          ...state,
          profileImageURL: profileImageBlob || payload.url,
          status: {
            ...state.status,
            uploadAvatar: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          uploadAvatar: payload.status,
        },
      };
    }

    case types.DELETE_EXPERIENCE: {
      const { payload } = action;

      if (payload.status === STATUS.REQUEST) {
        return {
          ...state,
          experience: state.experience.filter(item => item.uuid !== payload.experienceID),
          status: {
            ...state.status,
            deleteExperience: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          deleteExperience: payload.status,
        },
      };
    }

    case types.UPDATE_NAME: {
      const { payload } = action;

      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          firstName: payload.firstName,
          lastName: payload.lastName,
          status: {
            ...state.status,
            updateName: payload.status,
          },
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          updateName: payload.status,
        },
      };
    }

    case types.UPDATE_TOUR_TAKEN: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          hasTakenTour: payload.hasTakenTour,
        };
      }
      return state;
    }

    case types.UPDATE_REQUIRED_LINK: {
      const { payload } = action;
      const isLinkPresent = state.otherProfiles.find(({ name }) => name === payload.name);
      if (isLinkPresent) {
        return {
          ...state,
          otherProfiles: state.otherProfiles.map(link => {
            if (link.name === payload.name) {
              return { ...link, value: payload.value, uuid: payload.uuid || link.uuid };
            }
            return link;
          }),
        };
      }
      return {
        ...state,
        otherProfiles: [...state.otherProfiles, payload],
      };
    }

    case types.UPDATE_OPPORTUNITY_PREFS: {
      const { payload } = action;
      if (payload.status === STATUS.REQUEST) {
        return {
          ...state,
          opportunities: payload.opportunities,
        };
      }
      return state;
    }

    case types.UPDATE_PUBLIC_PROFILE_VISIBLE: {
      const { payload } = action;
      if (payload.status === STATUS.REQUEST) {
        return {
          ...state,
          publicProfileVisible: payload.visible,
          status: {
            ...state.status,
            updatePublicProfile: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updatePublicProfile: payload.status,
        },
      };
    }

    case types.FETCH_USER_BASIC_INFO: {
      const { payload } = action;
      const { shouldUpdateStatus } = payload;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ...payload.data,
          // Only update the loading and basic info status
          // when required (This is necessary to prevent
          // the Routes component from re-rendering
          // unnecessarily)
          ...(shouldUpdateStatus && {
            isLoading: false,
            status: {
              ...state.status,
              fetchUserBasicInfo: payload.status,
            },
          }),
        };
      }
      // Update status only when required for the reasons
      // mentioned above
      if (shouldUpdateStatus) {
        return {
          ...state,
          isLoading: payload.status === STATUS.REQUEST,
          status: {
            ...state.status,
            fetchUserBasicInfo: payload.status,
          },
        };
      }
      return state;
    }

    case types.FETCH_USER_STATS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          userStats: payload.data,
          status: {
            ...state.status,
            fetchUserStats: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchUserStats: payload.status,
        },
      };
    }

    case types.FETCH_USER_EXTRA_INFO: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ...payload.data,
          status: {
            ...state.status,
            fetchUserExtraInfo: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchUserExtraInfo: payload.status,
        },
      };
    }

    case types.FETCH_FEATURE_FLAGS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          featureFlags: payload.featureFlags,
          status: {
            ...state.status,
            fetchFeatureFlags: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchFeatureFlags: payload.status,
        },
      };
    }

    case types.TOGGLE_BETA: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          featureFlags: payload.featureFlags,
          status: {
            ...state.status,
            toggleBeta: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          toggleBeta: payload.status,
        },
      };
    }

    case types.UPDATE_ETHEREUM_WALLET: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ethereumAddress: payload.ethereumAddress,
          status: {
            ...state.status,
            updateEthereumAddress: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          updateEthereumAddress: payload.status,
        },
      };
    }

    case types.DISCONNECT_OAUTH_PROVIDER: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          allOAuthProviders: state.allOAuthProviders.filter(provider => provider.uuid !== payload.providerUUID),
          status: {
            ...state.status,
            disconnectOAuthProvider: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          disconnectOAuthProvider: payload.status,
        },
      };
    }

    case types.FETCH_DISCORD_ID: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          discordProviderUUID: payload.discordProviderUUID,
          status: {
            ...state.status,
            fetchDiscordID: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchDiscordID: payload.status,
        },
      };
    }

    case types.DISCONNECT_DISCORD: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          discordProviderUUID: null,
          status: {
            ...state.status,
            disconnectDiscord: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          disconnectDiscord: payload.status,
        },
      };
    }
    case types.FETCH_OAUTH_PROVIDERS: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          allOAuthProviders: payload.allOAuthProviders,
          status: {
            ...state.status,
            fetchAllOAuthProviders: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchAllOAuthProviders: payload.status,
        },
      };
    }
    case types.FETCH_USER_QV_DATA: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        const userQVData = payload.data?.me?.user_hackathons?.[0];
        return {
          ...state,
          userQVData,
          status: {
            ...state.status,
            fetchUserQVData: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchUserQVData: payload.status,
        },
      };
    }

    case types.FETCH_IS_USER_ELIGIBLE_FOR_QV: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          isUserEligibleForQV: payload.data.data,
          status: {
            ...state.status,
            fetchIsUserEligibleForQV: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchIsUserEligibleForQV: payload.status,
        },
      };
    }

    default:
      return state;
  }
};

export default user;

import { Logo } from '@devfolioco/genesis';
import Nprogress from 'nprogress';
import React from 'react';

import { Container, Donut } from './style';

Nprogress.configure({ showSpinner: false });

class Loader extends React.PureComponent {
  componentDidMount() {
    const { donut, donutWithProgress } = this.props;

    if (!donut || donutWithProgress) {
      Nprogress.start();
    }
  }

  componentWillUnmount() {
    const { donut, donutWithProgress } = this.props;

    if (!donut || donutWithProgress) {
      Nprogress.done();
    }
  }

  render() {
    const { donut, donutWithProgress, showLogo = true, ...rest } = this.props;

    if (donut || donutWithProgress) {
      return (
        <Container {...rest}>
          <Donut />
        </Container>
      );
    }

    if (showLogo) {
      return (
        <Container {...rest}>
          <Logo />
        </Container>
      );
    }

    return null;
  }
}

export default Loader;

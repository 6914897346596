/* -------------------------------------- */
/* -- S T Y L E D  C O M P O N E N T S -- */
/* -------------------------------------- */

import styled, { keyframes, css } from 'styled-components';
import Input from 'components/Input';
import { Column } from 'hedron';

export const Body = styled.p`
  color: ${({ theme }) => theme.color.grey[7]};
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1em;
  line-height: 27px;
`;

export const A = styled.a`
  color: ${props => props.theme.color.blue[4]};
  font-family: ${props => props.theme.typography.font.primary};
  font-size: 17px;
  hyphens: auto;
  line-height: 23px;
  overflow-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${props =>
    props.appearance === 'neutral' &&
    css`
      color: ${props.theme.color.grey[7]};

      &:hover {
        text-decoration: none;
        color: ${props.theme.color.grey[8]};
      }
    `}
`;

/* -- F O R M  E L E M E N T S -- */

export const FormTitle = styled.h1`
  font-size: 1.999em;
  line-height: 1.33;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  color: #353576;
`;

export { Input };

export const Instruction = styled.span`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 17px;
  color: ${props => (props.error ? '#FF3838' : props.theme.color.grey[7])};
  margin-top: 4px;
`;

export const Card = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid rgb(252, 252, 255);
  border-radius: 6px;
  box-shadow:
    0 10px 40px 0 rgba(62, 58, 108, 0.08),
    0 2px 10px 0 rgba(62, 58, 108, 0.08);
  flex-direction: column;
  margin: 0 auto;
  padding: 32px;
`;

export const Toast = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  width: 100%;
  color: #125045;
  background-color: #fdcb6e;
  border-radius: 3px;
  margin-bottom: 48px;
`;

export const Page = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
`;

export const ArticlePage = styled(Page)`
  max-width: 800px;
`;

export const FullPage = styled(Page)`
  max-width: 1200px;
`;

export const HalfColumn = styled(Column)`
  :first-child {
    padding-right: 16px;
  }

  :last-child {
    padding-left: 16px;
  }

  :only-child {
    padding-left: 0;
  }

  @media (max-width: 768px) {
    :first-child {
      padding-bottom: 16px;
      padding-right: 0;
    }

    :last-child {
      padding-top: 16px;
      padding-left: 0;
    }
  }
`;

export const LinkInputContainer = styled.div`
  display: flex;
  position: relative;

  input {
    text-indent: 40px;
  }
`;

const shimmer = keyframes`
  from {
    transform: translateX(-33.3%);
  }

  to {
    transform: translateX(33.3%);
  }
`;

export const ShimmerOverlay = styled.div`
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  top: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -100%;
    bottom: 0;
    left: -100%;
    will-change: transform;
    animation: ${shimmer} 1s infinite linear;
    background-image: ${({ offwhite }) =>
      offwhite
        ? `linear-gradient(
      to right,
      rgba(244, 245, 246, 0) 33.3%,
      rgba(244, 245, 246, 0.8),
      rgba(244, 245, 246, 0) 66.6%
    )`
        : `linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 33.3%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 66.6%
    )`};
  }
`;

import { Analytics } from '@devfolioco/helpers';
import { API } from '../api';
import { CLIENT_PARAM, STATE, ACTION_STATUS as STATUS, ANALYTICS_TRACKING_EVENTS } from '../constants';
import { CHECK_EMAIL_PROFILE } from '../constants/settings';
import { createDevfolioAuth } from './authentication';
import { ERRORS, SERVER_ERRORS } from '../constants/errors';
import { getState, getUsername, getError, apiErrorStatus } from '../helpers';
import { setUserAvatarFromGoogle } from '../helpers/registration';
import { history } from '../helpers/history';
import { setUserState } from './user';
import { storeItem, removeItemFromStorage } from '../helpers/localStorage';
import * as types from '../constants/actions';
import { logger, logActionError } from '../helpers/logger';

export const clearRegistrationError = () => ({
  type: types.CLEAR_REGISTRATION_ERROR,
});

const registrationError = error => ({
  type: types.SIGNUP_ERROR,
  payload: error,
});

export const setRegistrationState = (param, value) => ({
  type: types.SET_REGISTRATION_STATE,
  payload: { param, value },
});

export const setRegistrationLoadingState = value => ({
  type: types.SET_REGISTRATION_LOADING_STATE,
  payload: value,
});

const isUsernameUnavailable = value => ({
  type: types.IS_USERNAME_UNAVAILABLE,
  payload: value,
});

/**
 * This action prefills user's information when they select to
 * 'Sign Up with Google'
 * @param {Object} userObject - contains username, email, first and last
 * name along with the googleObject (IDToken)
 */
export const hydrateRegistrationState = userObject => async dispatch => {
  const { googleObject, email, firstName, lastName } = userObject;
  const username = email.substring(0, email.indexOf('@'));

  dispatch(setRegistrationState('googleObject', googleObject));
  dispatch(setRegistrationState(CLIENT_PARAM.email, email));
  dispatch(setRegistrationState(CLIENT_PARAM.first_name, firstName));
  dispatch(setRegistrationState(CLIENT_PARAM.last_name, lastName));
  dispatch(setRegistrationState(CLIENT_PARAM.username, username));
};

const isEmailProfileValid = async (email, dispatch) => {
  try {
    const { isSMTPValid, isFormatValid } = await API.registration.getEmailProfile(email);
    if (!isFormatValid) {
      dispatch(registrationError(ERRORS.emailInvalid));
      dispatch(setRegistrationLoadingState(false));
      return false;
    }
    if (!isSMTPValid) {
      dispatch(registrationError(ERRORS.emailDirty));
      dispatch(setRegistrationLoadingState(false));
      return false;
    }
    return true;
  } catch (error) {
    dispatch(setRegistrationLoadingState(false));
    if (apiErrorStatus.internalServerError(error)) {
      logger.error('api', error);
    }
    return false;
  }
};

// NEW ACCOUNT REGISTRATION
/**
 * Check if the email is available and valid, if it is, move to the
 * second screen of the registration flow
 * @param {string} email - new account email
 */
export const setRegistrationEmail =
  (email, referralCode = null) =>
  async dispatch => {
    try {
      dispatch(setRegistrationLoadingState(true));
      dispatch(setRegistrationState('registerStatus', STATUS.REQUEST));

      let profileValid = true;
      if (CHECK_EMAIL_PROFILE) {
        profileValid = await isEmailProfileValid(email, dispatch);
      }
      if (profileValid) {
        const isEmailUnavailable = await API.registration.isEmailAssociated(email);
        dispatch(setRegistrationLoadingState(false));

        if (isEmailUnavailable) {
          dispatch(registrationError(ERRORS.emailUnavailable));
        } else {
          dispatch(setRegistrationState(CLIENT_PARAM.email, email));
          history.push('/signup/account', { referralCode });
        }
      }
      dispatch(setRegistrationState('registerStatus', STATUS.SUCCESS));
      Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.TRIVIAL_SIGNUP_ENTER_EMAIL);
    } catch (error) {
      dispatch(setRegistrationState('registerStatus', STATUS.FAILURE));
      dispatch(setRegistrationLoadingState(false));
      logActionError('setRegistrationEmail', error, { email, referralCode });
    }
  };

/**
 * Create a new account with the obtained user details
 * @param {string} username - username of the account
 * @param {string} email - associated email
 * @param {string} password - password
 */
export const setRegistrationAccount =
  (username, email, password, referralCode, shouldSubscribeToNewsletter) => async dispatch => {
    try {
      const { googleObject } = getState(STATE.REGISTRATION);

      dispatch(setRegistrationLoadingState(true));
      dispatch(setRegistrationState(CLIENT_PARAM.username, username));
      dispatch(setRegistrationState(CLIENT_PARAM.email, email));

      await API.registration.registerUser(
        username,
        email,
        password,
        referralCode,
        googleObject,
        shouldSubscribeToNewsletter
      );

      await createDevfolioAuth(dispatch);

      storeItem('devfolio-signup', {
        isProfileComplete: false,
        isSkillsComplete: false,
      });

      /**
       * @description Below logic is for setting Google Account profile picture as Devfolio Avatar
       * If `imageUrl` key in `googleObject` is equal to string that means user has signed in using Google
       */
      if (typeof googleObject?.imageUrl === 'string') {
        await setUserAvatarFromGoogle(username, googleObject.imageUrl);
      }

      Analytics.identifyUser();
      Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.TRIVIAL_SIGNUP_ACCOUNT);

      dispatch(setRegistrationLoadingState(false));

      routeOnSignUp('/signup/profile');
    } catch (error) {
      dispatch(setRegistrationLoadingState(false));
      logActionError('setRegistrationAccount', error, { username, email, referralCode });
    }
  };

export const setRegistrationProfile = (firstName, lastName) => async dispatch => {
  /**
   * This action sets first and last name of a user. Trigerred on submit button
   * click in the sign up: profile page
   * @param {string} firstName - the entered first name
   * @param {string} lastName - the entered last name
   */
  try {
    dispatch(setRegistrationLoadingState(true));
    const username = await getUsername();

    dispatch(setRegistrationState(CLIENT_PARAM.first_name, firstName));
    dispatch(setRegistrationState(CLIENT_PARAM.last_name, lastName));

    await API.registration.setUserProfile(username, firstName, lastName);

    dispatch(setRegistrationLoadingState(false));
    Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.TRIVIAL_SIGNUP_NAME);

    history.push('/signup/skills');
    storeItem('devfolio-signup', {
      isProfileComplete: true,
      isSkillsComplete: false,
    });
  } catch (error) {
    dispatch(setRegistrationLoadingState(false));
    logActionError('setRegistrationProfile', error, { firstName, lastName });
  }
};

export const setRegistrationSkills = skills => async dispatch => {
  /**
   * Move to the complete page of the sign up flow. Also set the skills.
   * @param {string[]} skills - the skills array with priority
   */
  try {
    dispatch(setRegistrationLoadingState(true));
    const username = await getUsername();
    dispatch(setRegistrationState(CLIENT_PARAM.user_skill, skills));
    removeItemFromStorage('devfolio-signup');

    dispatch(setRegistrationLoadingState(false));
    Analytics.trackEvent(ANALYTICS_TRACKING_EVENTS.TRIVIAL_SIGNUP_SKILLS);
    history.push('/signup/complete');
    await API.user.setUserSkills(username, skills);
  } catch (error) {
    dispatch(setRegistrationLoadingState(false));
    logActionError('setRegistrationSkills', error, { skills });
  }
};

export const checkUsernameAvailability = username => async dispatch => {
  /**
   * If the username if available dispatch a clear error action. Otherwise,
   * dispatch a usernameUnvailable error.
   * @param {string} username - username of the user
   */
  try {
    const isAvailable = await API.registration.isUsernameAvailable(username);
    dispatch(isUsernameUnavailable(!isAvailable));
  } catch (error) {
    logActionError('checkUsernameAvailability', error, { username });
  }
};

const routeOnSignUp = pathname => {
  const {
    location: { state },
  } = history;

  if (typeof state === 'object' && state.hasOwnProperty('from')) {
    history.push({ pathname: state.from.pathname, state });
  } else {
    history.push({ pathname, state });
  }
};

const registerUserAction = payload => ({
  type: types.REGISTER_USER,
  payload,
});

export const registerUser = (email, username, password, firstName, lastName) => async dispatch => {
  try {
    dispatch(registerUserAction({ status: STATUS.REQUEST }));

    let profileValid = true;

    if (CHECK_EMAIL_PROFILE) {
      profileValid = await isEmailProfileValid(email, dispatch);
    }
    // If the email profile is valid then proceed with all the other steps
    // of creating user account and updating the user state accordingly
    if (profileValid) {
      const isEmailUnavailable = await API.registration.isEmailAssociated(email);

      if (isEmailUnavailable) {
        dispatch(registrationError(ERRORS.emailUnavailable));
      } else {
        await API.registration.registerUser(username, email, password);

        await createDevfolioAuth(dispatch);

        if (firstName && lastName) {
          await API.registration.setUserProfile(username, firstName, lastName);

          dispatch(setUserState('firstName', firstName));
          dispatch(setUserState('lastName', lastName));
        }

        dispatch(setUserState('email', email));
        dispatch(setUserState('username', username));

        dispatch(registerUserAction({ status: STATUS.SUCCESS }));

        return;
      }
    }

    dispatch(registerUserAction({ status: STATUS.FAILURE }));
  } catch (error) {
    dispatch(registerUserAction({ status: STATUS.FAILURE }));

    const err = getError(error);

    if (err) {
      if (err.hasOwnProperty('source')) {
        if (err.source.hasOwnProperty('email')) {
          switch (err.source.email.msg) {
            case SERVER_ERRORS.emailInvalid:
              dispatch(registrationError(ERRORS.emailInvalid));
              break;
            case SERVER_ERRORS.emailDuplicate:
              dispatch(registrationError(ERRORS.emailUnavailable));
              break;
            default:
              break;
          }
        }

        if (err.source.hasOwnProperty('username')) {
          switch (err.source.username.msg) {
            case SERVER_ERRORS.usernameUnavailable:
              dispatch(registrationError(ERRORS.usernameUnavailable));
              break;
            case SERVER_ERRORS.usernameInvalidDuplicate:
              dispatch(registrationError(ERRORS.usernameInvalid));
              break;
            default:
              break;
          }
        }

        if (err.source.hasOwnProperty('password')) {
          switch (err.source.password.msg) {
            case SERVER_ERRORS.passwordShort:
              dispatch(registrationError(ERRORS.passwordShort));
              break;
            default:
              break;
          }
        }
      }
    }

    logActionError(types.REGISTER_USER, error, { email, username, firstName, lastName });
  }
};

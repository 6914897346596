import jwt from 'jsonwebtoken';

const verify = payload => {
  return jwt.verify(payload, process.env.QUIZ_SECRET);
};

const sign = payload => {
  return jwt.sign(payload, process.env.QUIZ_SECRET);
};

export { sign, verify };

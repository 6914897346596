import React from 'react';

import { Container, SelectInput } from './style';

const Select = ({ onChange, name, value, children }) => (
  <Container>
    <SelectInput onChange={onChange} name={name} value={value}>
      {children}
    </SelectInput>
  </Container>
);

export default Select;

import * as types from '../constants/actions';
import { ACTION_STATUS as STATUS } from '../constants';

export const initialState = {
  uuid: '',
  name: '',
  author: '',
  source: '',
  idea: '',
  stats: {
    ideaUUID: null,
    builders: [],
    projects: [],
  },
  status: {
    fetchIdea: STATUS.READY,
    fetchIdeaStats: STATUS.READY,
    submitIdea: STATUS.READY,
  },
};

const idea = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_IDEA: {
      const { payload } = action;
      if (payload.status === STATUS.SUCCESS) {
        return {
          ...state,
          ...payload.data,
          status: {
            ...state.status,
            fetchIdea: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchIdea: payload.status,
        },
      };
    }
    case types.FETCH_IDEA_STATS: {
      const { payload } = action;
      if (payload.status !== STATUS.REQUEST) {
        return {
          ...state,
          stats: {
            ideaUUID: payload.data.ideaUUID,
            builders: payload.data.builders,
            projects: payload.data.projects,
          },
          status: {
            ...state.status,
            fetchIdeaStats: payload.status,
          },
        };
      }
      return {
        ...state,
        status: {
          ...state.status,
          fetchIdeaStats: payload.status,
        },
      };
    }
    case types.SUBMIT_IDEA: {
      const { payload } = action;
      return {
        ...state,
        status: {
          ...state.status,
          submitIdea: payload.status,
        },
      };
    }
    default:
      return state;
  }
};

export default idea;
